import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ButtonBase, makeStyles, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { format, isPast, isToday, isTomorrow } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import general_messages from 'messages/general_messages';
import meeting_messages from 'messages/meeting_messages';

import useScrollTo from '../../hooks/useScrollTo';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  button: {
    position: 'relative',
    width: '100%',
    cursor: ({ clickable }) => (clickable ? 'pointer' : 'auto'),
    background: ({ bgColor }) => bgColor,
    borderRadius: theme.shape.borderRadiusLarge,
    padding: theme.spacing(2.5),
    display: 'grid',
    gridGap: theme.spacing(1),
    [theme.breakpoints.only('sm')]: {
      gridGap: theme.spacing(0.5),
      padding: ({ responsive }) => responsive && theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: ({ responsive }) => responsive && 'auto auto',
      gridGap: ({ responsive }) => responsive && theme.spacing(5),
      minWidth: ({ responsive }) => !responsive && '115px',
    },
  },
  disabled: {
    opacity: '0.3',
  },
  time: {
    fontSize: '1.5rem',
    fontWeight: '700',
    [theme.breakpoints.only('sm')]: {
      fontSize: ({ responsive }) => responsive && '1.25rem',
    },
  },
  status: {
    fontWeight: '500',
    fontSize: '1.15rem',
    [theme.breakpoints.only('sm')]: {
      fontSize: ({ responsive }) => responsive && '0.875rem',
    },
  },
  timeIndicator: {
    position: 'absolute',
    width: '100%',
    height: '2px',
    background: theme.palette.primary[300],
    top: ({ timelinePosition }) => `${timelinePosition}%`,
    transition: '2s',
  },
  loading: {
    opacity: '0.3',
  },
}));

const isCurrentSlot = date => {
  if (!isToday(date)) return false;

  const now = new Date();
  if (date.getHours() !== now.getHours()) return false;
  return now.getMinutes() >= 30 ? date.getMinutes() >= 30 : date.getMinutes() < 30;
};

const getMissingMilliseconds = () => {
  const seconds = new Date().getSeconds();
  return (60 - seconds) * 1000;
};

const calculateTimeLinePosition = () => {
  let minutes = new Date().getMinutes();
  if (minutes > 30) minutes -= 30;
  return (minutes / 30) * 100;
};

const TimeSlot = props => {
  const {
    isAvailable,
    isBooked,
    onClick,
    date,
    responsive,
    advisorView,
    showDate,
    showTimeline,
    reload,
    reloadTrigger,
    loading,
    autoScroll,
    isClickableCheck,
    tileHref,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const isClickable = isClickableCheck(props);
  const isDisabled = isBooked ? isPast(date) : isPast(date) || (!advisorView && !isAvailable);

  const href = useMemo(() => {
    if (!tileHref) return undefined;
    if (typeof tileHref === 'string') return tileHref;
    return tileHref(props);
  }, [props]);

  const clickHandler = () => {
    if (onClick && isClickable && !href) onClick(props);
  };

  const resolveTitle = () => {
    if (showDate) {
      if (isToday(date)) return t(...general_messages.today);
      if (isTomorrow(date)) return t(...general_messages.tomorrow);
      return format(date, 'dd LLL');
    }
    if (isBooked) return t(...meeting_messages.reserved);
    if (!isAvailable) return t(...meeting_messages.unavailable);
    return advisorView ? t(...meeting_messages.available) : t(...meeting_messages.book);
  };

  const resolveColor = () => {
    const { palette } = theme;
    if (advisorView) {
      if (!isAvailable) return palette.grey[500];
      if (isBooked) return palette.primary[100];
    }

    if (isBooked) return palette.grey[200];
    return palette.secondary[50];
  };

  // TIME INDICATOR LOGIC
  // render in another component if performance issues occurs
  const isCurrent = isCurrentSlot(date);
  const [timelinePosition, setTimelinePosition] = useState(calculateTimeLinePosition());
  const scheduleRef = useRef();

  const updateTimelinePosition = () => {
    if (!isCurrentSlot(date)) reloadTrigger();
    setTimelinePosition(calculateTimeLinePosition());
  };

  const initUpdateTimelinePosition = () => {
    updateTimelinePosition();
    scheduleRef.current = setInterval(updateTimelinePosition, 60000);
  };

  useEffect(() => {
    if (showTimeline && isCurrentSlot(date)) {
      updateTimelinePosition();
      scheduleRef.current = setTimeout(initUpdateTimelinePosition, getMissingMilliseconds());
    }
    return () => {
      clearInterval(scheduleRef.current);
      clearTimeout(scheduleRef.current);
    };
  }, [reload]);

  // AUTO SCROLL LOGIC
  const currentRef = useRef();
  const { scrollIntoView } = useScrollTo(currentRef);
  useEffect(() => {
    if (autoScroll) scrollIntoView();
  }, [currentRef, autoScroll]);

  const styles = useStyles({ responsive, timelinePosition, bgColor: resolveColor(), clickable: isClickable });
  return (
    <div ref={isCurrent && autoScroll ? currentRef : null} className={clsx(styles.wrapper, loading && styles.loading, isCurrent)}>
      <ButtonBase
        className={clsx(styles.button, isDisabled && styles.disabled)}
        component={isClickable && href ? Link : 'button'}
        disableTouchRipple={!onClick || !isClickable}
        onClick={clickHandler}
        to={href}
      >
        <Typography className={styles.time}>{format(date, 'HH:mm')}</Typography>
        <Typography className={styles.status}>{resolveTitle()}</Typography>
      </ButtonBase>
      {showTimeline && isCurrent && <div className={styles.timeIndicator} />}
    </div>
  );
};

TimeSlot.propTypes = {
  isAvailable: PropTypes.bool.isRequired,
  isBooked: PropTypes.bool,
  responsive: PropTypes.bool,
  advisorView: PropTypes.bool,
  showDate: PropTypes.bool,
  date: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func,
  showTimeline: PropTypes.bool,
  reload: PropTypes.number,
  reloadTrigger: PropTypes.func,
  loading: PropTypes.bool,
  autoScroll: PropTypes.bool,
  isClickableCheck: PropTypes.func,
  tileHref: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

TimeSlot.defaultProps = {
  onClick: null,
  responsive: false,
  isBooked: false,
  advisorView: false,
  showDate: false,
  showTimeline: false,
  reload: 0,
  reloadTrigger: () => {},
  loading: false,
  autoScroll: false,
  isClickableCheck: () => true,
  tileHref: undefined,
};

export default TimeSlot;
