import React from 'react';

import { useTranslation } from 'react-i18next';

import MenuList from 'components/Layout/_components/MenuList';
import MenuSection from 'components/Layout/_components/MenuSection';
import menu_messages from 'messages/menu_messages';
import PATHS from 'router/PATHS';

const ContentSection = () => {
  const { t } = useTranslation();

  const elements = [
    { label: t(...menu_messages.articles), to: PATHS.ARTICLES },
    { label: t(...menu_messages.guides), to: PATHS.GUIDES },
    { label: t(...menu_messages.self_tests), to: PATHS.SELF_TESTS },
  ];
  return (
    <MenuSection title={t(...menu_messages.content)}>
      <MenuList elements={elements} />
    </MenuSection>
  );
};

export default ContentSection;
