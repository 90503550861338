import React from 'react';

import { intersection } from 'lodash';
import { useTranslation } from 'react-i18next';

import MenuList from 'components/Layout/_components/MenuList';
import MenuSection from 'components/Layout/_components/MenuSection';
import ROLES from 'config/constants/ROLES';
import menu_messages from 'messages/menu_messages';
import PATHS from 'router/PATHS';
import userDataStorage from 'storages/userDataStorage';

const { PS_ROLES } = ROLES;

const usedRoles = [PS_ROLES.CONTENT_EDITOR, PS_ROLES.SECURITY_ONLINE];

const PocketSafeAdministrationSection = () => {
  const { t } = useTranslation();
  const { ps_roles } = userDataStorage.get() || {};
  if (!intersection(usedRoles, ps_roles).length) return null;
  const elements = [
    { label: t(...menu_messages.manage_content), to: PATHS.MANAGE_CONTENT },
    { label: t(...menu_messages.meeting_time_slots), to: PATHS.MANAGE_MEETING_SLOTS },
    { label: t(...menu_messages.advisory_page), to: PATHS.ADVISORY_PAGE },
    { label: t(...menu_messages.questions), to: PATHS.QUESTIONS },
  ];

  return (
    <MenuSection title={t(...menu_messages.ps_administrator)}>
      <MenuList elements={elements} />
    </MenuSection>
  );
};

export default PocketSafeAdministrationSection;
