import { intersection } from 'lodash';

import ensureIsArray from 'helpers/ensureIsArray';
import permissions from 'router/permissions';

// TODO refactor arguments
const checkPermissions = (path, userData, dialogType) => {
  const key = path ? path.replace(/\/:.*/, '') : dialogType;
  const permission = permissions[key];
  if (!userData) return true;
  if (!permission) return true;
  const { onlyForPSRoles, onlyForRoles } = permission;
  const { roles, ps_roles } = userData;
  return !!intersection(roles, ensureIsArray(onlyForRoles)).length || !!intersection(ps_roles, ensureIsArray(onlyForPSRoles)).length;
};

export default checkPermissions;
