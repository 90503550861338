import React from 'react';

import { ButtonBase, IconButton, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import MenuList from 'components/Layout/_components/MenuList';
import MenuSection from 'components/Layout/_components/MenuSection';
import menu_messages from 'messages/menu_messages';
import { useAuthContext } from 'reactContext/AuthContext';
import PATHS from 'router/PATHS';
import useLayoutStore from 'storages/layoutStore';
import userDataStorage from 'storages/userDataStorage';

import UserAvatar from '../../../../UserAvatar/UserAvatar';

const useStyles = makeStyles(theme => ({
  button: {
    width: `calc(100% - ${theme.spacing(2)}px)`,
    padding: theme.spacing(1, 2),
    marginBottom: '-8px',
    color: ({ selected }) => (selected ? theme.palette.secondary.main : theme.palette.text.inverted),
    '&.MuiButton-root': {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    '@media (hover: hover)': {
      '&:hover': {
        color: () => theme.palette.secondary.main,
        '&.MuiButton-root': {
          padding: theme.spacing(1),
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
    },
  },
  buttonContent: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gridGap: theme.spacing(2),
    textTransform: 'none',
    textAlign: 'left',
  },
  dashboardText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
    },
  },
  lockIcon: {
    background: theme.palette.common.black,
    borderRadius: '100%',
    padding: '.5rem',
    height: '2.5rem',
    width: '2.5rem',
    color: theme.palette.text.inverted,
  },
}));

const AuthorizedButton = ({ onClick }) => {
  const { isMenuOpen } = useLayoutStore();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { id: userId } = userDataStorage.get() || {};

  const styles = useStyles({ selected: pathname === PATHS.ROOT });
  return isMenuOpen ? (
    <ButtonBase className={styles.button} component={Link} onClick={onClick} to={PATHS.ROOT}>
      <span className={styles.buttonContent}>
        <UserAvatar userId={userId} />
        <Typography className={styles.dashboardText}>{t(...menu_messages.dashboard)}</Typography>
      </span>
    </ButtonBase>
  ) : (
    <IconButton component={Link} disableRipple to={PATHS.ROOT}>
      <UserAvatar userId={userId} />
    </IconButton>
  );
};

const UnauthorizedButton = ({ onClick }) => {
  const { isMenuOpen } = useLayoutStore();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const styles = useStyles({ selected: pathname === PATHS.AUTH_LOGIN });
  return isMenuOpen ? (
    <ButtonBase className={styles.button} component={Link} onClick={onClick} to={PATHS.AUTH_LOGIN}>
      <span className={styles.buttonContent}>
        <LockOutlinedIcon className={styles.lockIcon} />
        <Typography>{t(...menu_messages.login)}</Typography>
      </span>
    </ButtonBase>
  ) : (
    <IconButton component={Link} disableRipple to={PATHS.AUTH_LOGIN}>
      <LockOutlinedIcon className={styles.lockIcon} />
    </IconButton>
  );
};

const UserSection = () => {
  const { isMenuOpen, closeMenu } = useLayoutStore();

  const userData = userDataStorage.get();
  const { isAuthorized } = useAuthContext();
  const { t } = useTranslation();

  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  const onClick = () => {
    if (!upMd) closeMenu();
  };

  const menuElements = () => {
    if (isAuthorized) {
      return [{ label: t(...menu_messages.profile), to: PATHS.MY_PROFILE }];
    }
    return process.env.REACT_APP_REGISTRATION_AVAILABLE === 'true' ? [{ label: t(...menu_messages.sign_up), to: PATHS.AUTH_REGISTER }] : [];
  };

  if (isAuthorized && !userData?.hasPaymentMethod) return null;
  return (
    <MenuSection collapsible={false} keepVisible>
      {isAuthorized ? <AuthorizedButton onClick={onClick} /> : <UnauthorizedButton onClick={onClick} />}
      {isMenuOpen && <MenuList elements={menuElements()} />}
    </MenuSection>
  );
};

export default UserSection;
