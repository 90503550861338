import React, { useCallback, useMemo, useState } from 'react';

import { Container, IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import AddNewWorkplaceDialog from 'components/_dialogs/AddNewWorkplaceDialog/AddNewWorkplaceDialog';
import EditWorkplaceDialog from 'components/_dialogs/EditWorkplaceDialog/EditWorkplaceDialog';
import WorkplacesHelpDialog from 'components/_dialogs/WorkplacesHelpDialog/WorkplacesHelpDialog';
import ColoredButton from 'components/ColoredButton';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import FooterButton from 'components/FooterButton';
import ManagePageHeader from 'components/ManagePageHeader';
import Table from 'components/Table';
import TextButton from 'components/TextButton/TextButton';
import type { Workplace, WorkplaceDTO } from 'config/api/workplaces/_types';
import { parseMultipleWorkplacesForFE } from 'config/api/workplaces/parsers';
import workplaces, { workplacesApi } from 'config/api/workplaces/workplaces';
import ROLES from 'config/constants/ROLES';
import useBoolState from 'hooks/useBoolState';
import usePagination from 'hooks/usePagination';
import usePermissions from 'hooks/usePermissions';
import useResponsiblePermission from 'hooks/useResponsiblePremission';
import general_messages from 'messages/general_messages';
import workplaces_messages from 'messages/workplace_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext';
import PATHS from 'router/PATHS';
import type { TableDataMappingRow } from 'types/Table';

const WorkplacesPage: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isAccountOwner] = usePermissions([ROLES.ORGANIZATION.ACCOUNT_OWNER]);

  const [sort, setSort] = useState<{ key: string | null; direction: string | null }>({ key: null, direction: null });
  const onSort = (key: string, direction: string) => setSort({ key, direction });
  const [search, setSearch] = useState('');
  const { state: instructionDialog, setTrue: openInstructionDialog, setFalse: closeInstructionDialog } = useBoolState(false);
  const { state: addNewDialog, setTrue: openAddNewDialog, setFalse: closeAddNewDialog } = useBoolState(false);
  const [editDialog, setEditDialog] = useState<{ id: number | null; open: boolean }>({ id: null, open: false });
  const closeEditDialog = useCallback(() => setEditDialog({ id: null, open: false }), [setEditDialog]);
  // @ts-ignore
  const { showConfirmationModal } = useConfirmationModalContext();

  const {
    data: tableData,
    getData: getTableData,
    hasNextPage,
    loading,
    totalCount,
    refreshData,
  } = usePagination({
    endpointFunc: workplaces.getWorkplacesForOrganization,
    limits: [30, 20, 10],
    dependencies: [sort, search],
    sort,
    search,
    isGet: true,
  });

  const onEditWorkplace = (id: number) => () => {
    setEditDialog({ id, open: true });
  };

  const onDeleteWorkplace = (workplace: Workplace) => async () => {
    const confirmation = await showConfirmationModal({
      title: t(workplaces_messages.remove.confirmation_title),
      body: t(workplaces_messages.remove.confirmation_body, { workplaceName: workplace.name, usersNumber: workplace.responsibles.length }),
    });
    if (confirmation) {
      try {
        await workplacesApi.removeWorkplace(workplace.id);
        await refreshData();
      } catch (e) {
        enqueueSnackbar(t(general_messages.something_went_wrong), { variant: 'error' });
      }
    }
  };

  const renderContextMenu = (data: Workplace) => {
    const isResponsible = useResponsiblePermission(data.responsibles);

    if (!isResponsible && !isAccountOwner) return null;

    const editMenuItem = { label: t(general_messages.edit), onClick: onEditWorkplace(data.id) };
    const deleteMenuItem = { label: t(general_messages.delete), onClick: onDeleteWorkplace(data) };

    const menuItems = [editMenuItem];
    if (isAccountOwner) menuItems.push(deleteMenuItem);

    return (
      <ContextMenu elements={menuItems} id={`workplace-table-row-${data.id}`}>
        <IconButton>
          <MoreIcon />
        </IconButton>
      </ContextMenu>
    );
  };

  const dataMapping = useMemo(() => {
    const base: TableDataMappingRow<Workplace>[] = [
      {
        id: 'name',
        width: isAccountOwner ? '24%' : '30%',
        label: t(workplaces_messages.table_header.workplace),
        get: ({ name }) => name,
      },
      {
        id: 'responsibles',
        width: '40%',
        label: t(workplaces_messages.table_header.responsible),
        get: ({ responsibles }) => responsibles.map(({ profile }) => `${profile.fullName} `),
        blockSorting: true,
      },
      {
        id: 'location',
        width: '30%',
        label: t(workplaces_messages.table_header.localization),
        get: ({ location }) => location,
      },
    ];

    base.push({
      id: 'context',
      width: '6%',
      label: '',
      isAddon: true,
      get: (data): React.ReactNode => renderContextMenu(data),
    });
    return base;
  }, [t]);

  const getRowHref = ({ id }: WorkplaceDTO) => `${PATHS.WORKPLACES}/${id}`;

  return (
    <Container>
      {/* @ts-ignore */}
      <ManagePageHeader
        rightAdornment={
          <>
            <TextButton onClick={openInstructionDialog}>{t(general_messages.how_it_works)}</TextButton>
            {isAccountOwner && (
              <>
                {/* @ts-ignore */}
                <ColoredButton component={Link} customColor='secondary' to={PATHS.WORKPLACES_TEMPLATE} variant='outlined'>
                  {t(workplaces_messages.buttons.edit_categories)}
                </ColoredButton>
                {/* @ts-ignore */}
                <ColoredButton customColor='secondary' onClick={openAddNewDialog} variant='outlined'>
                  {t(workplaces_messages.buttons.new_workplace)}
                </ColoredButton>
              </>
            )}
          </>
        }
      >
        {totalCount === null ? '' : t(workplaces_messages.workplaces_page_header, { workplacesCount: totalCount })}
      </ManagePageHeader>
      <Table
        clickableRow
        data={tableData && parseMultipleWorkplacesForFE(tableData)}
        dataMapping={dataMapping}
        enableSearch
        getRowHref={getRowHref}
        onSearch={setSearch}
        onSort={onSort}
        refetching={loading}
        sort={sort}
      />
      {hasNextPage && (
        // @ts-ignore
        <FooterButton loading={loading} onClick={getTableData}>
          {t(general_messages.show_more)}
        </FooterButton>
      )}
      <AddNewWorkplaceDialog onClose={closeAddNewDialog} open={addNewDialog} refreshData={refreshData} />
      {editDialog.open && (
        <EditWorkplaceDialog
          onClose={closeEditDialog}
          open={editDialog.open}
          refreshData={refreshData}
          workplaceId={editDialog.id as number}
        />
      )}
      <WorkplacesHelpDialog onClose={closeInstructionDialog} open={instructionDialog} />
    </Container>
  );
};

export default WorkplacesPage;
