import React, { Suspense, useEffect, useMemo } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import Loader from 'components/Loader';
import { commonApi } from 'config/api/common';
import axiosSetup, { refreshToken } from 'config/axios';
import AuthProvider from 'reactContext/AuthContext';
import Router from 'router/Router';
// eslint-disable-next-line import/extensions
import useBrandingStore from 'storages/brandingStore';
import globalStyles from 'styles/globalStyles';
import theme from 'styles/theme';

import EVENTS from '../../config/events/pubsub';
import useSubscription from '../../hooks/useSubscription';
import general_messages from '../../messages/general_messages';

const GlobalCss = withStyles(globalStyles)(() => null);
axiosSetup();
refreshToken();

const mergeTheme = (baseTheme: typeof theme, overrideTheme: any): typeof theme => {
  const result: any = cloneDeep(theme);
  if (!overrideTheme) return baseTheme;
  result.palette = {
    ...result.palette,
    primary: {
      contrastText: overrideTheme.primary_contrast_text,
      dark: overrideTheme.primary_dark,
      light: overrideTheme.primary_light,
      main: overrideTheme.primary_main,
      50: overrideTheme.primary_50,
      100: overrideTheme.primary_100,
      200: overrideTheme.primary_200,
      300: overrideTheme.primary_300,
      400: overrideTheme.primary_400,
      500: overrideTheme.primary_500,
      600: overrideTheme.primary_600,
      700: overrideTheme.primary_700,
      800: overrideTheme.primary_800,
      900: overrideTheme.primary_900,
    },
    secondary: {
      contrastText: overrideTheme.secondary_contrast_text,
      dark: overrideTheme.secondary_dark,
      light: overrideTheme.secondary_light,
      main: overrideTheme.secondary_main,
      50: overrideTheme.secondary_50,
      100: overrideTheme.secondary_100,
      200: overrideTheme.secondary_200,
      300: overrideTheme.secondary_300,
      400: overrideTheme.secondary_400,
      500: overrideTheme.secondary_500,
      600: overrideTheme.secondary_600,
      700: overrideTheme.secondary_700,
      800: overrideTheme.secondary_800,
      900: overrideTheme.secondary_900,
    },
    background: {
      ...result.palette.background,
      dark: overrideTheme.background_dark || result.palette.background.dark,
    },
  };
  return result;
};

const App = () => {
  const { t } = useTranslation();
  const { data } = useQuery('Get env settings', commonApi.getEnvSettings, { refetchOnWindowFocus: false });
  const { setLogo, setFavicon, favicon, pageTitle, setPageTitle } = useBrandingStore();
  const { enqueueSnackbar } = useSnackbar();

  const mergedTheme = useMemo(() => {
    if (!data) return null;
    return mergeTheme(theme, data.data);
  }, [data]);

  useEffect(() => {
    if (data) {
      setFavicon(data.data.favicon);
      setLogo({
        full: data.data.logo_full,
        symbol: data.data.logo_symbol,
      });
      setPageTitle(data.data.page_title || 'PocketSafe');
    }
  }, [data]);

  const callback = () => {
    enqueueSnackbar(t(general_messages.something_went_wrong), { variant: 'error' });
  };
  useSubscription(EVENTS.API_MUTATION_ERROR, callback);
  useSubscription(EVENTS.API_FETCH_ERROR, callback);

  if (mergedTheme === null) return <Loader open />;
  return (
    <ThemeProvider theme={mergedTheme as typeof theme}>
      {favicon && (
        <Helmet>
          <link href={favicon} rel='icon' sizes='any' />
        </Helmet>
      )}
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Suspense fallback={<Loader open />}>
        <CssBaseline />
        <GlobalCss />
        <AuthProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AuthProvider>
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </ThemeProvider>
  );
};

export default App;
