import React, { useMemo } from 'react';

import { ButtonBase, List, ListItem, ListItemText, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import checkPermissions from 'services/checkPermissions';
import userDataStorage from 'storages/userDataStorage';

import { useCommonDialogsContext } from '../../../../reactContext/CommonDialogsContext';
import useLayoutStore from '../../../../storages/layoutStore';

const useStyles = makeStyles(theme => ({
  list: {
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0),
    },
  },
  item: {
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.secondary.light,
      },
    },
    display: ({ isMenuOpen }) => (isMenuOpen ? 'flex' : 'none'),
  },
  itemText: {
    '& span': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem !important',
      },
    },
  },
  active: {
    color: theme.palette.secondary.main,
    position: 'relative',
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    '&:before': {
      display: 'block',
      content: '"•"',
      position: 'absolute',
      left: 5,
    },
  },
}));

const MenuList = ({ elements }) => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const { isMenuOpen, closeMenu } = useLayoutStore();
  const userData = userDataStorage.get();
  const { openCommonDialog } = useCommonDialogsContext();
  const filteredElements = useMemo(() => {
    if (!userData) return elements;
    return elements.filter(({ to, dialogType }) => checkPermissions(to, userData, dialogType));
  }, [userData]);

  const onClick = dialogType => {
    if (!upMd) closeMenu();
    if (dialogType) openCommonDialog(dialogType);
  };

  const styles = useStyles({ isMenuOpen });
  return (
    <List className={styles.list}>
      {filteredElements.map(({ label, to, dialogType }) => (
        <ListItem
          key={label}
          activeClassName={styles.active}
          button
          className={styles.item}
          component={to ? NavLink : ButtonBase}
          data-cy={to ? `menu_link_to-${to.replace('/', '')}` : `menu_action-${dialogType}`}
          disableRipple
          exact
          onClick={() => onClick(dialogType)}
          to={to}
        >
          <ListItemText classes={{ root: styles.itemText }} primary={label} />
        </ListItem>
      ))}
    </List>
  );
};

MenuList.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      onlyForRoles: PropTypes.arrayOf(PropTypes.number),
      onlyForPSRoles: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
};

export default MenuList;
