import React from 'react';

import { FormControlLabel, makeStyles, Tooltip, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

import CustomAccordion from '../CustomAccordion';

const useStyles = makeStyles(theme => ({
  tile: {
    border: `thin solid ${theme.palette.secondary[100]}`,
    borderRadius: theme.shape.borderRadiusLarge,
    padding: theme.spacing(1.25, 2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  accordionTile: {
    border: `thin solid ${theme.palette.secondary[100]}`,
    borderRadius: theme.shape.borderRadiusLarge,
    padding: theme.spacing(0.75, 2),
    '& .MuiAccordionSummary-content': {
      margin: '0',
    },
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    marginRight: 0,
  },
  label: {
    flexGrow: 1,
  },
  selected: {
    background: theme.palette.secondary[50],
  },
  tooltip: {
    maxWidth: '180px',
    background: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    color: theme.palette.text.secondary,
    fontSize: '.825rem',
  },
  icon: {
    fill: theme.palette.secondary[900],
  },
}));

const CheckboxTile = ({ label, onChange, selected, control, description, className, expandable, ...rest }) => {
  const styles = useStyles();

  const stopPropagation = event => event.stopPropagation();

  if (expandable && description) {
    return (
      <CustomAccordion
        accordionClassName={clsx(styles.accordionTile, selected && styles.selected, className)}
        heading={
          <FormControlLabel
            classes={{ label: styles.label, root: styles.root }}
            control={control}
            label={label}
            onChange={onChange}
            onClick={stopPropagation}
            onFocus={stopPropagation}
            {...rest}
          />
        }
      >
        <Typography dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }} />
      </CustomAccordion>
    );
  }

  return (
    <div className={clsx(styles.tile, selected && styles.selected, className)}>
      <FormControlLabel
        classes={{ label: styles.label, root: styles.root }}
        control={control}
        label={label}
        onChange={onChange}
        {...rest}
      />
      {description && (
        <Tooltip classes={{ tooltip: styles.tooltip }} title={description}>
          <InfoOutlinedIcon className={styles.icon} />
        </Tooltip>
      )}
    </div>
  );
};

CheckboxTile.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.bool.isRequired,
  control: PropTypes.node.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  expandable: PropTypes.bool,
};

CheckboxTile.defaultProps = {
  onChange: () => {},
  description: null,
  className: null,
  expandable: false,
};

export default CheckboxTile;
