import React from 'react';

import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid';
import ColoredButton from 'components/ColoredButton/ColoredButton';
import FormAutocomplete from 'components/FormAutocomplete';
import FormTextInput from 'components/FormTextInput';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import type { WorkplaceCreate } from 'config/api/workplaces/_types';
import { workplacesApi } from 'config/api/workplaces/workplaces';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';
import workplace_messages from 'messages/workplace_messages';
import coworkerOptionsParser from 'services/autocompleteServices/coworkerOptionsParser/coworkerOptionsParser';
import coworkerParamsGetter from 'services/autocompleteServices/coworkerParamsGetter/coworkerParamsGetter';
import coworkerResultComponent from 'services/autocompleteServices/coworkerResultComponent/coworkerResultComponent';

import ButtonsGrid from '../_components/ButtonsGrid';
import DialogGrid from '../_components/DialogGrid';

type Props = {
  open: boolean;
  onClose: () => void;
  refreshData: () => Promise<any>;
};

const FORM_ID = 'AddNewWorkplaceForm';

const AddNewWorkplaceDialog: React.FC<Props> = ({ onClose, open, refreshData }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation(QUERY_KEYS.CREATE_WORKPLACE, workplacesApi.createWorkplace, {
    onSuccess: async () => {
      await refreshData();
      enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
      onClose();
    },
    onError: ({ response }) => {
      Object.values(response.data).forEach(errorMessage => enqueueSnackbar(errorMessage as string, { variant: 'error' }));
    },
  });
  const onSubmit = (formData: WorkplaceCreate) => {
    mutation.mutate(formData);
  };

  const formik = useFormik<WorkplaceCreate>({
    initialValues: {
      location: '',
      responsibles: [],
      name: '',
    },
    validationSchema: yup.object({
      location: yup.string().required(t(validation_messages.required)),
      name: yup.string().required(t(validation_messages.required)),
    }),
    onSubmit,
  });

  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          {/* @ts-ignore */}
          <ColoredButton customColor='none' disabled={mutation.isLoading} onClick={onClose} variant='outlined'>
            {t(general_messages.cancel)}
          </ColoredButton>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={mutation.isLoading} form={FORM_ID} type='submit' variant='outlined'>
            {t(general_messages.save)}
          </ColoredButton>
        </ButtonsGrid>
      }
      onClose={onClose}
      open={open}
      title={t(workplace_messages.newWorkplace.dialogTitle)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        {/* @ts-ignore */}
        <CenteredGrid gridGap={2} withoutPadding>
          <FormTextInput formik={formik} id='name' label={t(workplace_messages.form.workplaceName)} />
          <FormTextInput formik={formik} id='location' label={t(workplace_messages.form.localization)} />
          <FormAutocomplete
            apiCallParamsGetter={coworkerParamsGetter}
            customizeLabel={coworkerResultComponent}
            formik={formik}
            id='responsibles'
            label={t(workplace_messages.form.responsibles)}
            optionsParser={coworkerOptionsParser}
          />
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

export default AddNewWorkplaceDialog;
