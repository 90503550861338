import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  header: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(3, 4),
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    gridGap: theme.spacing(2),
  },
  body: {
    padding: theme.spacing(5),
  },
  closeIcon: {
    // @ts-ignore
    color: theme.palette.secondary[900],
  },
  editButton: {
    position: 'relative',
    '&:before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '2px',
      height: '60%',
      top: '20%',
      right: `-${theme.spacing(0.5) + 1}px`,
      // @ts-ignore
      background: theme.palette.secondary[800],
    },
  },
  headerButtons: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  categorySection: {
    display: 'grid',
    gridGap: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  contentGrid: {
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  contentRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridGap: theme.spacing(2),
  },
  responsibles: {
    paddingTop: theme.spacing(3),
    // @ts-ignore
    borderTop: `2px solid ${theme.palette.primary[300]}`,
  },
  responsiblesGrid: {
    paddingTop: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: theme.spacing(3),

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
    },
  },
  responsibleTile: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(3),
  },
  responsibleName: {
    display: 'grid',
  },
}));
