import { useEffect, useMemo, useState } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';

import DIRECTIONS from 'config/constants/sortDirections';
import useApiCall from 'hooks/useApiCall';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import useLoadingState from 'hooks/useLoadingState';

let lastFetch;
/**
 *
 * @param {Function} endpointFunc
 * @param {Array} dependencies
 * @param {Array} pageSizes
 * @param {Object?} filters
 * @param {Object?} params
 * @param {Object?} sort
 * @param {Boolean} omitInitialCall=false
 * @param {Boolean} sticky=false
 * @param {Boolean} isGet=false
 * @param {String} search
 * @returns {{data: Array, hasNextPage: Boolean, loading: Boolean, getData: ((function(): Promise<void>)|*)}}
 */
const minInterval = 100;
const usePagination = ({
  endpointFunc,
  dependencies = [],
  limits = [15, 10, 5],
  filters = {},
  params = {},
  sort = {},
  queryParams = {},
  omitInitialCall = false,
  isGet = false,
  sticky = false,
  disabled = false,
  search,
}) => {
  const theme = useTheme();
  const largeMQ = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const mediumMQ = useMediaQuery(theme.breakpoints.only('md'), { noSsr: true });
  const smallMQ = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const limit = useMemo(() => {
    const [lg, md, sm] = limits;
    if (largeMQ) return lg;
    if (mediumMQ) return md;
    if (smallMQ) return sm;
    return undefined;
  }, [largeMQ, mediumMQ, smallMQ]);

  // const getLimit = () => {
  //   const { values } = theme.breakpoints;
  //   const [lg, md, sm] = limits;
  //   const screenSize = window.innerWidth;
  //   if (screenSize <= values.md) return sm;
  //   if (screenSize <= values.lg) return md;
  //   return lg;
  // };

  const { apiCall } = useApiCall();
  const { loading, setLoading, setLoaded } = useLoadingState(true);

  const [offset, setOffset] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(null);
  const [data, setData] = useState(null);
  const [initialFetched, setInitialFetched] = useState(false);
  const [totalCount, setTotalCount] = useState(null);

  const orderBy = useMemo(() => {
    const { internalKey, key, direction } = sort;
    const keyToUse = internalKey || key;
    if (keyToUse && direction) {
      let finalKey = keyToUse;
      if (direction === DIRECTIONS.DESC) finalKey = keyToUse;
      if (direction === DIRECTIONS.ASC) finalKey = `-${keyToUse}`;
      return sticky ? ['-is_sticky', finalKey] : [finalKey];
    }
    return null;
  }, [sort]);

  const getQuerySet = () => {
    if (isGet) {
      return { ...(orderBy && { ordering: orderBy }), ...(search && { search }), ...filters, ...queryParams };
    }
    return {};
  };

  const getConfig = () => {
    if (!isGet) {
      return { data: { ...filters, ...(orderBy && { order_by: orderBy }), ...(search && { search }), ...params } };
    }
    return {};
  };

  const getData = async () => {
    if (disabled) return;
    setLoading();
    const { data: dataFormAPI } = await apiCall(endpointFunc({ limit, offset, ...getQuerySet() }), getConfig());
    setHasNextPage(!!dataFormAPI.next);
    setOffset(prevState => prevState + limit);
    setData(prevState => (prevState ? [...prevState, ...dataFormAPI.results] : dataFormAPI.results));
    setTotalCount(dataFormAPI.count);
    setLoaded();
  };

  const getDataWithReset = async offsetOverride => {
    if (disabled) return;
    setLoading();
    setData(null);
    const localLimit = offsetOverride || limit;
    const { data: dataFormAPI } = await apiCall(endpointFunc({ limit: localLimit, offset: 0, ...getQuerySet() }), getConfig());
    setOffset(localLimit);
    setHasNextPage(!!dataFormAPI.next);
    setData(dataFormAPI.results);
    setLoaded();
  };

  const refreshData = () => getDataWithReset(offset);

  useEffect(() => {
    if (!omitInitialCall && limit && !initialFetched) {
      setInitialFetched(true);
      getData();
    }
  }, [limit]);

  useDidUpdateEffect(() => {
    const now = new Date().getTime();
    if (limit && (initialFetched || omitInitialCall) && (!lastFetch || now - lastFetch > minInterval)) {
      setInitialFetched(true);
      getDataWithReset();
      lastFetch = now;
    }
  }, [...dependencies, limit]);

  useDidUpdateEffect(() => {
    getDataWithReset(offset);
  }, [orderBy]);

  return { data, getData, hasNextPage, loading, refreshData, initialLoading: loading && !initialFetched, totalCount };
};

export default usePagination;
