import React from 'react';

import useStyles from './CarouselWrapper.styles';

type Props = {
  children: React.ReactNode;
};

const CarouselWrapper: React.FC<Props> = ({ children }) => {
  const styles = useStyles({});
  return (
    <div className={styles.externalWrapper}>
      <div className={styles.internalWrapper}>
        <div className={styles.scrollableElement}>{children}</div>
      </div>
    </div>
  );
};

export default CarouselWrapper;
