import { get } from 'lodash/object';

import ensureIsArray from 'helpers/ensureIsArray';

const mergeWithCommas = (value, path) => {
  let arr = ensureIsArray(value);
  if (path) arr = arr.map(element => get(element, path));
  return arr.join(', ');
};

export default mergeWithCommas;
