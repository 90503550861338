import React, { MouseEventHandler, useEffect } from 'react';

import { ButtonBase, Tooltip } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import clsx from 'clsx';
import PubSub from 'pubsub-js';

import EVENTS from 'config/events/pubsub';
import useBoolState from 'hooks/useBoolState';
import useSubscription from 'hooks/useSubscription';

import useStyles from './ContextMenu.styles';

type Elements = {
  label: string;
  onClick: (data?: any) => void;
  disabled?: boolean;
}[];

type Props = {
  children: React.ReactElement<any, any>;
  elements: Elements;
  id?: string;
};

const TooltipContent: React.FC<{ elements: Elements; closeTooltip: () => void }> = ({ elements, closeTooltip }) => {
  const preventPropagation = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, callback: (data?: any) => void, isDisabled?: boolean) => {
    e.stopPropagation();
    if (isDisabled) return;
    callback();
    closeTooltip();
  };
  const styles = useStyles();

  return (
    <div className={styles.tooltipContent}>
      {elements.map(({ label, onClick, disabled }) => (
        <ButtonBase
          className={clsx(styles.button, disabled && styles.buttonDisabled)}
          disableRipple={disabled}
          disableTouchRipple={disabled}
          onClick={e => preventPropagation(e, onClick, disabled)}
        >
          {label}
        </ButtonBase>
      ))}
    </div>
  );
};

const ContextMenu: React.FC<Props> = ({ children, elements, id }) => {
  const { state, setFalse, toggle } = useBoolState(false);
  const onOpen: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    toggle();
  };

  useSubscription(EVENTS.CONTEXT_MENU_OPENED, (_: string, eventData: { id: string }) => {
    if (eventData.id !== id) setFalse();
  });

  useEffect(() => {
    if (state && id) PubSub.publish(EVENTS.CONTEXT_MENU_OPENED, { id });
  }, [state]);

  const styles = useStyles();

  return (
    <ClickAwayListener onClickAway={setFalse}>
      <Tooltip
        classes={{ tooltip: styles.tooltip }}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        interactive
        onClose={setFalse}
        open={state}
        title={<TooltipContent closeTooltip={setFalse} elements={elements} />}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div aria-roledescription='button' onClick={onOpen}>
          {children}
        </div>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default ContextMenu;
