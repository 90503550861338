import React, { useEffect, useState } from 'react';

import { makeStyles, Button, Checkbox, ButtonBase, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { add } from 'date-fns';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid';
import CheckboxTile from 'components/CheckboxTile';
import ColoredButton from 'components/ColoredButton';
import DateTimeDetails from 'components/DateTimeDetails';
import FormSelect from 'components/FormSelect';
import FormTextInput from 'components/FormTextInput';
import incidentReportsEndpoints from 'config/api/incident_reports';
import meetingsEndpoints from 'config/api/meetings';
import ROLES from 'config/constants/ROLES';
import EVENTS from 'config/events/pubsub';
import phoneNumberRegex from 'config/validators/phoneNumberRegex';
import parseDate from 'helpers/parseDate';
import useApiCall from 'hooks/useApiCall';
import useLoadingState from 'hooks/useLoadingState';
import usePermissions from 'hooks/usePermissions';
import general_messages from 'messages/general_messages';
import meeting_messages from 'messages/meeting_messages';
import validation_messages from 'messages/validation_messages';
import prepareDataForSelect from 'services/prepareDataForSelect';

const useStyles = makeStyles(theme => ({
  addAgendaItemButton: {
    border: `thin solid ${theme.palette.secondary[100]}`,
    borderRadius: theme.shape.borderRadiusLarge,
    padding: theme.spacing(0.5, 2),
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(0.5),
    alignItems: 'center',
    justifyItems: 'flex-start',
    marginBottom: theme.spacing(3),
    minHeight: '50px',
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
}));

const FORM_ID = 'BookMeetingDialog';

const FORM = {
  CONTACT: 'phone_number',
  AGENDA: 'agenda_items',
  INCIDENT_REPORT: 'incident_report_id',
  INCIDENT_REPORT_DESCRIPTION: 'advice_on',
};

const BookMeetingDialog = ({ onClose, date, isEdit, id, open, refresh, knowledgeArea }) => {
  const { t } = useTranslation();
  const { apiCall } = useApiCall();
  const [addIncidentReport, setAddIncidentReport] = useState(false);
  const { loading, setLoaded, setLoading } = useLoadingState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isAdviser, isSupervisor] = usePermissions([ROLES.PS_ROLES.SECURITY_ONLINE, ROLES.PS_ROLES.SECURITY_SUPERVISOR]);

  const onUpdate = snackBarMessage => {
    onClose();
    if (refresh) refresh();
    PubSub.publish(EVENTS.MEETINGS_UPDATED);
    if (snackBarMessage) enqueueSnackbar(t(...snackBarMessage), { variant: 'success' });
  };

  const onSubmit = async rawData => {
    setLoading();
    const data = { ...rawData };
    data[FORM.AGENDA] = rawData[FORM.AGENDA].filter(value => !!value).map(topic => ({ topic }));
    data.begin = date.toISOString();
    data.end = add(date, { minutes: 30 }).toISOString();
    if (knowledgeArea) data.knowledge_area_id = knowledgeArea;
    if (!addIncidentReport) delete data[FORM.INCIDENT_REPORT];
    if (isEdit && id) {
      const { status } = await apiCall(meetingsEndpoints.updateMeetingAsSecurityOfficer(id), { data });
      if (status < 300) onUpdate(meeting_messages.meeting_updated);
    } else {
      const { status } = await apiCall(meetingsEndpoints.createMeetingAsSecurityOfficer(), { data });
      if (status < 300) onUpdate(meeting_messages.meeting_created);
    }
    setLoaded();
  };

  const formik = useFormik({
    initialValues: {
      [FORM.CONTACT]: '',
      [FORM.AGENDA]: [''],
      [FORM.INCIDENT_REPORT]: '',
      [FORM.INCIDENT_REPORT_DESCRIPTION]: '',
    },
    onSubmit,
    validationSchema: yup.object({
      [FORM.CONTACT]: yup.string(t(...validation_messages.required)).required(t(...validation_messages.required)),
      [FORM.AGENDA]: yup.array().of(
        // eslint-disable-next-line func-names
        yup.string().test('first_agenda_item', t(...validation_messages.required), function (value) {
          /* eslint-disable react/no-this-in-sfc */
          // const [{ value: formValue }] = this.from;
          // if (formValue[FORM.INCIDENT_REPORT] && formValue[FORM.INCIDENT_REPORT_DESCRIPTION]) return true;
          return this.path !== `${FORM.AGENDA}[0]` || value;
          /* eslint-enable react/no-this-in-sfc */
        }),
      ),
      ...(addIncidentReport && {
        [FORM.INCIDENT_REPORT]: yup.string(t(...validation_messages.required)).required(t(...validation_messages.required)),
      }),
    }),
  });

  const onIncidentReportCheckboxChange = (e, v) => setAddIncidentReport(v);

  const [incidentReports, setIncidentReports] = useState([]);
  const getIncidentReports = async () => {
    const { data } = await apiCall(incidentReportsEndpoints.getAllAsActiveUser());
    setIncidentReports(prepareDataForSelect(data, 'id', ({ name, created_at }) => `${name} (${parseDate(created_at)})`));
  };

  const addAgendaItem = () => {
    const emptyIndex = formik.values[FORM.AGENDA].findIndex(e => !e);
    if (emptyIndex >= 0) enqueueSnackbar(t(...meeting_messages.fill_all_agenda_items), { variant: 'warning' });
    else formik.setFieldValue(FORM.AGENDA, [...formik.values[FORM.AGENDA], '']);
  };

  useEffect(() => {
    getIncidentReports();
  }, []);

  const getInitialData = async () => {
    const { data } = await (() => {
      if (isSupervisor) {
        return apiCall(meetingsEndpoints.getMeetingAsSupervisor(id), {}, { cache: true });
      }
      if (isAdviser) {
        return apiCall(meetingsEndpoints.getMeetingAsAdviser(id), {}, { cache: true });
      }
      return apiCall(meetingsEndpoints.getMeetingAsSecurityOfficer(id), {}, { cache: true });
    })();
    if (data[FORM.INCIDENT_REPORT]) setAddIncidentReport(true);
    const values = { ...data };
    values[FORM.AGENDA] = data[FORM.AGENDA].map(({ topic }) => topic);
    formik.setValues(values);
  };

  useEffect(() => {
    if (isEdit && id) getInitialData();
  }, [isEdit, id]);

  const styles = useStyles();
  return (
    <DialogGrid
      dialogActions={
        <CenteredGrid gridGap={2} withoutPadding>
          <ColoredButton
            customColor='secondary'
            disabled={!formik.isValid || !formik.dirty}
            form={FORM_ID}
            showLoader={loading}
            type='submit'
            variant='outlined'
          >
            {isEdit ? t(...general_messages.update) : t(...meeting_messages.book_meeting)}
          </ColoredButton>
          <Button disabled={loading} onClick={onClose} variant='outlined'>
            {t(...general_messages.cancel)}
          </Button>
        </CenteredGrid>
      }
      fullWidth={false}
      onClose={onClose}
      open={open}
      title={t(...meeting_messages.book_meeting_dialog_title)}
    >
      <CenteredGrid tight>
        <DateTimeDetails date={date} duration={{ minutes: 30 }} />
      </CenteredGrid>
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        <CenteredGrid gridGap={2} tight title={t(...meeting_messages.contact_data_section_title)} withoutPadding>
          <Typography>{t(...meeting_messages.meeting_phone_clarification)}</Typography>
          <FormTextInput blockedChars={phoneNumberRegex} formik={formik} id={FORM.CONTACT} label={t(...general_messages.phone)} required />
        </CenteredGrid>
        <CenteredGrid gridGap={1.5} title={t(...meeting_messages.agenda_section_title)} width='sm' withoutPadding>
          {formik.values[FORM.AGENDA].map((agendaElement, index) => (
            <FormTextInput
              key={index}
              formik={formik}
              id={`${FORM.AGENDA}[${index}]`}
              label={t(...meeting_messages.add_agenda_item_input_placeholder)}
              multiline
              rows={3}
            />
          ))}
          <ButtonBase className={styles.addAgendaItemButton} onClick={addAgendaItem}>
            <AddIcon />
            <Typography>{t(...meeting_messages.add_agenda_item_button_label)}</Typography>
          </ButtonBase>
          <CheckboxTile
            checked={addIncidentReport}
            className={styles.marginBottom}
            control={<Checkbox />}
            label={t(...meeting_messages.add_incident_report_checkbox_label)}
            onChange={onIncidentReportCheckboxChange}
            selected={addIncidentReport}
          />
          {addIncidentReport && (
            <>
              <FormSelect
                formik={formik}
                id={FORM.INCIDENT_REPORT}
                label={t(...meeting_messages.add_incident_report_dropdown_placeholder)}
                options={incidentReports}
                required
              />
              <FormTextInput
                formik={formik}
                id={FORM.INCIDENT_REPORT_DESCRIPTION}
                label={t(...meeting_messages.add_incident_report_field_placeholder)}
                multiline
              />
            </>
          )}
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

BookMeetingDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  refresh: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

BookMeetingDialog.defaultProps = {
  isEdit: false,
  id: null,
  refresh: null,
};

export default BookMeetingDialog;
