import React, { useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import EditOrganizationDataDialog from 'components/_dialogs/EditOrganizationDataDialog';
import TerminateAccountDialog from 'components/_dialogs/TerminateAccountDialog';
import ColoredButton from 'components/ColoredButton';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { usersApi } from 'config/api/users';
import { employeesNumberResolver } from 'config/dictionaries/EMPLOYES_NUMBER_SELECT';
import EVENTS from 'config/events/pubsub';
import parseDate from 'helpers/parseDate';
import useBoolState from 'hooks/useBoolState';
import useQueryParams from 'hooks/useQueryParams';
import useSubscription from 'hooks/useSubscription';
import auth_messages from 'messages/auth_messages';
import general_messages from 'messages/general_messages';
import organization_administration_messages from 'messages/organization_administration_messages';

import useStyles from './OrganizationAdministrationManage.styles';

const OrganizationAdministrationManage = ({ data: initialData, loading, organizationId }) => {
  const { t } = useTranslation();
  const { queryParams } = useQueryParams();
  const { state: terminateDialog, setFalse: closeTerminateDialog, setTrue: openTerminateDialog } = useBoolState(false);
  const { state: editDialog, setFalse: closeEditDialog, setTrue: openEditDialog } = useBoolState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [orgData, setOrgData] = useState(initialData);
  const {
    data: pricingData,
    isLoading: isPriceLoading,
    refetch: refetchPricing,
  } = useQuery(QUERY_KEYS.PRICING_INFO, () => usersApi.getOrganizationPricingInfo(organizationId));

  const updateData = (_, dataToUpdate) => {
    refetchPricing();
    setOrgData({
      name: dataToUpdate.name,
      organization_number: dataToUpdate.organization_number,
      country: dataToUpdate.country,
      campaign_code: dataToUpdate.campaign_code,
      ...dataToUpdate.infos,
    });
  };

  useEffect(() => {
    setOrgData(initialData);
  }, [initialData]);

  useSubscription(EVENTS.ORGANIZATION_DATA_UPDATED, updateData);
  const getContactData = contactData => {
    if (!contactData?.contact_first_name && !contactData?.contact_last_name) return t(...general_messages.no_data);
    return `${contactData?.contact_first_name || ''} ${contactData?.contact_last_name || ''}`;
  };

  useEffect(() => {
    const { stripe_success, stripe_failure } = queryParams;
    if (stripe_failure || stripe_success) {
      if (stripe_success) enqueueSnackbar(t(...auth_messages.payment.success), { variant: 'success' });
      if (stripe_failure) enqueueSnackbar(t(...auth_messages.payment.failed), { variant: 'error' });
    }
  }, [queryParams]);

  const styles = useStyles();
  return (
    <>
      <div>
        <div className={styles.container}>
          <section>
            <Typography className={styles.nameHeader} component='h3' variant='h3'>
              {loading ? (
                <>
                  <Skeleton height='2rem' width='50%' />
                  <Skeleton height='30px' variant='circle' width='30px' />
                </>
              ) : (
                <>
                  <span>{orgData?.name}</span>
                  <ColoredButton customColor='none' icon onClick={openEditDialog}>
                    <EditOutlinedIcon fontSize='small' />
                  </ColoredButton>
                </>
              )}
            </Typography>
            <div className={styles.contentBlock}>
              {loading ? (
                <Skeleton height='3rem' width='70%' />
              ) : (
                <>
                  <Typography component='p' variant='body2'>
                    {orgData?.organization_number}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {orgData?.business_type && t(`general>>business_types>>${orgData?.business_type}`, orgData?.business_type)}
                  </Typography>
                </>
              )}
            </div>
            <div className={styles.contentBlock}>
              {loading ? (
                <Skeleton height='2rem' width='90%' />
              ) : (
                <Typography component='p' variant='body2'>
                  {t(...organization_administration_messages.employees)}: {t(...employeesNumberResolver(orgData?.organization_size))}
                </Typography>
              )}
            </div>
          </section>
          <section>
            <Typography component='h3' variant='h3'>
              {t(...organization_administration_messages.contact_person)}
            </Typography>
            <div className={styles.contentBlock}>
              {loading ? (
                <Skeleton height='2rem' width='80%' />
              ) : (
                <>
                  <Typography component='p' variant='body2'>
                    {getContactData(orgData)}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {orgData?.contact_email}
                  </Typography>
                </>
              )}
            </div>
          </section>
          {orgData?.campaign_code && (
            <section>
              <Typography component='h3' variant='h3'>
                {t(...organization_administration_messages.campaign_code)}
              </Typography>
              <div className={styles.contentBlock}>
                {loading ? (
                  <Skeleton height='2rem' width='80%' />
                ) : (
                  <Typography component='p' variant='body2'>
                    {orgData?.campaign_code}
                  </Typography>
                )}
              </div>
            </section>
          )}
          <section>
            <Typography component='h3' variant='h3'>
              {t(...organization_administration_messages.pricing_info.title)}
            </Typography>
            <div className={styles.contentBlock}>
              {isPriceLoading ? (
                <>
                  <Skeleton height='2rem' width='80%' />
                  <Skeleton height='2rem' width='80%' />
                  <Skeleton height='2rem' width='80%' />
                  <Skeleton height='2rem' width='80%' />
                  <Skeleton height='2rem' width='80%' />
                  <Skeleton height='2rem' width='80%' />
                </>
              ) : (
                <>
                  <Typography component='p' variant='body2'>
                    {`${t(...organization_administration_messages.pricing_info.number_of_free_months_for_users)}: ${
                      pricingData?.data?.number_of_free_months_for_users
                    }`}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {`${t(...organization_administration_messages.pricing_info.price_for_first_active_user)}: ${
                      pricingData?.data?.price_for_first_active_user
                    }`}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {`${t(...organization_administration_messages.pricing_info.price_for_second_and_next_active_user)}: ${
                      pricingData?.data?.price_for_second_and_next_active_user
                    }`}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {`${t(...organization_administration_messages.pricing_info.price_for_advisory_meeting)}: ${
                      pricingData?.data?.price_for_advisory_meeting
                    }`}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {`${t(...organization_administration_messages.pricing_info.valid_from)}: ${parseDate(pricingData?.data?.valid_from)}`}
                  </Typography>
                  <Typography component='p' variant='body2'>
                    {`${t(...organization_administration_messages.pricing_info.valid_to)}: ${parseDate(pricingData?.data?.valid_to)}`}
                  </Typography>
                </>
              )}
            </div>
          </section>
          <section>
            <ColoredButton
              className={styles.terminateButton}
              customColor='danger'
              disabled={loading}
              onClick={openTerminateDialog}
              textLike
            >
              {t(...organization_administration_messages.terminate_organization)}
            </ColoredButton>
          </section>
        </div>
      </div>
      <EditOrganizationDataDialog initialData={orgData} onClose={closeEditDialog} open={editDialog} />
      <TerminateAccountDialog onClose={closeTerminateDialog} open={terminateDialog} />
    </>
  );
};

OrganizationAdministrationManage.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape({
    name: PropTypes.string,
    organization_number: PropTypes.string,
    business_type: PropTypes.string,
    organization_size: PropTypes.string,
    contact_first_name: PropTypes.string,
    contact_last_name: PropTypes.string,
    contact_email: PropTypes.string,
    billing_email: PropTypes.string,
    billing_street: PropTypes.string,
    billing_zip_code: PropTypes.string,
    billing_city: PropTypes.string,
    billing_reference: PropTypes.string,
    campaign_code: PropTypes.string,
  }),
};

OrganizationAdministrationManage.defaultProps = {
  loading: false,
  data: null,
};

export default OrganizationAdministrationManage;
