import React from 'react';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import ArticleTile from 'components/ArticleTile';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  scrollableGrid: {
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: ({ length }) => `repeat(${length}, 1fr)`,
      gridGap: theme.spacing(1.5),
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
      maxWidth: '100vw',
      overflowX: 'scroll',

      padding: theme.spacing(0, 3),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 2),
      },
    },
  },
}));

const ArticleTilesGrid = ({ data, placeholdersNumber, scrollable }) => {
  const styles = useStyles({ length: data?.length });

  if (!data)
    return (
      <div className={clsx(styles.grid, scrollable && styles.scrollableGrid)}>
        {Array.from(Array(placeholdersNumber).keys()).map(key => (
          <ArticleTile key={key} />
        ))}
      </div>
    );
  return (
    <div className={clsx(styles.grid, scrollable && styles.scrollableGrid)}>
      {data.map(content => (
        <ArticleTile key={content.id} data={content} />
      ))}
    </div>
  );
};

ArticleTilesGrid.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      image_url: PropTypes.string,
      heading: PropTypes.string.isRequired,
      estimated_time: PropTypes.number.isRequired,
      teaser: PropTypes.string,
    }),
  ),
  placeholdersNumber: PropTypes.number,
  scrollable: PropTypes.bool,
};

ArticleTilesGrid.defaultProps = {
  data: null,
  placeholdersNumber: 3,
  scrollable: false,
};

export default ArticleTilesGrid;
