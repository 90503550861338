import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { set, get, trimEnd } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import GuideStepCreateDialog from 'components/_dialogs/GuideStepCreateDialog';
import useBoolState from 'hooks/useBoolState';
import guide_messages from 'messages/guide_messages';
import GuideSchemaBuilder from 'pages/EditGuidePage/_components/GuideSchemaBuilder';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  line: {
    position: 'absolute',
    width: '1px',
    background: theme.palette.secondary[900],
  },
}));

const buildPath = levels => {
  const childrenPart = '.children';
  const result = [];
  const lastIndex = levels.length - 1;
  levels.forEach((level, index) => {
    const isLast = index === lastIndex;
    result.push(isLast ? `[${level}]` : `[${level}]${childrenPart}`);
  });
  const path = result.join('');
  const index = levels.pop();
  result.splice(-1, 1).join('');
  const siblingsPath = result.join('');
  const parentPath = trimEnd(siblingsPath, childrenPart);
  return { parentPath, siblingsPath, path, index };
};

const PLACEMENTS = {
  SIBLING: 'sibling',
  CHILDREN: 'children',
  SELF: 'self',
};

const getPositionsObject = selectorElement => {
  const positions = {};
  const elements = document.querySelectorAll(`span[data-${selectorElement}]`);
  const wrapper = document.querySelector(`#schemaWrapper`);
  const parent = wrapper.getBoundingClientRect();

  elements.forEach(element => {
    const hasChildren = element.dataset.has_children;
    const key = element.dataset[selectorElement];
    if (!positions.hasOwnProperty(key)) positions[key] = [];
    const child = element.getBoundingClientRect();
    positions[key].push({ x: child.x - parent.x, y: child.y - parent.y, hasChildren });
  });
  return positions;
};

const generateLines = () => {
  const ROW_HEIGHT = 48;
  const DOT_SIZE = 7;
  const dotsPositions = getPositionsObject('dot');
  const linesPositions = getPositionsObject('line');

  const result = [];
  Object.entries(dotsPositions).forEach(([index, dotsSet]) => {
    const lines = linesPositions[+index + 1];
    if (+index === 0 && dotsSet.length > 1) {
      const [firstDot] = dotsSet;
      const lastDot = dotsSet[dotsSet.length - 1];
      result.push({ begin: firstDot, length: lastDot.y - firstDot.y });
    }
    if (lines) {
      const parentDots = dotsSet.filter(({ hasChildren }) => hasChildren === 'true');
      parentDots.forEach(parentDot => {
        const firstSiblingDot = dotsSet.find(({ y }) => y > parentDot.y);
        let length;
        if (firstSiblingDot) length = firstSiblingDot.y - parentDot.y - ROW_HEIGHT - DOT_SIZE;
        else {
          const lastLine = lines[lines.length - 1];
          length = lastLine.y - parentDot.y;
        }
        result.push({ begin: parentDot, length });
      });
    }
  });
  return result;
};

const GuideSchema = ({ steps, updateSteps, reportChange }) => {
  const { t } = useTranslation();
  const { state: isDialogOpen, setTrue: openDialog, setFalse: closeDialog } = useBoolState(false);
  const [updateConfig, setUpdateConfig] = useState(null);
  const [lines, setLines] = useState([]);
  const { showConfirmationModal } = useConfirmationModalContext();

  const onOpenDialog = (levels, placement, isEdit) => {
    openDialog();
    const paths = buildPath(levels);
    const stepsCopy = [...steps];
    setUpdateConfig({ paths, placement, ...(isEdit && { initialData: get(stepsCopy, paths.path) }) });
  };

  const addSibling = levels => {
    onOpenDialog(levels, PLACEMENTS.SIBLING);
  };

  const addChildren = levels => {
    onOpenDialog(levels, PLACEMENTS.CHILDREN);
  };

  const edit = levels => {
    onOpenDialog(levels, PLACEMENTS.SELF, true);
  };

  const onCloseDialog = () => {
    setUpdateConfig(null);
    closeDialog();
  };

  const addStep = data => {
    const { paths, placement } = updateConfig;
    const newSteps = [...steps];
    const isRoot = !get(newSteps, paths.siblingsPath);

    const newStep = {
      ...data,
      children: [],
    };

    // eslint-disable-next-line default-case
    switch (placement) {
      case PLACEMENTS.SIBLING: {
        const indexPlacement = paths.index + 1;
        const parent = get(newSteps, paths.siblingsPath);
        if (isRoot) newSteps.splice(indexPlacement, 0, newStep);
        else parent.splice(indexPlacement, 0, newStep);
        break;
      }
      case PLACEMENTS.CHILDREN: {
        const { children } = get(newSteps, paths.path);
        children.push(newStep);
        break;
      }
      case PLACEMENTS.SELF: {
        const parent = get(newSteps, paths.siblingsPath);
        const { children } = get(newSteps, paths.path);
        if (isRoot) newSteps[paths.index] = { ...newStep, children };
        else parent[paths.index] = { ...newStep, children };
        break;
      }
    }
    updateSteps(newSteps);
    onCloseDialog();
    reportChange();
  };

  const onRemove = async (event, levels) => {
    const immediate = event.ctrlKey || event.metaKey;
    if (!immediate) {
      const confirmation = await showConfirmationModal({
        title: t(...guide_messages.remove_step_confirmation),
        body: t(...guide_messages.remove_step_confirmation_question),
      });
      if (!confirmation) return;
    }
    const builtPath = levels ? buildPath(levels) : updateConfig.paths;
    const { siblingsPath, index, path } = builtPath;
    const newSteps = [...steps];
    const { children } = get(newSteps, path);
    const hasChildren = !!children.length;
    let newChildren = get(newSteps, siblingsPath);

    const isRoot = !newChildren;

    if (hasChildren && !immediate) {
      const confirmationChildren = await showConfirmationModal({
        title: t(...guide_messages.nested_elements),
        body: t(...guide_messages.nested_elements_question),
      });
      if (!confirmationChildren) return;
    }

    if (isRoot) newChildren = newSteps.splice(index, 1);
    else newChildren.splice(index, 1);

    set(newSteps, siblingsPath, newChildren);
    updateSteps(newSteps);
    reportChange();
  };

  useEffect(() => {
    setLines(generateLines());
  }, [steps]);

  const styles = useStyles();
  return (
    <div className={styles.wrapper} id='schemaWrapper'>
      {lines &&
        lines.map(({ begin, length }) => (
          <div key={`${begin.x}-${begin.y}`} className={styles.line} style={{ left: begin.x + 3, top: begin.y, height: length }} />
        ))}
      <GuideSchemaBuilder steps={steps} updaters={{ addChildren, addSibling, edit, onRemove }} />
      {isDialogOpen && (
        <GuideStepCreateDialog
          addStep={addStep}
          initialData={updateConfig?.initialData}
          onClose={onCloseDialog}
          onRemove={onRemove}
          open={isDialogOpen}
        />
      )}
    </div>
  );
};

GuideSchema.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateSteps: PropTypes.func.isRequired,
  reportChange: PropTypes.func.isRequired,
};

export default GuideSchema;
