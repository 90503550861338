import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import CenteredGrid from 'components/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import InformationSummary from 'components/InformationSummary';
import Loader from 'components/Loader';
import TakenStepsSummary from 'components/TakenStepsSummary';
import guidesEndpoints from 'config/api/guides';
import { guideTypesResolver } from 'config/translatableConstants/TRANSLATABLE_GUIDE_TYPES';
import parseDate from 'helpers/parseDate';
import useApiCall from 'hooks/useApiCall';
import useLoadingState from 'hooks/useLoadingState';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';
import PATHS from 'router/PATHS';

import DialogGrid from '../_components/DialogGrid';

const GuideSummaryDialog = ({ open, onClose, progress_id, version_id, guide_id }) => {
  const { t } = useTranslation();
  const { loading, setLoaded } = useLoadingState(false);
  const { apiCall } = useApiCall();
  const location = useLocation();
  const [stepsData, setStepsData] = useState(null);
  const [guideData, setGuideData] = useState(null);
  const [progressData, setProgressData] = useState(null);

  const getData = async () => {
    const [{ data: guideDataFromApi }, { data: stepsDataFromApi }, { data: progressDataFromApi }] = await Promise.all([
      apiCall(guidesEndpoints.get(version_id)),
      apiCall(guidesEndpoints.getProgressSteps(progress_id)),
      apiCall(guidesEndpoints.getProgress(progress_id)),
    ]);
    setStepsData(stepsDataFromApi);
    setGuideData(guideDataFromApi);
    setProgressData(progressDataFromApi);
    setLoaded();
  };

  useEffect(() => {
    getData();
  }, []);

  const informationSummaryData = useMemo(() => {
    if (!guideData || !progressData) return null;
    return [
      { label: guide_messages.report_information_labels.name, value: guideData.heading || t(...general_messages.guide) },
      { label: guide_messages.report_information_labels.status, value: 'In progress' },
      { label: guide_messages.report_information_labels.start, value: parseDate(progressData.created_at) },
      { label: guide_messages.report_information_labels.type, value: t(...guideTypesResolver(guideData.is_incident)) },
      { label: guide_messages.report_information_labels.last_edit, value: parseDate(progressData.updated_at) },
    ];
  }, [guideData, progressData]);

  return (
    <DialogGrid
      dialogActions={
        <CenteredGrid gridGap={2} withoutPadding>
          {progressData?.is_completed ? (
            <ColoredButton customColor='secondary' onClick={onClose} variant='outlined'>
              {t(...general_messages.close)}
            </ColoredButton>
          ) : (
            <ColoredButton
              component={Link}
              customColor='secondary'
              onClick={onClose}
              to={`${PATHS.GUIDES}/${guide_id}/${version_id}/${progress_id}/?redirect_url=${location.pathname}`}
              variant='outlined'
            >
              {t(...guide_messages.continue_guide)}
            </ColoredButton>
          )}
        </CenteredGrid>
      }
      loading={loading}
      onClose={onClose}
      open={open}
      title={guideData?.heading || t(...general_messages.guide)}
    >
      {!loading && stepsData ? (
        <>
          <CenteredGrid title={t(...general_messages.information)}>
            <InformationSummary data={informationSummaryData} />
          </CenteredGrid>
          <CenteredGrid>
            <TakenStepsSummary steps={stepsData} />
          </CenteredGrid>
        </>
      ) : (
        <Loader inner />
      )}
    </DialogGrid>
  );
};

GuideSummaryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  progress_id: PropTypes.string.isRequired,
  version_id: PropTypes.string.isRequired,
  guide_id: PropTypes.string.isRequired,
  contextApi: PropTypes.shape({
    openCommonDialog: PropTypes.func.isRequired,
  }).isRequired,
};

export default GuideSummaryDialog;
