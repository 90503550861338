import React, { useEffect, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AddIncidentLogDialog from 'components/_dialogs/AddIncidentLogDialog';
import ColoredButton from 'components/ColoredButton';
import Table from 'components/Table';
import incidentReportsEndpoints from 'config/api/incident_reports';
import { incidentPhaseResolver } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PHASES';
import parseDate from 'helpers/parseDate';
import parseTime from 'helpers/parseTime';
import useApiCall from 'hooks/useApiCall';
import general_messages from 'messages/general_messages';
import report_messages from 'messages/report_messages';

import IncidentLogDetailsDialog from '../_dialogs/IncidentLogDetailsDialog';

import useStyles from './IncidentLogs.styles';

const IncidentLogs = ({ readOnly, reportId }) => {
  const { t } = useTranslation();

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const openAddDialog = () => setIsAddDialogOpen(true);
  const closeAddDialog = () => setIsAddDialogOpen(false);

  const [detailsDialogData, setDetailsDialogData] = useState(null);
  const openDetailsDialog = setDetailsDialogData;
  const closeDetailsDialog = () => setDetailsDialogData(null);

  const { apiCall } = useApiCall();
  const [logs, setLogs] = useState([]);

  const getData = async () => {
    const { data } = await apiCall(incidentReportsEndpoints.getReportLogsAsIncidentManager(reportId));
    setLogs(data);
  };

  useEffect(() => {
    if (reportId) getData();
  }, [reportId]);

  const styles = useStyles();

  const dataMapping = [
    { id: 'contacted', width: '30%', label: t(...report_messages.log_table_headers.contacted), get: data => data?.contacted },
    {
      id: 'date_time',
      label: t(...report_messages.log_table_headers.date),
      width: '25%',

      get: data => (
        <div>
          {/* eslint-disable react/destructuring-assignment */}
          <p>{parseDate(data?.date_time)}</p>
          <p>{parseTime(data?.date_time)}</p>
          {/* eslint-enable react/destructuring-assignment */}
        </div>
      ),
    },
    {
      id: 'phase',
      width: '35%',
      label: t(...report_messages.log_table_headers.phase),
      get: data => (data?.phase ? t(...incidentPhaseResolver(data.phase)) : null),
    },
    {
      id: 'info',
      width: '10%',
      label: '',
      isAddon: true,
      get: data => (
        <IconButton onClick={() => openDetailsDialog(data)}>
          <InfoOutlinedIcon className={styles.icon} />
        </IconButton>
      ),
    },
  ];
  return (
    <div className={styles.wrapper}>
      <Table data={logs} dataMapping={dataMapping} fixedHeader />
      {!readOnly && (
        <>
          <ColoredButton customColor='secondary' endIcon={<Add />} onClick={openAddDialog}>
            {t(...general_messages.add)}
          </ColoredButton>
          <AddIncidentLogDialog onClose={closeAddDialog} open={isAddDialogOpen} refreshData={getData} reportId={reportId} />
        </>
      )}
      <IncidentLogDetailsDialog logData={detailsDialogData} onClose={closeDetailsDialog} open={!!detailsDialogData} />
    </div>
  );
};

IncidentLogs.propTypes = {
  readOnly: PropTypes.bool,
  reportId: PropTypes.string.isRequired,
};

IncidentLogs.defaultProps = {
  readOnly: false,
};

export default IncidentLogs;
