import { alpha } from '@material-ui/core/styles/colorManipulator';

import theme from './theme';

export default {
  '@global': {
    // GLOBAL
    html: {
      scrollBehavior: 'smooth',
      fontSize: '16px',
      [theme.breakpoints.down('md')]: { fontSize: '15px' },
      [theme.breakpoints.down('xs')]: { fontSize: '14px' },
    },
    body: {
      transition: 'background .3s',
    },
    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active': {
      '-webkit-box-shadow': '0 0 0 30px white inset !important',
    },
    ul: {
      padding: 0,
      listStylePosition: 'inside',
    },
    img: {
      verticalAlign: 'bottom',
    },

    // TYPOGRAPHY
    '.MuiTypography-h1': {
      [theme.breakpoints.down('md')]: { fontSize: '2.6rem' },
      [theme.breakpoints.down('sm')]: { fontSize: '2.3rem' },
      [theme.breakpoints.down('xs')]: { fontSize: '2rem' },
    },

    // SCROLL BARS
    '*': {
      scrollbarColor: `${theme.palette.primary.main} transparent`,
      scrollbarWidth: 'thin',
    },
    '*::-webkit-scrollbar': {
      width: '0.4rem',
      height: '0.4rem',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.primary.main,
    },

    '.MuiDrawer-root': {
      '& *': {
        scrollbarColor: `${alpha(theme.palette.getContrastText(theme.palette.background.dark), 0.95)} transparent`,
        scrollbarWidth: 'thin',
      },
      '& *::-webkit-scrollbar': {
        width: '0.4rem',
      },
      '& *::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '& *::-webkit-scrollbar-thumb': {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.getContrastText(theme.palette.background.dark), 0.95),
      },
    },

    // BUTTONS
    '.MuiButton-root': {
      padding: '12px',
    },
    '.MuiButton-root.MuiButton-sizeSmall': {
      padding: '8px',
    },
    '.MuiButton-outlined, .MuiButton-outlined:hover, .MuiButton-outlined.Mui-disabled': {
      borderWidth: '2px',
      padding: '10px',
    },

    '.MuiButton-outlined.MuiButton-outlinedSecondary:not(.Mui-disabled)': {
      borderColor: theme.palette.secondary[900],
      color: theme.palette.text.primary,
    },

    // INPUTS
    '.MuiOutlinedInput-root': {
      background: theme.palette.common.white,
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      },
      '&.MuiOutlinedInput-colorSecondary fieldset': {
        borderColor: theme.palette.secondary.main,
        borderWidth: '2px',
      },
      '&:hover:not(.Mui-disabled) fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.dark,
      },
      '&:hover:not(.Mui-disabled).MuiOutlinedInput-colorSecondary fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.dark,
      },
    },

    '.MuiRadio-colorSecondary, .MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette.secondary[900],
    },

    '.MuiCheckbox-colorSecondary.Mui-checked, .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.secondary[900],
    },

    '.MuiSelect-select:focus': {
      background: theme.palette.common.white,
    },

    // DIALOG
    '.MuiDialog-paperWidthSm': {
      maxWidth: '630px',
      width: '100%',
    },

    // TAGS
    '.MuiChip-root': {
      background: theme.palette.secondary[50],
      color: theme.palette.text.secondaryColor,
      margin: theme.spacing(0.5, 1, 0.5, 0),
      cursor: 'pointer',
      '&:focus, &:hover': {
        background: theme.palette.secondary[100],
      },
    },
    '.MuiChip-icon': {
      color: theme.palette.text.secondaryColor,
    },

    // PICKERS
    '.MuiPickersModal-dialogRoot': {
      // width: 'unset',
      '& .MuiButton-textPrimary': {
        color: '#085B3C',
      },
    },

    '.MuiPickersToolbarText-toolbarTxt': {
      color: '#292929',
    },
  },
};
