import React, { useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import ColoredButton from 'components/ColoredButton';

const useStyles = makeStyles(theme => ({
  accordion: {
    '&.MuiPaper-root:before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      padding: 0,
      '& p': {
        fontSize: '1.2rem !important',
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: theme.spacing(1, 0, 2),
    },
    '& .MuiIconButton-root': {
      padding: '6px',
    },
    '& .MuiAccordionSummary-content': {
      margin: '10px 0 0',
    },
  },
}));

const CustomAccordion = ({ id, heading, children, accordionClassName, contentClassName }) => {
  const [expanded, setExpanded] = useState();
  const onExpand = (e, isExpanded) => setExpanded(isExpanded);

  const styles = useStyles();
  return (
    <Accordion className={clsx(styles.accordion, accordionClassName)} elevation={0} onChange={onExpand}>
      <AccordionSummary
        expandIcon={
          <ColoredButton customColor={expanded ? 'secondary' : 'none'} icon>
            <ExpandMoreIcon fontSize='small' />
          </ColoredButton>
        }
        id={`${id}`}
      >
        {heading}
      </AccordionSummary>
      <AccordionDetails className={contentClassName}>{children}</AccordionDetails>
    </Accordion>
  );
};

CustomAccordion.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  accordionClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  heading: PropTypes.node.isRequired,
};

CustomAccordion.defaultProps = {
  accordionClassName: null,
  contentClassName: null,
};

export default CustomAccordion;
