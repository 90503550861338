import React from 'react';

import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CenteredGrid from 'components/CenteredGrid';
import { incidentPhaseResolver } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PHASES';
import parseDateTime from 'helpers/parseDateTime';
import report_messages from 'messages/report_messages';

import DialogGrid from '../_components/DialogGrid';

const IncidentLogDetailsDialog = ({ onClose, open, logData }) => {
  const { t } = useTranslation();

  return (
    <DialogGrid onClose={onClose} open={open} title={t(...report_messages.log_details_dialog_title)}>
      <CenteredGrid tight title={t(...report_messages.log_table_headers.date)}>
        <Typography>{parseDateTime(logData?.date_time)}</Typography>
      </CenteredGrid>
      <CenteredGrid tight title={t(...report_messages.log_table_headers.contacted)}>
        <Typography>{logData?.contacted}</Typography>
      </CenteredGrid>
      <CenteredGrid tight title={t(...report_messages.log_table_headers.actions)}>
        <Typography>{logData?.actions}</Typography>
      </CenteredGrid>
      <CenteredGrid tight title={t(...report_messages.log_table_headers.phase)}>
        <Typography>{logData?.phase ? t(...incidentPhaseResolver(logData.phase)) : null}</Typography>
      </CenteredGrid>
      <CenteredGrid tight title={t(...report_messages.log_table_headers.notes)}>
        <Typography>{logData?.notes}</Typography>
      </CenteredGrid>
    </DialogGrid>
  );
};

IncidentLogDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  logData: PropTypes.shape({
    date_time: PropTypes.instanceOf(Date).isRequired,
    contacted: PropTypes.string.isRequired,
    actions: PropTypes.string.isRequired,
    notes: PropTypes.string.isRequired,
    phase: PropTypes.string.isRequired,
  }).isRequired,
};

export default IncidentLogDetailsDialog;
