import React, { useEffect, useMemo, useRef } from 'react';

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import authEndpoints from 'config/api/auth';
import hashLinkScroll from 'helpers/hashLinkScroll';
import useApiCall from 'hooks/useApiCall';
import UnauthorizedPage from 'pages/UnauthorizedPage';
import { useAuthContext } from 'reactContext/AuthContext';
import PATHS from 'router/PATHS';
import checkPermissions from 'services/checkPermissions';
import userDataStorage from 'storages/userDataStorage';

const PrivateRoute = ({ path, ...rest }) => {
  const { apiCall } = useApiCall();
  const { isAuthorized } = useAuthContext();
  const intervalRef = useRef();
  const userData = userDataStorage.get();

  const refreshSession = () => {
    // refresh token on focus
    if (document.hasFocus()) apiCall(authEndpoints.me());
  };

  useEffect(() => {
    hashLinkScroll();
  }, []);

  useEffect(() => {
    window.addEventListener('focus', refreshSession);
    intervalRef.current = setInterval(refreshSession, 2 * 60 * 1000); // 5 mins
    return () => {
      window.removeEventListener('focus', refreshSession);
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  const hasAccess = useMemo(() => checkPermissions(path, userData), [userData]);
  if (!hasAccess) return <UnauthorizedPage />;
  if (!isAuthorized) return <Redirect to={{ pathname: PATHS.AUTH, state: { redirectedFrom: window.location.pathname } }} />;
  if (!userData.hasPaymentMethod && path !== PATHS.PAYMENT) return <Redirect to={{ pathname: PATHS.PAYMENT }} />;
  return <Route {...rest} />;
};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
};

export default PrivateRoute;
