import React from 'react';

import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import GuideTile from 'components/GuideTile';
import Loader from 'components/Loader';
import Section from 'components/Section';
import general_messages from 'messages/general_messages';

import ArticleTile from '../ArticleTile';

import useStyles from './RelatedContentOneColumn.styles';

const RelatedContentOneColumn = ({ isLoading, guides, articles }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  if (isLoading) return <Loader />;
  return (
    <div className={styles.wrapper}>
      <Typography component='h2' variant='h2'>
        {t(...general_messages.related_content)}
      </Typography>
      {articles?.length ? (
        <Section paddingTop={3} smallPadding smallTitle title={t(...general_messages.content_types.article)}>
          <div className={styles.contentWrapper}>
            {articles.map(data => (
              <ArticleTile key={data.id} data={data} />
            ))}
          </div>
        </Section>
      ) : null}
      {guides?.length ? (
        <Section paddingTop={3} smallPadding smallTitle title={t(...general_messages.content_types.guide)}>
          <div className={styles.contentWrapper}>
            {guides.map(({ id, metadata, ...rest }) => (
              <GuideTile key={id} darker id={id} metadata={metadata} {...rest} />
            ))}
          </div>
        </Section>
      ) : null}
    </div>
  );
};

RelatedContentOneColumn.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  guides: PropTypes.arrayOf(PropTypes.shape({})),
  articles: PropTypes.arrayOf(PropTypes.shape({})),
};

RelatedContentOneColumn.defaultProps = {
  guides: [],
  articles: [],
};

export default RelatedContentOneColumn;
