import React, { useMemo } from 'react';

import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ColoredTile from 'components/ColoredTile/ColoredTile';
import ManagePageHeader from 'components/ManagePageHeader';
import Section from 'components/Section';
import Table from 'components/Table';
import locale from 'config/locale';
import organization_administration_messages from 'messages/organization_administration_messages';
import title_messages from 'messages/title_messages';

import useStyles from './OrganizationAdministrationInfo.styles';

const parseDate = ({ begin_date } = {}) => {
  const begin = new Date(begin_date);
  return format(begin, 'MM - yyyy');
};

const OrganizationAdministrationInfo = ({ data, loading }) => {
  const { t, i18n } = useTranslation();
  const firstMonth = useMemo(() => (data ? data[0] : null), [data]);

  const statusTitle = `${t(...organization_administration_messages.current_status)}: ${format(new Date(), 'LLLL', {
    locale: locale[i18n.language || 'en'],
  })}`;

  const dataMapping = [
    { id: 'date_range', label: t(...organization_administration_messages.month_year), get: parseDate },
    { id: 'active_accounts', label: t(...organization_administration_messages.active_accounts), get: entity => entity?.active_users },
    {
      id: 'advisory_meetings',
      label: t(...organization_administration_messages.advisory_meetings),
      get: entity => entity?.meetings,
    },
  ];

  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <ManagePageHeader noBorder>{t(...title_messages.organization_administration)}</ManagePageHeader>

        <Section paddingTop={2} smallPadding smallTitle title={statusTitle}>
          <div className={styles.tilesContainer}>
            <ColoredTile
              centered
              description={t(...organization_administration_messages.active_accounts)}
              disableRipple
              title={loading ? <Skeleton height='2.1rem' style={{ margin: '0 auto' }} width='1rem' /> : firstMonth?.active_users}
            />
            <ColoredTile
              centered
              description={t(...organization_administration_messages.advisory_meetings)}
              disableRipple
              title={loading ? <Skeleton height='2.1rem' style={{ margin: '0 auto' }} width='1rem' /> : firstMonth?.meetings}
            />
          </div>
          <Typography className={styles.summary}>
            {loading ? (
              <Skeleton height='3.75rem' />
            ) : (
              <>
                {t(
                  ...(firstMonth?.active_users === 1
                    ? organization_administration_messages.summary_active_user
                    : organization_administration_messages.summary_active_users),
                  { count: firstMonth?.active_users },
                )}{' '}
                {t(
                  ...(firstMonth?.meetings === 1
                    ? organization_administration_messages.summary_booked_meeting
                    : organization_administration_messages.summary_booked_meetings),
                  { count: firstMonth?.meetings },
                )}
              </>
            )}
          </Typography>
        </Section>
      </div>
      <div className={styles.body}>
        <Section paddingTop={0} showSeparator smallTitle title={t(...organization_administration_messages.history)}>
          <Table data={data} dataMapping={dataMapping} />
        </Section>
      </div>
    </div>
  );
};

OrganizationAdministrationInfo.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    active_accounts: PropTypes.number,
    advisory_meetings: PropTypes.number,
    active_users: PropTypes.number,
    booked_meetings: PropTypes.number,
    history: PropTypes.array,
  }),
};

OrganizationAdministrationInfo.defaultProps = {
  data: {},
};

export default OrganizationAdministrationInfo;
