import React, { useEffect, useState } from 'react';

import { Typography, MobileStepper, Button, RadioGroup, Radio, Container } from '@material-ui/core';
import { useFormik } from 'formik';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router';

import ArticleTile from 'components/ArticleTile';
import CheckboxTile from 'components/CheckboxTile';
import ColoredButton from 'components/ColoredButton';
import DialogViewWrapper from 'components/DialogViewWrapper';
import GuideTile from 'components/GuideTile';
import Loader from 'components/Loader';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { selfTestApi } from 'config/api/selfTests/selfTests';
import useBoolState from 'hooks/useBoolState';
import useGetSelfTestData from 'hooks/useGetSelfTestData/useGetSelfTestData';
import useGetSelfTestProgressInstance from 'hooks/useGetSelfTestProgressInstance/useGetSelfTestProgressInstance';
import self_test_messages from 'messages/self_test_messages';
import PATHS from 'router/PATHS';

import useStyles from './SingleSelfTestPage.styles';

const SELF_TEST_PROGRESS_STATUSES = {
  NEW: 'new',
  COMPLETED: 'completed',
};

const SingleSelfTestPage: React.FC = () => {
  const { selfTestRootId, selfTestVersionId, selfTestProgressId } = useParams() as unknown as {
    selfTestRootId: string;
    selfTestVersionId: string;
    selfTestProgressId: number;
  };
  const styles = useStyles();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const { state: testIsFinished, setTrue: setTestIsFinished } = useBoolState(false);
  const [newInstanceId, setNewInstanceId] = useState<number | null>(null);
  const [isNewInstance, setIsNewInstance] = useState<boolean | null>(null);
  const [resultIsLoaded, setResultIsLoaded] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setIsNewInstance(!selfTestProgressId);
  }, [selfTestProgressId]);

  const progressId = selfTestProgressId || newInstanceId;
  const progressData = useGetSelfTestProgressInstance(progressId, isNewInstance);
  const { data, isLoading, questions, maxSteps } = useGetSelfTestData(selfTestVersionId);

  const createSelfTestInstance = useMutation(QUERY_KEYS.CREATE_SELF_TEST_PROGRESS, selfTestApi.createSelfTestInstance, {
    onSuccess: ({ id }) => {
      setNewInstanceId(id);
    },
  });

  const handleNextStep = () => {
    if (maxSteps) {
      if (activeStep === maxSteps - 1) {
        setTestIsFinished();
      }
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const createQuestionInstance = useMutation(QUERY_KEYS.ANSWER_QUESTION, selfTestApi.createQuestionInstance, {
    onSuccess: () => {
      handleNextStep();
    },
  });

  const editQuestionInstance = useMutation(QUERY_KEYS.ANSWER_QUESTION, selfTestApi.editQuestionInstance, {
    onSuccess: () => {
      handleNextStep();
    },
  });

  // query for the end of the test
  const testResult = useQuery(
    [QUERY_KEYS.GET_SELF_TEST_RESULT, newInstanceId, progressData.currentTestVersionId],
    () => selfTestApi.getTestResult(newInstanceId, progressData.currentTestVersionId),
    {
      enabled: testIsFinished,
      onSuccess: () => {
        setResultIsLoaded(true);
      },
    },
  );
  const testProgress = useQuery(
    [QUERY_KEYS.GET_SELF_TEST_RESULT_PROGRESS_DATA, newInstanceId, progressData.currentTestVersionId],
    () => selfTestApi.getProgressDataAfterFinishingTest(newInstanceId, progressData.currentTestVersionId),
    {
      enabled: testIsFinished,
      onSuccess: ({ id }) => {
        history.push({
          pathname: `${PATHS.SELF_TESTS}/${selfTestRootId}/${selfTestVersionId}/${id}`,
        });
        setResultIsLoaded(true);
      },
    },
  );

  useEffect(() => {
    if (progressData.data?.progressStatus === SELF_TEST_PROGRESS_STATUSES.COMPLETED) {
      setTestIsFinished();
    }
  }, [progressData]);

  useEffect(() => {
    if (isNewInstance) {
      createSelfTestInstance.mutate({ selfTestId: selfTestVersionId });
    }
  }, [isNewInstance]);

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const onSubmit = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const checkedAnswerId = Number(formik.values.answerId);
    const dataToSend = {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      userSelfTestId: Number(formik.values.userSelfTestId),
      answerId: checkedAnswerId,
    };
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (formik.initialValues.answerId !== null && checkedAnswerId !== Number(formik.initialValues.answerId)) {
      const editedData = {
        ...dataToSend,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        id: formik.values.id,
      };
      editQuestionInstance.mutate(editedData);
    } else {
      createQuestionInstance.mutate(dataToSend);
    }
  };

  const answerId =
    questions && progressData?.data?.answeredQuestions?.find(answer => answer.questionId === questions[activeStep]?.id)?.answerId;
  const questionId =
    questions && progressData?.data?.answeredQuestions?.find(answer => answer.questionId === questions[activeStep]?.id)?.questionId;
  const instanceId =
    questions && progressData?.data?.answeredQuestions?.find(answer => answer.questionId === questions[activeStep]?.id)?.id;

  const formik = useFormik({
    initialValues: {
      userSelfTestId: newInstanceId || progressData.currentTestVersionId,
      answerId: answerId?.toString() || null,
      questionId: questionId || null,
      id: instanceId || null,
    },
    onSubmit,
    enableReinitialize: true,
  });

  const onChange = (event: { target: HTMLInputElement }) => {
    formik.setFieldValue('answerId', event.target.value);
  };

  useEffect(() => {
    if (questions?.length && !testIsFinished) {
      formik.setFieldValue('questionId', questions[activeStep].id);
    }
  }, [activeStep, questions, testIsFinished]);

  if (isLoading) return <Loader inner />;

  return (
    <>
      <DialogViewWrapper title={t(self_test_messages.self_test_title)}>
        {!testIsFinished && (
          <form id='answer' onSubmit={formik.handleSubmit}>
            <div className={styles.header}>
              <Typography variant='subtitle1'>
                <b>{data?.selfTestData?.heading}</b>
              </Typography>
              <Typography variant='subtitle1'>
                <b>{`${activeStep + 1} / ${maxSteps}`}</b>
              </Typography>
            </div>
            <Typography variant='h3'>{questions?.length && questions[activeStep].heading}</Typography>
            <Typography className={styles.description} variant='body1'>
              {questions?.length && questions[activeStep].description}
            </Typography>
            <RadioGroup name='answerId' onChange={onChange} value={formik.values.answerId}>
              {questions?.length &&
                questions[activeStep].answers.map(answer => (
                  // @ts-ignore
                  <CheckboxTile
                    key={answer.id}
                    className={styles.answer}
                    control={<Radio value={answer.id.toString()} />}
                    description={!isNil(answer.description) && answer.description}
                    formik={formik}
                    id='answerId'
                    label={answer.heading}
                    selected={formik.values.answerId?.toString() === answer.id.toString()}
                    value={answer.id}
                  />
                ))}
            </RadioGroup>
            <MobileStepper
              activeStep={activeStep}
              backButton={
                <Button className={styles.stepperButton} color='secondary' disabled={activeStep === 0} onClick={handleBack}>
                  {t(self_test_messages.self_test_actions.previous)}
                </Button>
              }
              classes={{ root: styles.stepperRoot, dots: styles.stepperDots }}
              nextButton={
                // @ts-ignore
                <ColoredButton
                  className={styles.stepperButton}
                  color='secondary'
                  customColor='secondary'
                  disabled={formik.values.answerId === null}
                  onClick={onSubmit}
                >
                  {maxSteps && activeStep === maxSteps - 1
                    ? t(self_test_messages.self_test_actions.finish_test)
                    : t(self_test_messages.self_test_actions.next)}
                </ColoredButton>
              }
              position='static'
              steps={maxSteps || 0}
            />
          </form>
        )}
        {resultIsLoaded && (
          <Container maxWidth='sm'>
            <div className={styles.header}>
              <Typography variant='subtitle1'>
                <b>{data?.selfTestData?.heading}</b>
              </Typography>
              <Typography variant='subtitle1'>
                <b>{t(self_test_messages.results)}</b>
              </Typography>
            </div>
            <Typography variant='h2'>
              {t(self_test_messages.score_message, {
                currentScore: testProgress.data?.currentScore,
                maxScore: testProgress.data?.selfTest.maxScore,
              })}
            </Typography>
            <Typography variant='h3'>{testResult.data?.heading}</Typography>
            <Typography variant='body1'>{testResult.data?.description}</Typography>
            <Typography className={styles.description} variant='h3'>
              {t(self_test_messages.recommendations)}
            </Typography>
            {testProgress.data?.recommendationTexts.map(text => (
              <div className={styles.accordionTile}>{text}</div>
            ))}
            <div className={styles.recommendedGuides}>
              {testProgress.data?.recommendedGuides.map(guide => (
                // @ts-ignore
                <GuideTile
                  key={guide.metadata?.versionId}
                  description={guide.description || ''}
                  heading={guide.heading}
                  id={guide.id}
                  metadata={guide.metadata}
                  minimal
                />
              ))}
            </div>
            <div className={styles.recommendedGrid}>
              {testProgress.data?.recommendedArticles.map(article => (
                <ArticleTile key={article.id} data={article} smallTile />
              ))}
            </div>
          </Container>
        )}
      </DialogViewWrapper>
    </>
  );
};

export default SingleSelfTestPage;
