import React, { useEffect, useRef, useState } from 'react';

import {
  CircularProgress,
  ClickAwayListener,
  makeStyles,
  MenuItem,
  Paper,
  TextField,
  MenuList,
  IconButton,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ensureIsArray from 'helpers/ensureIsArray';
import useApiCall from 'hooks/useApiCall';
import useBoolState from 'hooks/useBoolState';
import useDebounce from 'hooks/useDebounce';
import useLoadingState from 'hooks/useLoadingState';
import general_messages from 'messages/general_messages';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  popperContent: {
    width: '100%',
    display: 'grid',
  },
  popperElement: {
    background: 'none',
    border: 'none',
    display: 'grid',
    gridAutoColumns: '100px auto',
  },
  selectedValues: {
    paddingTop: theme.spacing(2),
  },
  selectedElement: {
    borderBottom: `thin solid ${theme.palette.secondary[500]}`,
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noData: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
}));

const FormAutocomplete = ({ label, formik, id, apiCallParamsGetter, optionsParser, color, customizeLabel, labelClassName, disabled }) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const { loading, setLoaded, setLoading } = useLoadingState(true);

  const { apiCall, cancelRequest } = useApiCall();
  const debouncedSearchValue = useDebounce(inputValue, 300);
  const { touched, errors, setFieldValue, values } = formik;

  const updateOptions = optionsToSet => {
    if (optionsToSet) {
      const parsedOptions = optionsParser(optionsToSet);
      const filteredOptions = parsedOptions.filter(({ key }) => {
        const selectedValues = get(values, id) || [];
        return !selectedValues.some(selectedValue => selectedValue.key === key);
      });
      setOptions(filteredOptions);
    }
  };

  const performSearch = async value => {
    cancelRequest();
    if (apiCallParamsGetter) {
      const { data } = await apiCall(...ensureIsArray(apiCallParamsGetter(value)));
      updateOptions(data);
      setLoaded();
    }
  };

  useEffect(() => {
    if (debouncedSearchValue) {
      setLoading();
      performSearch(debouncedSearchValue);
    }
  }, [debouncedSearchValue]);

  const { state: open, setTrue: openPopper, setFalse: closePopper } = useBoolState(false);
  const ref = useRef(null);

  const onInputChange = ({ target }) => {
    setInputValue(target.value);
    openPopper();
  };

  const onInputFocus = ({ target }) => {
    if (target.value) openPopper();
  };

  const onSelect = (event, value) => {
    const previousValue = get(values, id) || [];
    setFieldValue(id, [...previousValue, value]);
    closePopper();
    setOptions([]);
    setInputValue('');
  };

  const onRemove = key => {
    const previousValue = get(values, id) || [];
    setFieldValue(
      id,
      previousValue.filter(value => value.key !== key),
    );
  };

  const styles = useStyles();
  return (
    <div>
      <ClickAwayListener onClickAway={closePopper}>
        <div className={styles.wrapper}>
          <TextField
            color={color}
            disabled={disabled}
            error={touched[id] && Boolean(errors[id])}
            fullWidth
            innerRef={ref}
            InputProps={{
              endAdornment:
                loading && open ? (
                  <InputAdornment position='end'>
                    <CircularProgress color='secondary' size={20} />
                  </InputAdornment>
                ) : null,
            }}
            label={label}
            onChange={onInputChange}
            onFocus={onInputFocus}
            value={inputValue}
            variant='outlined'
          />
          <Popper anchorEl={ref.current} className={styles.popper} open={open && !loading}>
            {options.length ? (
              <Paper className={styles.popperContent} elevation={3}>
                <MenuList style={{ width: ref?.current?.offsetWidth }}>
                  {options.map(option => (
                    <MenuItem key={option.key} className={clsx(styles.popperElement, labelClassName)} onClick={e => onSelect(e, option)}>
                      {customizeLabel(option)}
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            ) : (
              <Paper className={styles.popperContent} elevation={3}>
                <Typography className={styles.noData} style={{ width: ref?.current?.offsetWidth }}>
                  {t(...general_messages.no_data)}
                </Typography>
              </Paper>
            )}
          </Popper>
        </div>
      </ClickAwayListener>

      <div className={styles.selectedValues}>
        {get(values, id) &&
          get(values, id).map(option => (
            <div key={option.key} className={styles.selectedElement}>
              <div className={labelClassName}>{customizeLabel(option)}</div>
              {!disabled && (
                <IconButton onClick={() => onRemove(option.key)} size='small'>
                  <CloseIcon />
                </IconButton>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

FormAutocomplete.propTypes = {
  formik: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    setFieldValue: PropTypes.func,
    values: PropTypes.object.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  apiCallParamsGetter: PropTypes.func.isRequired,
  optionsParser: PropTypes.func,
  customizeLabel: PropTypes.func,
  color: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

FormAutocomplete.defaultProps = {
  customizeLabel: option => option.label,
  optionsParser: data => data,
  color: 'primary',
  labelClassName: null,
  disabled: false,
};

export default FormAutocomplete;
