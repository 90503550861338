import React, { useEffect, useMemo, useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormAutocomplete from 'components/FormAutocomplete';
import FormFileInput from 'components/FormFileInput';
import FormSelect from 'components/FormSelect';
import FormSwitch from 'components/FormSwitch';
import FormTagsPicker from 'components/FormTagsPicker';
import FormTextInput from 'components/FormTextInput';
import guidesEndpoints from 'config/api/guides';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import PUBLISH_STATUSES from 'config/constants/PUBLISH_STATUSES';
import CONTENT_TYPES_SELECT from 'config/dictionaries/CONTENT_TYPES_SELECT';
import { AUDIENCES_DICTIONARY } from 'config/translatableConstants/AUDIENCES';
import { CUSTOMER_LEVELS_DICTIONARY } from 'config/translatableConstants/CUSTOMER_LEVELS';
import { DEEP_LEVELS_DICTIONARY } from 'config/translatableConstants/DEEP_LEVELS';
import { LANGUAGES_DICTIONARY } from 'config/translatableConstants/LANGUAGES';
import getFilenameFormPath from 'helpers/getFilenameFormPath';
import useApiCall from 'hooks/useApiCall';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import useMultipleApiCall from 'hooks/useMultipleApiCall';
import general_messages from 'messages/general_messages';
import metadata_messages from 'messages/metadata_messages';
import validation_messages from 'messages/validation_messages';
import PATHS from 'router/PATHS';
import serviceAreaDictionary from 'services/_dictionaries/parsers/serviceAreaDictionary';
import tagDictionary from 'services/_dictionaries/parsers/tagDictionary';
import relatedContentOptionsParser from 'services/autocompleteServices/relatedContentOptionsParser';
import relatedContentParamsGetter from 'services/autocompleteServices/relatedContentParamsGetter';
import relatedContentResultComponent from 'services/autocompleteServices/relatedContentResultComponent';
import getRelatedContentInitialValues from 'services/getRelatedContentInitialValues';

import parseMetadataForRequest from './_services/parseMetadataForRequest';

const useStyles = makeStyles(theme => ({
  autocompleteLabel: {
    display: 'grid',
    gridTemplateColumns: '80px 60px 1fr',
    gridGap: theme.spacing(1),
  },
  tags: {
    paddingBottom: theme.spacing(3),
  },
  switch: {
    paddingBottom: theme.spacing(3),
  },
}));

const FORM = {
  IS_ACTIVE: 'publish_status',
  CONTENT_TYPE: 'content_type',
  HEADING: 'heading',
  LANGUAGE: 'language',
  SERVICE_AREA: 'service_area',
  CUSTOMER_LEVEL: 'customer_level',
  DEPTH_LEVEL: 'depth_level',
  AUDIENCE: 'audience',
  RELATED_CONTENT: 'related_content',
  IMAGE: 'image',
  ESTIMATED_TIME: 'estimated_time',
  TAGS: 'tags',
};
const FORM_ID = 'metadata-form';

const getDictionariesUrls = () => ({
  serviceAreaDictionary,
  tags: tagDictionary,
});

const staticDictionaries = {
  audience: AUDIENCES_DICTIONARY,
  customer_level: CUSTOMER_LEVELS_DICTIONARY,
  depth_level: DEEP_LEVELS_DICTIONARY,
  language: LANGUAGES_DICTIONARY,
};

const MetadataDialog = ({ open, onClose, type, initData, id, refresh }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isEditing = useMemo(() => !!initData, [initData]);
  const { multipleApiCall } = useMultipleApiCall();
  const { apiCall } = useApiCall();
  const [dictionaries, setDictionaries] = useState([]);
  const [sending, setSending] = useState(false);

  const getDictionaries = async () => {
    const urls = getDictionariesUrls();
    const response = await multipleApiCall(urls);
    setDictionaries({ ...response, ...staticDictionaries });
  };

  useEffect(() => {
    getDictionaries();
  }, []);

  const uploadImage = (version_id, file) => {
    const data = new FormData();
    data.append('file', file);
    return apiCall(guidesEndpoints.uploadImage(version_id), { data });
  };

  const onSubmit = async data => {
    setSending(true);
    if (isEditing) {
      const { content_type, image } = data;
      if (content_type === CONTENT_TYPES.GUIDE) {
        await apiCall(guidesEndpoints.update(id), { data: parseMetadataForRequest(data, FORM) });
        if (image?.size) await uploadImage(id, image);
        setSending(false);
        refresh();
        onClose();
      }
    } else {
      const { content_type, image } = data;
      if (content_type === CONTENT_TYPES.GUIDE) {
        const {
          data: { version_id },
        } = await apiCall(guidesEndpoints.create(), { data: parseMetadataForRequest(data, FORM) });
        if (image?.size) await uploadImage(version_id, image);
        setSending(false);
        history.push(`${PATHS.EDIT_GUIDE}/${version_id}`);
      }
      if (content_type === CONTENT_TYPES.ARTICLE) history.push(PATHS.EDIT_ARTICLE);
    }
  };

  const formik = useFormik({
    initialValues: {
      [FORM.IS_ACTIVE]: false,
      [FORM.CONTENT_TYPE]: '',
      [FORM.HEADING]: '',
      [FORM.LANGUAGE]: '',
      [FORM.SERVICE_AREA]: '',
      [FORM.CUSTOMER_LEVEL]: '',
      [FORM.DEPTH_LEVEL]: '',
      [FORM.AUDIENCE]: '',
      [FORM.RELATED_CONTENT]: [],
      [FORM.IMAGE]: '',
      [FORM.ESTIMATED_TIME]: '',
    },
    validationSchema: yup.object({
      [FORM.CONTENT_TYPE]: yup.string().required(t(...validation_messages.required)),
      [FORM.HEADING]: yup.string().required(t(...validation_messages.required)),
      [FORM.LANGUAGE]: yup.string().required(t(...validation_messages.required)),
      [FORM.SERVICE_AREA]: yup.string().required(t(...validation_messages.required)),
      [FORM.CUSTOMER_LEVEL]: yup.string().required(t(...validation_messages.required)),
      [FORM.AUDIENCE]: yup.string().required(t(...validation_messages.required)),
      [FORM.DEPTH_LEVEL]: yup.string().required(t(...validation_messages.required)),
      [FORM.ESTIMATED_TIME]: yup.number().required(t(...validation_messages.required)),
    }),
    onSubmit,
  });

  const resolveTitle = () => {
    if (!isEditing) return t(...general_messages.new);
    if (type === CONTENT_TYPES.GUIDE) return t(...general_messages.edit_guide);
    if (type === CONTENT_TYPES.ARTICLE) return t(...general_messages.edit_article);
    return t(...general_messages.edit);
  };

  useDidUpdateEffect(() => {
    if (type) formik.setFieldValue([FORM.CONTENT_TYPE], type);
  }, [type]);

  useEffect(() => {
    if (initData && dictionaries) {
      formik.setValues({
        ...formik.values,
        [FORM.IS_ACTIVE]: initData[FORM.IS_ACTIVE] === PUBLISH_STATUSES.PUBLISHED,
        [FORM.CONTENT_TYPE]: type,
        [FORM.HEADING]: initData[FORM.HEADING],
        [FORM.AUDIENCE]: initData[FORM.AUDIENCE],
        [FORM.DEPTH_LEVEL]: initData[FORM.DEPTH_LEVEL],
        [FORM.ESTIMATED_TIME]: initData[FORM.ESTIMATED_TIME],
        [FORM.CUSTOMER_LEVEL]: initData[FORM.CUSTOMER_LEVEL],
        [FORM.IMAGE]: { name: getFilenameFormPath(initData.image_url) },
        [FORM.RELATED_CONTENT]: getRelatedContentInitialValues(initData),
        [FORM.LANGUAGE]: initData[FORM.LANGUAGE].id,
        [FORM.SERVICE_AREA]: initData[FORM.SERVICE_AREA].id,
        [FORM.TAGS]: initData[FORM.TAGS].map(tag => ({ label: tag, key: tag })),
      });
    }
  }, [initData, dictionaries]);

  const styles = useStyles();
  return (
    <DialogGrid
      dialogActions={
        <CenteredGrid withoutPadding>
          <ColoredButton
            customColor='secondary'
            disabled={!formik.isValid || !formik.dirty}
            form={FORM_ID}
            showLoader={sending}
            type='submit'
            variant='outlined'
          >
            {t(...general_messages.save_continue)}
          </ColoredButton>
        </CenteredGrid>
      }
      fullWidth={false}
      onClose={onClose}
      open={open}
      title={resolveTitle()}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        <CenteredGrid gridGap={1.5}>
          <FormSwitch className={styles.switch} formik={formik} id={FORM.IS_ACTIVE} label={t(...general_messages.active_content)} />
          {!isEditing && (
            <FormSelect
              formik={formik}
              id={FORM.CONTENT_TYPE}
              label={t(...general_messages.content_type)}
              options={CONTENT_TYPES_SELECT}
              required
            />
          )}
          <FormTextInput formik={formik} id={FORM.HEADING} label={t(...metadata_messages.heading1)} multiline required />
          <FormFileInput acceptedTypes={['image']} buttonType formik={formik} id={FORM.IMAGE} label={t(...general_messages.upload_image)} />
        </CenteredGrid>
        <CenteredGrid gridGap={1.5} title={t(...general_messages.metadata)}>
          <FormSelect
            formik={formik}
            id={FORM.LANGUAGE}
            label={t(...metadata_messages.language)}
            options={dictionaries.language}
            required
          />
          <FormSelect
            formik={formik}
            id={FORM.SERVICE_AREA}
            label={t(...metadata_messages.service_area)}
            options={dictionaries.service_area}
            required
          />
          <FormSelect
            formik={formik}
            id={FORM.CUSTOMER_LEVEL}
            label={t(...metadata_messages.customer_level)}
            options={dictionaries.customer_level}
            required
          />
          <FormSelect
            formik={formik}
            id={FORM.AUDIENCE}
            label={t(...metadata_messages.audience)}
            options={dictionaries.audience}
            required
          />
          <FormSelect
            formik={formik}
            id={FORM.DEPTH_LEVEL}
            label={t(...metadata_messages.depth_level)}
            options={dictionaries.depth_level}
            required
          />
          <FormTextInput formik={formik} id={FORM.ESTIMATED_TIME} label={t(...metadata_messages.estimated_time)} required type='number' />
          <Typography className={styles.tags} component='h3' variant='h5'>
            {t(...general_messages.tags)}
          </Typography>
          <FormTagsPicker formik={formik} id={FORM.TAGS} label={t(...general_messages.tags)} options={dictionaries.tags} />
        </CenteredGrid>
        <CenteredGrid title={t(...general_messages.related_content)} withoutPadding>
          <FormAutocomplete
            apiCallParamsGetter={relatedContentParamsGetter}
            customizeLabel={relatedContentResultComponent}
            formik={formik}
            id={FORM.RELATED_CONTENT}
            label={t(...general_messages.search_add)}
            labelClassName={styles.autocompleteLabel}
            optionsParser={relatedContentOptionsParser}
          />
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

MetadataDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(CONTENT_TYPES)),
  initData: PropTypes.object,
  id: PropTypes.string,
  refresh: PropTypes.func,
};

MetadataDialog.defaultProps = {
  type: '',
  initData: null,
  id: null,
  refresh: () => {},
};

export default MetadataDialog;
