import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  header: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(3, 0),
    marginBottom: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    gridGap: theme.spacing(2),
  },
  stepperButton: {
    minWidth: '100px',
  },
  stepperRoot: {
    background: 'none',
  },
  stepperDots: {
    display: 'none',
  },
  description: {
    margin: theme.spacing(2, 0),
  },
  answer: {
    margin: theme.spacing(1, 0),
  },
  accordionTile: {
    marginBottom: theme.spacing(2),
    // @ts-ignore
    border: `thin solid ${theme.palette.secondary[100]}`,
    // @ts-ignore
    borderRadius: theme.shape.borderRadiusLarge,
    padding: theme.spacing(0.75, 2),
  },
  recommendedGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '15px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  recommendedGuides: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '15px',
  },
}));
