import React, { useEffect } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { TemplateSectionWithValue } from 'config/api/workplaces/_types';
import type { Workplace } from 'config/api/workplaces/_types';
import { workplacesApi } from 'config/api/workplaces/workplaces';
import ROLES from 'config/constants/ROLES';
import useBoolState from 'hooks/useBoolState';
import usePermissions from 'hooks/usePermissions';
import useResponsiblePermission from 'hooks/useResponsiblePremission';
import workplace_messages from 'messages/workplace_messages';
import { useAuthContext } from 'reactContext/AuthContext';

import PATHS from '../../router/PATHS';
import EditWorkplaceDialog from '../_dialogs/EditWorkplaceDialog/EditWorkplaceDialog';
import UserAvatar from '../UserAvatar/UserAvatar';

import useStyles from './WorkplaceDetailsCard.styles';

type Props = {
  workplaceBaseData: Workplace;
  templateValues: TemplateSectionWithValue[];
  refreshData: () => Promise<any>;
};

const WorkplaceDetailsCard: React.FC<Props> = ({ workplaceBaseData, templateValues, refreshData }) => {
  const { t } = useTranslation();
  const history = useHistory();
  // @ts-ignore
  const { updateUserData } = useAuthContext();
  const isUserResponsible = useResponsiblePermission(workplaceBaseData.responsibles);
  const [isAccountOwner] = usePermissions([ROLES.ORGANIZATION.ACCOUNT_OWNER]);

  const isAllowedToEdit = isUserResponsible || isAccountOwner;
  const emptySectionMessage = t(workplace_messages.singleWorkplace.emptySection);

  const defaultWorkplaceMutation = useMutation(QUERY_KEYS.SET_DEFAULT_WORKPLACE, workplacesApi.setUserDefaultWorkspace);

  useEffect(() => {
    (async () => {
      await defaultWorkplaceMutation.mutateAsync(workplaceBaseData.id);
      await updateUserData();
    })();
  }, []);

  const { state: editDialog, setTrue: openEditDialog, setFalse: closeEditDialog } = useBoolState(false);

  const onClose = async () => {
    await defaultWorkplaceMutation.mutateAsync(null);
    await updateUserData();
    history.push(PATHS.WORKPLACES);
  };

  const styles = useStyles();
  return (
    <>
      <div>
        <div className={styles.header}>
          <div>
            <Typography variant='h2'>{workplaceBaseData.name}</Typography>
            <Typography>{workplaceBaseData.location}</Typography>
          </div>
          <div className={styles.headerButtons}>
            {isAllowedToEdit && (
              <IconButton className={styles.editButton} onClick={openEditDialog}>
                <PenIcon />
              </IconButton>
            )}
            <IconButton onClick={onClose}>
              <CloseIcon className={styles.closeIcon} />
            </IconButton>
          </div>
        </div>
        <div className={styles.body}>
          <div>
            {templateValues.map(({ sectionName, contentElements }) => {
              if (!contentElements.length && !isAllowedToEdit) return emptySectionMessage;
              return (
                <div key={sectionName} className={styles.categorySection}>
                  <Typography variant='h3'>{sectionName}</Typography>
                  <div className={styles.contentGrid}>
                    {contentElements.map(({ id, name, value }) => {
                      if (!value && !isAllowedToEdit) return emptySectionMessage;
                      return (
                        <div key={id} className={styles.contentRow}>
                          <Typography>{name}</Typography>
                          {/* eslint-disable-next-line react/no-danger */}
                          <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(value) }} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.responsibles}>
            <Typography variant='h3'>{t(workplace_messages.singleWorkplace.responsibles)}</Typography>
            <div className={styles.responsiblesGrid}>
              {workplaceBaseData.responsibles.length
                ? workplaceBaseData.responsibles.map(({ email, profile, id }) => (
                    <div key={id} className={styles.responsibleTile}>
                      <UserAvatar size='m' userData={profile} userId={`${id}`} />
                      <div className={styles.responsibleName}>
                        <Typography>{profile.fullName}</Typography>
                        <Typography>{email}</Typography>
                      </div>
                    </div>
                  ))
                : t(workplace_messages.singleWorkplace.emptyResponsibles)}
            </div>
          </div>
        </div>
      </div>
      <EditWorkplaceDialog onClose={closeEditDialog} open={editDialog} refreshData={refreshData} workplaceId={workplaceBaseData.id} />
    </>
  );
};

export default WorkplaceDetailsCard;
