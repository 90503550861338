import React from 'react';

import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './ManagePageHeader.styles';

const ManagePageHeader = ({ children, subtitle, rightAdornment, noBorder, bottomAdornment, className }) => {
  const styles = useStyles({ noBorder });
  return (
    <header className={clsx(styles.header, className)}>
      <div>
        <Typography component='h1' variant='h2'>
          {children}
        </Typography>
        {subtitle && <Typography className={styles.subtitle}>{subtitle}</Typography>}
        {bottomAdornment || null}
      </div>
      {rightAdornment && <div className={styles.rightAdornment}>{rightAdornment}</div>}
    </header>
  );
};

ManagePageHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightAdornment: PropTypes.node,
  noBorder: PropTypes.bool,
  className: PropTypes.string,
};

ManagePageHeader.defaultProps = {
  rightAdornment: null,
  noBorder: false,
  subtitle: null,
};

export default ManagePageHeader;
