import React, { useEffect, useState } from 'react';

import { useMediaQuery, useTheme } from '@material-ui/core';
import { eachDayOfInterval, isEqual } from 'date-fns';
import PropTypes from 'prop-types';

import CalendarHeader from 'components/_calendar/CalendarHeader';
import MeetingsCalendar from 'components/_calendar/MeetingsCalendar';
import meetingsEndpoints from 'config/api/meetings';
import ROLES from 'config/constants/ROLES';
import EVENTS from 'config/events/pubsub';
import generateDayRange from 'helpers/generateDayRange';
import generateWeekRange from 'helpers/generateWeekRange';
import pruneTimeFromDate from 'helpers/pruneTimeFromDate';
import useApiCall from 'hooks/useApiCall';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import useLoadingState from 'hooks/useLoadingState';
import usePermissions from 'hooks/usePermissions';
import useSubscription from 'hooks/useSubscription';
import { useAdvisorContext } from 'reactContext/AdvisorContext';
import getDateRange from 'services/getDateRange';

const getSlotObj = (date, { availableSlots = [], bookedSlots = [] }) => {
  const bookedSlot = bookedSlots.find(bookedDate => isEqual(new Date(bookedDate.begin), date));
  const availableSlot = availableSlots.find(({ begin }) => isEqual(new Date(begin), date));
  return {
    date,
    isAvailable: availableSlots ? !!availableSlot : true,
    isBooked: !!bookedSlot,
    slotId: availableSlot?.id,
    meetingId: bookedSlot?.id,
  };
};

const ManageMeetingCalendar = ({ tileHref, loadingDates, clearLoadingDate, forceSingleDay, isClickableCheck, hideNA, onSlotClick }) => {
  const [availableSlots, setAvailableSlots] = useState([]);
  const [bookedSlots, setBookedSlots] = useState([]);
  const { advisor } = useAdvisorContext();

  const { loading, setLoaded, setLoading } = useLoadingState(true);
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const showSingleDay = useMediaQuery(theme.breakpoints.down('xs'));

  const [dateRange, setDateRange] = useState(getDateRange(new Date()));
  const [singleDay, setSingleDay] = useState(pruneTimeFromDate(new Date()));
  const [isAdviser, isSupervisor] = usePermissions([ROLES.PS_ROLES.SECURITY_ONLINE, ROLES.PS_ROLES.SECURITY_SUPERVISOR]);

  const refreshAfterAvailabilityChange = async (single, data) => {
    setLoading();
    // data => workaround for not passing context values by PubSub events
    const { start, end } = single ? generateDayRange(data.date) : generateWeekRange(data.date);
    const startDateString = start.toISOString();
    const endDateString = end.toISOString();
    const { data: availableSlotsFromAPI, status: availableSlotsStatus } = await (() => {
      if (isSupervisor) {
        return apiCall(
          meetingsEndpoints.getAvailableSlotsAsSupervisor({
            begin__gte: startDateString,
            end__lte: endDateString,
            adviser: data.advisor,
          }),
        );
      }
      if (isAdviser) {
        return apiCall(
          meetingsEndpoints.getAvailableSlotsAsAdviser({
            begin__gte: startDateString,
            end__lte: endDateString,
            adviser: data.advisor,
          }),
        );
      }
      return apiCall(
        meetingsEndpoints.getAvailableSlotsAsSecurityOfficer({
          begin__gte: startDateString,
          end__lte: endDateString,
          adviser: data.advisor,
        }),
      );
    })();
    if (availableSlotsStatus < 300) setAvailableSlots(availableSlotsFromAPI);
    clearLoadingDate(data);
    setLoaded();
  };

  const getData = async single => {
    setLoading();
    const { start, end } = generateDayRange(singleDay);
    const startDateString = single ? start.toISOString() : dateRange.start.toISOString();
    const endDateString = single ? end.toISOString() : dateRange.end.toISOString();
    const [{ data: availableSlotsFromAPI, status: availableSlotsStatus }, { data: bookedSlotsFromAPI, status: bookedSlotsStatus }] =
      await (() => {
        if (isSupervisor) {
          return Promise.all([
            apiCall(
              meetingsEndpoints.getAvailableSlotsAsSupervisor({
                begin__gte: startDateString,
                end__lte: endDateString,
                adviser: advisor,
              }),
            ),
            apiCall(
              meetingsEndpoints.getMeetingsAsSupervisor({
                time_slot__begin__gte: startDateString,
                time_slot__end_lte: endDateString,
                adviser: advisor,
              }),
            ),
          ]);
        }
        if (isAdviser) {
          return Promise.all([
            apiCall(
              meetingsEndpoints.getAvailableSlotsAsAdviser({
                begin__gte: startDateString,
                end__lte: endDateString,
                adviser: advisor,
              }),
            ),
            apiCall(
              meetingsEndpoints.getMeetingsAsAdviser({
                time_slot__begin__gte: startDateString,
                time_slot__end_lte: endDateString,
                adviser: advisor,
              }),
            ),
          ]);
        }
        return Promise.all([
          apiCall(
            meetingsEndpoints.getAvailableSlotsAsSecurityOfficer({
              begin__gte: startDateString,
              end__lte: endDateString,
              adviser: advisor,
            }),
          ),
          apiCall(
            meetingsEndpoints.getMeetingsAsSecurityOfficer({
              time_slot__begin__gte: startDateString,
              time_slot__end_lte: endDateString,
              adviser: advisor,
            }),
          ),
        ]);
      })();
    if (availableSlotsStatus < 300) setAvailableSlots(availableSlotsFromAPI);
    if (bookedSlotsStatus < 300) setBookedSlots(bookedSlotsFromAPI);
    setLoaded();
  };

  const slotsMiddleware = (dates, { full, half }) =>
    dates.push(getSlotObj(full, { availableSlots, bookedSlots }), getSlotObj(half, { availableSlots, bookedSlots }));

  useDidUpdateEffect(() => {
    getData(forceSingleDay || showSingleDay);
  }, [dateRange, singleDay, showSingleDay, advisor]);

  useEffect(() => {
    const { values } = theme.breakpoints;
    const screenSize = window.innerWidth;
    getData(forceSingleDay || screenSize <= values.sm);
  }, []);

  useSubscription(EVENTS.AVAILABILITY_UPDATED, (_, data) => refreshAfterAvailabilityChange(forceSingleDay, data));
  useSubscription(EVENTS.MEETINGS_UPDATED, () => getData(forceSingleDay));

  return (
    <div>
      <CalendarHeader
        dateRange={dateRange}
        forceSingleDay={forceSingleDay}
        setDateRange={setDateRange}
        setSingleDay={setSingleDay}
        singleDay={singleDay}
      />
      <MeetingsCalendar
        advisorView
        autoScroll
        days={eachDayOfInterval(dateRange)}
        forceSingleDay={forceSingleDay}
        hideNA={hideNA}
        isClickableCheck={isClickableCheck}
        loading={loading}
        loadingDates={loadingDates}
        onSlotClick={onSlotClick}
        singleDay={singleDay}
        slotsMiddleware={slotsMiddleware}
        tileHref={tileHref}
      />
    </div>
  );
};

ManageMeetingCalendar.propTypes = {
  tileHref: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  clearLoadingDate: PropTypes.func,
  loadingDates: PropTypes.arrayOf(PropTypes.object),
  forceSingleDay: PropTypes.bool,
  isClickableCheck: PropTypes.func,
  hideNA: PropTypes.bool,
  onSlotClick: PropTypes.func,
};

ManageMeetingCalendar.defaultProps = {
  loadingDates: null,
  forceSingleDay: false,
  clearLoadingDate: () => {},
  tileHref: null,
  isClickableCheck: () => false,
  hideNA: false,
  onSlotClick: null,
};

export default ManageMeetingCalendar;
