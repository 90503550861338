import React from 'react';

import { Container, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import GuidesTilesGrid from 'components/GuideTilesGrid';

const useStyles = makeStyles(theme => ({
  section: {
    padding: theme.spacing(6, 0),
    background: theme.palette.primary[50],
  },
}));

const RelatedContent = ({ guides }) => {
  const styles = useStyles();
  if (!guides.length) return null;

  return (
    <section className={styles.section}>
      <Container maxWidth='lg'>
        <GuidesTilesGrid data={guides} />
      </Container>
    </section>
  );
};

RelatedContent.propTypes = {
  guides: PropTypes.arrayOf(PropTypes.shape({})),
};

RelatedContent.defaultProps = {
  guides: [],
};

export default RelatedContent;
