import React, { useEffect, useState } from 'react';

import { Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ArticleTilesGrid from 'components/ArticleTilesGrid';
import DescriptionText from 'components/DescriptionText';
import GuideTile from 'components/GuideTile';
import Jumbotron from 'components/Jumbotron';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import RedirectButton from 'components/RedirectButton/RedirectButton';
import Section from 'components/Section';
import commonEndpoints from 'config/api/common';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import PUBLISH_STATUSES from 'config/constants/PUBLISH_STATUSES';
import useApiCall from 'hooks/useApiCall';
import general_messages from 'messages/general_messages';
import PATHS from 'router/PATHS';

import useStyles from './ServiceAreaPage.styles';

const PAGE_LIMIT = 9;

const ServiceAreaPage = () => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const { service_area_id } = useParams();
  const styles = useStyles();

  const [serviceAreaData, setServiceAreaData] = useState(null);
  const [serviceAreaArticles, setServiceAreaArticles] = useState({ articlesList: [], articlesCount: null });
  const [serviceAreaGuides, setServiceAreaGuides] = useState({ guidesList: [], guidesCount: null });

  const { apiCall } = useApiCall();

  const getServiceAreaData = async () => {
    const { data } = await apiCall(commonEndpoints.serviceAreaInfo(service_area_id));
    setServiceAreaData(data);
  };

  const getServiceAreaContent = async () => {
    const articlesQuery = apiCall(commonEndpoints.search({ limit: PAGE_LIMIT }), {
      data: {
        object_content_type: [CONTENT_TYPES.ARTICLE],
        publish_status: [PUBLISH_STATUSES.PUBLISHED],
        service_area: [service_area_id],
        order_by: ['-is_sticky', '-updated_at'],
      },
    });
    const guidesQuery = apiCall(commonEndpoints.search({ limit: PAGE_LIMIT }), {
      data: {
        object_content_type: [CONTENT_TYPES.GUIDE],
        publish_status: [PUBLISH_STATUSES.PUBLISHED],
        service_area: [service_area_id],
        order_by: ['-is_sticky', '-updated_at'],
      },
    });
    const [articles, guides] = await Promise.all([articlesQuery, guidesQuery]);
    setServiceAreaArticles({ articlesList: articles.data.results, articlesCount: articles.data.count });
    setServiceAreaGuides({ guidesList: guides.data.results, guidesCount: guides.data.count });
  };

  useEffect(() => {
    getServiceAreaData();
    getServiceAreaContent();
    window.scrollTo(0, 0);
  }, [service_area_id]);

  const redirectToFullListButton = pathname => (
    <RedirectButton
      message={t(...general_messages.service_area_articles_redirect_button_label, { service_area: serviceAreaData?.name })}
      pathname={pathname}
      state={{ preselectedFilters: { service_area: [Number(service_area_id)] } }}
    />
  );

  return (
    <Container disableGutters={!upMd}>
      <Jumbotron>
        <Typography component='h1' variant='h1'>
          {serviceAreaData?.name}
        </Typography>
      </Jumbotron>
      <MobileGuttersContainer>
        {serviceAreaData?.description && (
          <DescriptionText className={styles.description} component='div'>
            {parse(serviceAreaData.description)}
          </DescriptionText>
        )}
        {serviceAreaArticles?.articlesList.length ? (
          <>
            <Section smallPadding title={t(...general_messages.content_types.article)}>
              <ArticleTilesGrid data={serviceAreaArticles?.articlesList} placeholdersNumber={PAGE_LIMIT} scrollable />
            </Section>
            {serviceAreaArticles.articlesCount > PAGE_LIMIT && redirectToFullListButton(PATHS.ARTICLES)}
          </>
        ) : null}
        {serviceAreaGuides?.guidesList.length ? (
          <>
            <Section title={t(...general_messages.content_types.guide)}>
              <div className={styles.guides}>
                {serviceAreaGuides?.guidesList.map(({ id, metadata, ...rest }) => (
                  <GuideTile key={id} id={id} metadata={metadata} {...rest} />
                ))}
              </div>
            </Section>
            {serviceAreaGuides?.guidesCount > PAGE_LIMIT && redirectToFullListButton(PATHS.GUIDES)}
          </>
        ) : null}
      </MobileGuttersContainer>
    </Container>
  );
};

export default ServiceAreaPage;
