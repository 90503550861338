import React, { useEffect, useMemo } from 'react';

import { Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import ButtonsGrid from 'components/_dialogs/_components/ButtonsGrid';
import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormAutocomplete from 'components/FormAutocomplete';
import FormTextInput from 'components/FormTextInput';
import { questionsApi } from 'config/api/questions';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import QUESTION_STATUSES from 'config/constants/QUESTION_STATUSES';
import parseDateTime from 'helpers/parseDateTime';
import general_messages from 'messages/general_messages';
import questions_messages from 'messages/questions_messages';
import relatedContentOptionsParser from 'services/autocompleteServices/relatedContentOptionsParser';
import relatedContentParamsGetter from 'services/autocompleteServices/relatedContentParamsGetter';
import relatedContentResultComponent from 'services/autocompleteServices/relatedContentResultComponent';
import getRelatedContentInitialValues from 'services/getRelatedContentInitialValues';

import useStyles from './QuestionEditDialog.styles';

const FORM = {
  ANSWER: 'answer',
  RELATED_CONTENT: 'related content',
};

const MODE = {
  DRAFT: 'draft',
  PUBLISH: 'publish',
};

const QuestionEditDialog = ({ open, onClose, questionData, refreshData }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const isDraft = questionData.progress_status === QUESTION_STATUSES.DRAFT;

  const { data: answerData } = useQuery(['get question details', questionData.id], () => questionsApi.getAnswerPSOnline(questionData.id), {
    enabled: questionData.progress_status !== QUESTION_STATUSES.UNANSWERED,
  });

  const mutationCreate = useMutation('answer - create', questionsApi.createAnswer);
  const mutationPublish = useMutation('answer - publish', questionsApi.publishAnswer, {
    onSuccess: () => {
      enqueueSnackbar(t(questions_messages.snackbar.answer_published), { variant: 'success' });
      refreshData();
      onClose();
    },
  });
  const mutationEdit = useMutation('answer - update draft', questionsApi.updateAnswer, {
    onSuccess: () => {
      enqueueSnackbar(t(questions_messages.snackbar.draft_saved), { variant: 'success' });
      refreshData();
      onClose();
    },
  });

  const formik = useFormik({
    initialValues: {
      [FORM.ANSWER]: '',
      [FORM.RELATED_CONTENT]: '',
    },
  });

  useEffect(() => {
    if (answerData) {
      formik.setValues({
        [FORM.ANSWER]: answerData.data.text,
        [FORM.RELATED_CONTENT]: getRelatedContentInitialValues(answerData.data),
      });
    }
  }, [answerData]);

  const onSubmit = async mode => {
    const data = {
      question: questionData.id,
      text: formik.values[FORM.ANSWER],
      related_articles: [],
      related_guides: [],
    };
    const relatedContent = formik.values[FORM.RELATED_CONTENT] || [];
    relatedContent.forEach(({ object_content_type, key }) => {
      if (object_content_type === CONTENT_TYPES.ARTICLE) data.related_articles.push(key);
      if (object_content_type === CONTENT_TYPES.GUIDE || object_content_type === CONTENT_TYPES.INCIDENT_GUIDE) {
        data.related_guides.push(key);
      }
    });
    let id = answerData?.data.id;
    if (!id) {
      const { data: answerDataLocal } = await mutationCreate.mutateAsync(data);
      // eslint-disable-next-line prefer-destructuring
      id = answerDataLocal.id;
    }
    if (mode === MODE.PUBLISH) mutationPublish.mutate({ id, data });
    if (mode === MODE.DRAFT) mutationEdit.mutate({ id, data });
  };

  const onPublish = () => onSubmit(MODE.PUBLISH);
  const onSaveDraft = () => onSubmit(MODE.DRAFT);

  const disabled = useMemo(
    () => mutationCreate.isLoading || mutationPublish.isLoading || mutationEdit.isLoading,
    [mutationCreate, mutationPublish, mutationEdit],
  );
  const styles = useStyles();
  return (
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          <ColoredButton customColor='none' disabled={disabled} onClick={onSaveDraft} variant='outlined'>
            {t(...questions_messages.edit_buttons.save_draft)}
          </ColoredButton>
          <ColoredButton customColor='secondary' disabled={disabled} onClick={onPublish} variant='outlined'>
            {t(...questions_messages.edit_buttons.publish)}
          </ColoredButton>
        </ButtonsGrid>
      }
      onClose={onClose}
      open={open}
      title={t(isDraft ? questions_messages.dialog_draft_title : questions_messages.dialog_response_title)}
    >
      <CenteredGrid gridGap={1.5} tight>
        <Typography variant='body2'>
          {t(questions_messages.dialog_question_by)}: {questionData.user} @ {parseDateTime(questionData.created_at)}
        </Typography>
        {isDraft && (
          <Typography variant='body2'>
            {t(questions_messages.dialog_answered_by)}: {questionData.updated_by} @ {parseDateTime(questionData?.updated_at)}
          </Typography>
        )}
      </CenteredGrid>

      <CenteredGrid tight title={questionData.knowledge_area_name}>
        <Typography className={styles.subtitle}>{t(questions_messages.dialog_question_title)}</Typography>
        <Typography variant='body2'>{questionData.text}</Typography>
      </CenteredGrid>
      <form>
        <CenteredGrid gridGap={1.5} title={t(general_messages.related_content)} withoutPadding>
          <FormTextInput formik={formik} id={FORM.ANSWER} label={t(questions_messages.answer_input_label)} multiline rows={3} />
          <FormAutocomplete
            apiCallParamsGetter={relatedContentParamsGetter}
            customizeLabel={relatedContentResultComponent}
            formik={formik}
            id={FORM.RELATED_CONTENT}
            label={t(...general_messages.search_add)}
            labelClassName={styles.autocompleteLabel}
            optionsParser={relatedContentOptionsParser}
          />
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

export default QuestionEditDialog;

// TODO answer author and date
// TODO related content display (check)
