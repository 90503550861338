import React, { useRef } from 'react';

import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import type { Identifier } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DNDIcon } from 'assets/icons/dnd.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import CenteredGrid from 'components/CenteredGrid/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import WorkplaceTemplateContent from 'components/WorkplaceTemplateEditor/_components/WorkplaceTemplateContent/WorkplaceTemplateContent';
import onDNDHover, { DragItem } from 'components/WorkplaceTemplateEditor/_services/onDNDHover';
import { Content, ItemTypes } from 'components/WorkplaceTemplateEditor/WorkplaceTemplateEditor';
import workplace_messages from 'messages/workplace_messages';

import useStyles from './WorkplaceTemplateSection.styles';

type Props = {
  formik: any;
  sectionId: string;
  contentElements: { name: string; id: string; order: number }[];
  onDeleteSection: () => void;
  onDeleteContent: (sectionId: string, contentId: Content) => () => void;
  onAddContentInSection: () => void;
  onDNDSection: (dragIndex: number, hoverIndex: number) => void;
  onDNDContent: (dragIndex: number, hoverIndex: number) => void;
  index: number;
};

const WorkplaceTemplateSection: React.FC<Props> = ({
  formik,
  sectionId,
  contentElements,
  onAddContentInSection,
  onDeleteSection,
  onDeleteContent,
  onDNDSection,
  onDNDContent,
  index,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.SECTION,
    collect: monitor => ({ handlerId: monitor.getHandlerId() }),
    hover: (item, monitor) => onDNDHover(item, monitor, { ref, index, onDND: onDNDSection }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.SECTION,
    item: () => ({ id: sectionId, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const styles = useStyles({ isDragging });
  return (
    <div ref={ref} className={styles.root} data-handler-id={handlerId}>
      {/* @ts-ignore */}
      <CenteredGrid key={sectionId} gridGap={2} tight>
        <div className={styles.row}>
          <DNDIcon className={styles.dndIcon} />
          <FormTextInput formik={formik} id={`section.${sectionId}`} label={t(workplace_messages.template.sectionLabel)} />
          <IconButton onClick={onDeleteSection}>
            <TrashIcon />
          </IconButton>
        </div>
        {/* @ts-ignore */}
        <CenteredGrid key={sectionId} gridGap={2} tight>
          {contentElements.map((content, contentIndex) => (
            <WorkplaceTemplateContent
              key={content.id}
              contentId={content.id}
              formik={formik}
              index={contentIndex}
              onDelete={onDeleteContent(sectionId, content)}
              onDNDContent={onDNDContent}
            />
          ))}
          {/* @ts-ignore */}
          <ColoredButton className={styles.addButton} customColor='secondary' icon onClick={onAddContentInSection}>
            <AddIcon />
          </ColoredButton>
        </CenteredGrid>
      </CenteredGrid>
    </div>
  );
};

export default WorkplaceTemplateSection;
