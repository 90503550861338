import React from 'react';

import { ButtonBase, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.primary[100],
    padding: theme.spacing(2),
    position: 'relative',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.primary[200],
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
  },
  label: {
    textTransform: 'none',
  },
  buttonProgress: {
    color: theme.palette.primary[200],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const FooterButton = ({ className, children, loading, ...rest }) => {
  const styles = useStyles();
  return (
    <ButtonBase className={clsx(styles.button, className)} disabled={loading} {...rest}>
      <Typography className={styles.label} variant='button'>
        {children}
      </Typography>
      {loading && <CircularProgress className={styles.buttonProgress} size={24} />}
    </ButtonBase>
  );
};

FooterButton.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FooterButton.defaultProps = {
  className: null,
  loading: false,
};

export default FooterButton;
