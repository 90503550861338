import ROLES from 'config/constants/ROLES';

import { COMMON_DIALOG_TYPES } from '../reactContext/CommonDialogsContext';

import PATHS from './PATHS';

const { ORGANIZATION, PS_ROLES } = ROLES;

const permissions = {
  [PATHS.INCIDENT_REPORTS]: { onlyForRoles: [ORGANIZATION.INCIDENT_MANAGER] },
  [PATHS.USERS]: { onlyForRoles: [ORGANIZATION.USER_ADMINISTRATOR] },
  [PATHS.BOOK_MEETING]: { onlyForRoles: [ORGANIZATION.SECURITY_OFFICER] },
  [PATHS.MEETING_ADVICES]: { onlyForRoles: [ORGANIZATION.SECURITY_OFFICER] },
  [PATHS.ORGANIZATION_ADMINISTRATION]: { onlyForRoles: [ORGANIZATION.ACCOUNT_OWNER] },

  [PATHS.MANAGE_CONTENT]: { onlyForPSRoles: [PS_ROLES.CONTENT_EDITOR] },
  [PATHS.MANAGE_MEETING_SLOTS]: { onlyForPSRoles: [PS_ROLES.SECURITY_ONLINE, PS_ROLES.SECURITY_SUPERVISOR] },
  [PATHS.ADVISORY_PAGE]: { onlyForPSRoles: [PS_ROLES.SECURITY_ONLINE, PS_ROLES.SECURITY_SUPERVISOR] },
  [PATHS.MEETING_REPORT]: {
    onlyForPSRoles: [PS_ROLES.SECURITY_ONLINE, PS_ROLES.SECURITY_SUPERVISOR],
    onlyForRoles: [ORGANIZATION.SECURITY_OFFICER],
  },
  [COMMON_DIALOG_TYPES.ASK_QUESTION]: { onlyForRoles: [ORGANIZATION.SECURITY_OFFICER] },
  [PATHS.QUESTIONS]: { onlyForPSRoles: [PS_ROLES.SECURITY_ONLINE, PS_ROLES.SECURITY_SUPERVISOR] },
};

export default permissions;
