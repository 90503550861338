import commonEndpoints from 'config/api/common';
import general_messages from 'messages/general_messages';

const prepareTags = ({ tags }) => tags.map(tag => ({ id: tag, name: tag }));

const tagFilter = {
  url: commonEndpoints.tags(),
  dataParser: (filters, result) => ({ ...result, tags: { sectionName: general_messages.tags, filters: prepareTags(filters) } }),
};

export default tagFilter;
