import React, { useEffect, useRef } from 'react';

import { AppBar, makeStyles, Slide, Toolbar, useScrollTrigger } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Link } from 'react-router-dom';

import { ReactComponent as LogoLight } from 'assets/logo_light.svg';
import ContentSection from 'components/Layout/_components/sections/ContentSection';
import FooterSection from 'components/Layout/_components/sections/FooterSection';
import OrganizationSection from 'components/Layout/_components/sections/OrganizationSection';
import PocketSafeAdministrationSection from 'components/Layout/_components/sections/PocketSafeAdministrationSection';
import ServiceSection from 'components/Layout/_components/sections/ServiceSection';
import UserSection from 'components/Layout/_components/sections/UserSection';
import isIOS from 'helpers/isIOS';
import { useAuthContext } from 'reactContext/AuthContext';
import PATHS from 'router/PATHS';
// eslint-disable-next-line import/extensions
import useBrandingStore from 'storages/brandingStore.ts';
import userDataStorage from 'storages/userDataStorage';

import useLayoutStore from '../../../../storages/layoutStore';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  bar: {
    background: theme.palette.background.dark,
    color: theme.palette.text.inverted,
    ...theme.mixins.toolbar,
    borderBottom: `2px solid ${theme.palette.common.black}`,
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    justifyItems: 'flex-start',
    ...theme.mixins.toolbar,
  },
  logo: {
    width: '102px',
    height: '16px',
  },
  icon: {
    color: theme.palette.text.inverted,
  },
  closeButton: {
    color: theme.palette.secondary.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: '100%',
    padding: '5px',
    margin: '5px',
  },
  drawer: {
    minHeight: '100vh',
    display: 'flex',
    '-webkit-overflow-scrolling': 'touch',
    background: theme.palette.background.dark,
    color: theme.palette.text.inverted,
    ...theme.mixins.toolbarPadding,
  },
  drawerInner: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  drawerMainSection: {
    flexGrow: '1',
    marginBottom: '-2px',
  },
}));

const MainMenuMobile = () => {
  const { logo } = useBrandingStore();
  const bodyScrollLockElement = useRef();

  const userData = userDataStorage.get();
  const { isAuthorized } = useAuthContext();
  const trigger = useScrollTrigger();

  const { isMenuOpen, closeMenu, toggleMenu } = useLayoutStore();

  useEffect(() => {
    if (!isIOS()) {
      if (isMenuOpen) disableBodyScroll(bodyScrollLockElement);
      else enableBodyScroll(bodyScrollLockElement);
    }
    return () => enableBodyScroll(bodyScrollLockElement);
  }, [isMenuOpen]);

  useEffect(() => {
    closeMenu();
  }, []);

  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Slide appear={false} direction='down' in={!trigger || isMenuOpen}>
        <AppBar className={styles.bar}>
          <Toolbar className={styles.toolbar}>
            <IconButton component='a' href={process.env.REACT_APP_LANDING_PAGE_URL} target='_blank'>
              {logo?.full ? <img alt='' className={styles.logo} src={logo?.full} /> : <LogoLight className={styles.logo} />}
            </IconButton>
            <IconButton component={Link} to={PATHS.GLOBAL_SEARCH}>
              <SearchIcon className={styles.icon} />
            </IconButton>
            <IconButton className={isMenuOpen ? styles.closeButton : styles.icon} onClick={toggleMenu}>
              {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </Toolbar>
        </AppBar>
      </Slide>
      <Drawer
        ref={bodyScrollLockElement}
        anchor='top'
        classes={{
          paper: styles.drawer,
        }}
        open={isMenuOpen}
        variant='persistent'
      >
        <div className={styles.drawerInner}>
          <div className={styles.drawerMainSection}>
            <UserSection />
            {isAuthorized && userData?.hasPaymentMethod && isMenuOpen && (
              <>
                <ServiceSection />
                <OrganizationSection />
                <ContentSection />
                <PocketSafeAdministrationSection />
              </>
            )}
          </div>
          <FooterSection />
        </div>
      </Drawer>
    </div>
  );
};

MainMenuMobile.propTypes = {};

export default MainMenuMobile;
