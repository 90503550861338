import React from 'react';

import { Button, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import CenteredGrid from 'components/CenteredGrid';

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  error: {
    minHeight: theme.spacing(3),
    fontSize: '0.875rem',
  },
  button: {
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    '&.MuiButtonBase-root.Mui-disabled': {
      color: ({ locked }) => locked && theme.palette.text.primary,
    },
    '&:before': {
      display: ({ locked }) => (locked ? 'block' : 'none'),
      transition: 'width 1s linear',
      content: '""',
      background: theme.palette.primary[200],
      right: 0,
      width: ({ locked }) => `${((60 - locked) / 60) * 100}%`,
      height: '100%',
      position: 'absolute',
      zIndex: -1,
    },
  },
}));

const SimpleFormLayout = ({
  onSubmit,
  submitLabel,
  title,
  children,
  disabled,
  generalError,
  onChange,
  buttonVariant,
  locked,
  isForm,
  FORM_ID,
  sending,
  additionalButtons,
}) => {
  const styles = useStyles({ locked });

  const getLabel = () => {
    if (sending) return <CircularProgress color='primary' size={22} />;
    if (locked) return `00:${`0${locked}`.slice(-2)}`;
    return submitLabel;
  };

  return (
    <CenteredGrid component={isForm ? 'form' : 'div'} gridGap={1.5} onChange={onChange} onSubmit={onSubmit} width='xs'>
      {title && (
        <Typography className={styles.heading} component='h2' variant='h2'>
          {title}
        </Typography>
      )}
      {children}
      <Typography align='center' className={styles.error} color='error' data-cy='simple_form_error'>
        {generalError}
      </Typography>
      {additionalButtons && additionalButtons()}
      <Button
        className={styles.button}
        color='primary'
        data-cy='simple_form_submit'
        disabled={disabled || locked || sending}
        form={FORM_ID}
        type='submit'
        variant={buttonVariant}
      >
        {getLabel()}
      </Button>
    </CenteredGrid>
  );
};

SimpleFormLayout.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  submitLabel: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  generalError: PropTypes.string,
  buttonVariant: PropTypes.string,
  locked: PropTypes.number,
  isForm: PropTypes.bool,
  FORM_ID: PropTypes.string,
  sending: PropTypes.bool,
  additionalButtons: PropTypes.func,
};

SimpleFormLayout.defaultProps = {
  submitLabel: 'Ok',
  title: null,
  generalError: null,
  onChange: () => {},
  onSubmit: () => {},
  disabled: false,
  buttonVariant: 'contained',
  locked: null,
  isForm: true,
  FORM_ID: null,
  sending: false,
  additionalButtons: null,
};

export default SimpleFormLayout;
