import table_messages from 'messages/table_messages';

import booleanFilter from '../booleanFilter';

const isGDPR = {
  sectionName: table_messages.is_gdpr,
  filters: booleanFilter,
  isSingleSelect: true,
};

export default isGDPR;
