import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  externalWrapper: {
    width: '100%',
    overflowX: 'scroll',
    marginTop: theme.spacing(4),
  },
  internalWrapper: {
    display: 'flex',
    padding: theme.spacing(3, 0),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
  scrollableElement: {
    display: 'flex',
    alignItems: 'center',

    '& > *': {
      flexShrink: 0,
      marginRight: theme.spacing(5),
    },
  },
}));
