import React, { useEffect, useState } from 'react';

import { Container, FormGroup, Link, makeStyles, Paper, Typography } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';

import MetadataDialog from 'components/_dialogs/MetadataDialog';
import ColoredButton from 'components/ColoredButton';
import FormSelect from 'components/FormSelect';
import FormSwitch from 'components/FormSwitch';
import FormTextInput from 'components/FormTextInput';
import guidesEndpoints from 'config/api/guides';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import { AUDIENCES_ARRAY } from 'config/translatableConstants/AUDIENCES';
import { CUSTOMER_LEVELS_ARRAY } from 'config/translatableConstants/CUSTOMER_LEVELS';
import { DEEP_LEVELS_ARRAY } from 'config/translatableConstants/DEEP_LEVELS';
import getFilenameFormPath from 'helpers/getFilenameFormPath';
import mergeWithCommas from 'helpers/mergeWithCommas';
import useApiCall from 'hooks/useApiCall';
import useBoolState from 'hooks/useBoolState';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';
import metadata_messages from 'messages/metadata_messages';
import title_messages from 'messages/title_messages';
import validation_messages from 'messages/validation_messages';
import PATHS from 'router/PATHS';
import prepareDataForSelect from 'services/prepareDataForSelect';

import GuideSchema from './_components/GuideSchema';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      background: theme.palette.secondary[50],
    },
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(4),
    padding: theme.spacing(4, 0),
  },
  fullWidth: {
    gridColumn: '1 / 3',
  },
  bold: {
    fontWeight: 500,
  },
  paper: {
    padding: theme.spacing(4),
    display: 'grid',
    gridGap: theme.spacing(2.5),
    alignContent: 'flex-start',
  },
  steps: {
    padding: 0,
    gridColumn: '1 / 3',
  },
  stepsTitle: {
    padding: theme.spacing(4, 4, 2.5, 4),
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  schemaWrapper: {
    paddingBottom: theme.spacing(4),
    position: 'relative',
    overflow: 'hidden',
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: 'minmax(300px, 1fr) minmax(300px, 1fr)',
    gridGap: theme.spacing(4),
    justifySelf: 'center',
  },
  formGroup: {
    display: 'grid',
    gridGap: '1.5rem',
  },
}));

const getRelatedContent = data => {
  if (!data) return '';
  const { related_guides = [], related_articles = [] } = data;
  return [...related_guides, ...related_articles].map(({ friendly_id }) => friendly_id).join(', ');
};

const SENDING = {
  SAVE: 'save',
  PUBLISH: 'publish',
};

const EditGuidePage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { apiCall } = useApiCall();

  const [guideData, setGuideData] = useState(null);
  const [steps, setSteps] = useState(null);
  const [pristine, setPristine] = useState(true);
  const { state: metadataDialog, setTrue: openMetadataDialog, setFalse: closeMetadataDialog } = useBoolState(false);
  const [templatesOptions, setTemplatesOptions] = useState([]);
  const [sending, setSending] = useState(null);

  const reportChange = () => setPristine(false);

  const formik = useFormik({
    initialValues: {
      description: '',
      is_incident: false,
      incident_report_template: '',
    },
  });

  const getGuideData = async () => {
    const { data } = await apiCall(guidesEndpoints.get(id));
    setGuideData(data);
  };

  const getStepSchema = async () => {
    const { data } = await apiCall(guidesEndpoints.getGuideSchema(id));
    setSteps(data);
  };

  const getTemplatesDictionary = async () => {
    const { data } = await apiCall(guidesEndpoints.getTemplates());
    setTemplatesOptions(prepareDataForSelect(data, 'id', 'label'));
  };

  const getData = async () => {
    await Promise.all([getGuideData(), getStepSchema(), getTemplatesDictionary()]);
  };

  const save = async (isPublish = false) => {
    const { values, setFieldError } = formik;
    if (!steps?.length) {
      enqueueSnackbar(t(...validation_messages.guide_need_one_step), { variant: 'error' });
      return;
    }
    if (!values.incident_report_template && values.is_incident) {
      enqueueSnackbar(t(...validation_messages.missing_required_snackbar), { variant: 'error' });
      setFieldError('incident_report_template', t(...validation_messages.required));
      return;
    }
    setSending(isPublish === true ? SENDING.PUBLISH : SENDING.SAVE);
    const data = { ...values, steps };
    if (isPublish === true) data.new_version = true;
    if (!data.is_incident) data.incident_report_template = null;
    const { status } = await apiCall(guidesEndpoints.update(id), { data });
    setPristine(false);
    if (status < 300) {
      enqueueSnackbar(isPublish === true ? t(...general_messages.published) : t(...general_messages.data_saved), { variant: 'success' });
    }
    setSending(null);
  };

  const publish = () => save(true);

  useEffect(() => {
    if (id) getData();
  }, [id]);

  useEffect(() => {
    if (guideData) {
      formik.setValues({
        description: guideData.description,
        is_incident: guideData.is_incident || false,
        incident_report_template: guideData.incident_report_template || '',
      });
    }
  }, [guideData]);

  useEffect(() => {
    if (formik.dirty) reportChange();
  }, [formik.dirty]);

  const styles = useStyles();
  if (!id) return <Redirect to={PATHS.ROOT} />;
  return (
    <Container>
      <div className={styles.wrapper}>
        <Typography align='center' className={styles.fullWidth} component='h1' variant='h1'>
          {t(...title_messages.content_update)}
        </Typography>
        <Paper className={clsx(styles.paper, styles.fullWidth, styles.flexRow)} elevation={0}>
          <Typography component='h2' variant='h2'>
            {guideData ? guideData.heading : <Skeleton height={40} />}
          </Typography>
          <Typography component='p' variant='h2'>
            ID: {guideData ? guideData.friendly_id : <Skeleton height={40} />}
          </Typography>
        </Paper>
        <Paper className={styles.paper} elevation={0}>
          <div className={styles.flexRow}>
            <Typography component='h3' variant='h2'>
              {t(...metadata_messages.metadata)}
            </Typography>
            <ColoredButton customColor='secondary' icon onClick={openMetadataDialog}>
              <EditOutlinedIcon />
            </ColoredButton>
          </div>
          <Typography className={styles.bold}>
            {t(...general_messages.content_type)}: {t(...guide_messages.guide)}
          </Typography>
          {guideData ? (
            <div>
              <Typography>
                {`${t(...metadata_messages.link_image)}: `}
                <Link href={new URL(guideData.image_url, process.env.REACT_APP_API_URL).href} target='blank'>
                  {getFilenameFormPath(guideData.image_url)}
                </Link>
              </Typography>
              <Typography>{`${t(...metadata_messages.language)}: ${guideData.language.label}`}</Typography>
              <Typography>{`${t(...metadata_messages.service_area)}: ${guideData.service_area.name}`}</Typography>
              <Typography>{`${t(...metadata_messages.customer_level)}: ${t(CUSTOMER_LEVELS_ARRAY[guideData.customer_level])}`}</Typography>
              <Typography>{`${t(...metadata_messages.audience)}: ${t(AUDIENCES_ARRAY[guideData.audience])}`}</Typography>
              <Typography>{`${t(...metadata_messages.depth_level)}: ${t(DEEP_LEVELS_ARRAY[guideData.depth_level])}`}</Typography>
              <Typography>{`${t(...metadata_messages.estimated_time)}: ${guideData.estimated_time}`}</Typography>
            </div>
          ) : (
            <Skeleton height={100} variant='rect' width={250} />
          )}
          {guideData ? (
            <Typography>{`${t(...general_messages.tags)}: ${mergeWithCommas(guideData.tags)}`}</Typography>
          ) : (
            <Skeleton width={150} />
          )}
          {guideData ? (
            <Typography>{`${t(...general_messages.related_content)}: ${getRelatedContent(guideData)}`}</Typography>
          ) : (
            <Skeleton width={280} />
          )}
        </Paper>
        <Paper className={styles.paper} elevation={0}>
          <Typography component='h3' variant='h2'>
            {t(...guide_messages.guide_specifics)}
          </Typography>
          <Typography component='h2' variant='h3'>
            {t(...general_messages.description)}
          </Typography>
          <FormGroup className={styles.formGroup}>
            <FormTextInput formik={formik} id='description' label={t(...general_messages.description)} multiline />
            <FormSelect
              disabled={!formik.values.is_incident}
              formik={formik}
              id='incident_report_template'
              label={t(...guide_messages.report_template)}
              options={templatesOptions}
              required={formik.values.is_incident}
            />
            <FormSwitch formik={formik} id='is_incident' label={t(...guide_messages.incident_guide)} />
          </FormGroup>
        </Paper>
        <Paper className={styles.steps} elevation={0}>
          <Typography className={styles.stepsTitle} component='h3' variant='h2'>
            {t(...guide_messages.steps)}
          </Typography>
          <div className={styles.schemaWrapper}>
            {steps ? (
              <GuideSchema reportChange={reportChange} steps={steps} updateSteps={setSteps} />
            ) : (
              <Skeleton height={200} variant='rect' />
            )}
          </div>
        </Paper>
        <div className={clsx(styles.fullWidth, styles.buttons)}>
          <ColoredButton
            customColor='secondary'
            disabled={pristine || sending}
            onClick={save}
            showLoader={sending === SENDING.SAVE}
            variant='outlined'
          >
            {t(...general_messages.guide_buttons.save_draft)}
          </ColoredButton>
          <ColoredButton customColor='secondary' onClick={publish || sending} showLoader={sending === SENDING.PUBLISH} variant='outlined'>
            {t(...general_messages.guide_buttons.publish)}
          </ColoredButton>
        </div>
      </div>
      <MetadataDialog
        id={id}
        initData={guideData}
        onClose={closeMetadataDialog}
        open={!!metadataDialog}
        refresh={getGuideData}
        type={CONTENT_TYPES.GUIDE}
      />
    </Container>
  );
};

export default EditGuidePage;
