import React, { useRef } from 'react';

import { IconButton } from '@material-ui/core';
import { Identifier } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DNDIcon } from 'assets/icons/dnd.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import FormTextInput from 'components/FormTextInput';
import onDNDHover, { DragItem } from 'components/WorkplaceTemplateEditor/_services/onDNDHover';
import { ItemTypes } from 'components/WorkplaceTemplateEditor/WorkplaceTemplateEditor';
import workplace_messages from 'messages/workplace_messages';

import useStyles from './WorkplaceTemplateContent.styles';

type Props = {
  contentId: string;
  formik: any;
  onDelete: () => void;
  onDNDContent: (dragIndex: number, hoverIndex: number) => void;
  index: number;
};

const WorkplaceTemplateContent: React.FC<Props> = ({ onDelete, contentId, formik, onDNDContent, index }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CONTENT,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: (item, monitor) => onDNDHover(item, monitor, { ref, index, onDND: onDNDContent }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CONTENT,
    item: () => ({ id: contentId, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const styles = useStyles({ isDragging });
  return (
    <div ref={ref} className={styles.root} data-handler-id={handlerId}>
      <DNDIcon className={styles.dndIcon} />
      <FormTextInput key={contentId} formik={formik} id={`content.${contentId}`} label={t(workplace_messages.template.contentLabel)} />
      <IconButton onClick={onDelete}>
        <TrashIcon />
      </IconButton>
    </div>
  );
};

export default WorkplaceTemplateContent;
