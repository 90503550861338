import React, { useEffect, useState } from 'react';

import { Container, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import Loader from 'components/Loader';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import articleEndpoints from 'config/api/article';
import hashLinkScroll from 'helpers/hashLinkScroll';
import stripHTMLTags from 'helpers/stripHTMLTags';
import useApiCall from 'hooks/useApiCall';
import useLoadingState from 'hooks/useLoadingState';
import ArticleProvider from 'reactContext/ArticleContext';

import ArticleBody from './_components/ArticleBody';
import ArticleHeader from './_components/ArticleHeader';
import ArticleNavigation from './_components/ArticleNavigation';
import ArticleTags from './_components/ArticleTags';
import RelatedArticles from './_components/RelatedArticles';
import RelatedContent from './_components/RelatedContent';

const useStyles = makeStyles(theme => ({
  article: {
    display: 'grid',
    position: 'relative',
    width: '100%',
    paddingTop: theme.spacing(10),
    margin: '0 auto',
    maxWidth: theme.sizes.container.md,
    gridGap: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0),
      gridGap: theme.spacing(4),
    },

    '&:before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '10px',
      margin: '0 auto',
      top: 0,
      left: '50%',
      transitionDuration: theme.transitions.duration.standard,
      transitionTimingFunction: theme.transitions.easing.easeIn,
      transform: 'translate(-50%, 0)',
      background: theme.palette.primary[100],
      borderBottomLeftRadius: theme.shape.borderRadiusLarge,
      borderBottomRightRadius: theme.shape.borderRadiusLarge,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  contentGrid: {
    display: 'grid',
    gridGap: theme.spacing(8),
    gridTemplateColumns: `${theme.sizes.article.body}ch ${theme.sizes.article.navigation}ch`,
    justifyContent: 'center',
    [`@media screen and (max-width: 1470px)`]: {
      gridTemplateColumns: `${theme.sizes.article.body * 0.9}ch ${theme.sizes.article.navigation * 0.8}ch`,
    },
    [theme.breakpoints.down('md')]: {
      gridGap: theme.spacing(6),
    },
    [`@media screen and (max-width: ${theme.customBreakpoints.articleNavigation}px)`]: {
      gridTemplateColumns: '1fr',
      maxWidth: `${theme.sizes.article.body}ch`,
      margin: '0 auto',
    },

    '& .article-body1': {
      fontFamily: theme.typography.fontFamilyArticle,
      paddingBottom: theme.spacing(0.75),
    },
  },
  title: {
    maxWidth: `30ch`,
    margin: '0 -.5ch',
    [`@media screen and (max-width: ${theme.customBreakpoints.articleNavigation}px)`]: {
      margin: '0 auto',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      margin: '0 -5px',
    },
  },
}));

const SingleArticlePage = () => {
  const { id } = useParams();
  const { apiCall } = useApiCall();
  const { loading, setLoaded } = useLoadingState(true);
  const [articleData, setArticleData] = useState(null);
  const theme = useTheme();
  const showNavigation = useMediaQuery(`@media screen and (min-width: ${theme.customBreakpoints.articleNavigation}px)`);
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  const getArticleData = async () => {
    const { data } = await apiCall(articleEndpoints.get(id));
    setArticleData(data);
    setLoaded();
    hashLinkScroll();
  };

  useEffect(() => {
    getArticleData();
  }, [id]);

  const styles = useStyles();
  if (loading) return <Loader color='primary' inner />;
  const { body, teaser, heading, image_url, estimated_time, is_saved } = articleData;
  const hasRelatedArticles = () => {
    if (!articleData?.related_articles) return false;
    const { related_articles } = articleData;
    return Array.isArray(related_articles) && related_articles.length;
  };

  return (
    <ArticleProvider>
      <Container className={styles.article} disableGutters={!upMd}>
        <MobileGuttersContainer>
          <Typography className={styles.title} component='h1' variant='h1'>
            {stripHTMLTags(heading)}
          </Typography>
        </MobileGuttersContainer>
        <div>
          <div className={styles.contentGrid}>
            <ArticleHeader estimated_time={estimated_time} id={id} is_saved={is_saved} teaser={teaser} />
          </div>
          <MobileGuttersContainer>
            <div className={styles.contentGrid}>
              <ArticleBody bodyStream={body} image_url={image_url} />
              {showNavigation && body && <ArticleNavigation bodyStream={body} />}
            </div>
          </MobileGuttersContainer>
        </div>
        <MobileGuttersContainer>
          {/* TODO: use in next iteration */}
          {/* <div className={styles.contentGrid}> */}
          {/*  <RateArticle id={id} /> */}
          {/* </div> */}
          <div className={styles.contentGrid}>
            <ArticleTags tags={articleData?.tags} />
          </div>
        </MobileGuttersContainer>
      </Container>
      <RelatedContent guides={articleData.related_guides} />
      {hasRelatedArticles() ? <RelatedArticles articles={articleData.related_articles} innerClassName={styles.contentGrid} /> : null}
    </ArticleProvider>
  );
};

export default SingleArticlePage;
