import React from 'react';

import { intersection } from 'lodash';
import { useTranslation } from 'react-i18next';

import MenuList from 'components/Layout/_components/MenuList';
import MenuSection from 'components/Layout/_components/MenuSection';
import ROLES from 'config/constants/ROLES';
import menu_messages from 'messages/menu_messages';
import { COMMON_DIALOG_TYPES } from 'reactContext/CommonDialogsContext';
import PATHS from 'router/PATHS';
import userDataStorage from 'storages/userDataStorage';

const { ORGANIZATION } = ROLES;

const usedRoles = [
  ORGANIZATION.INCIDENT_MANAGER,
  ORGANIZATION.USER_ADMINISTRATOR,
  ORGANIZATION.SECURITY_OFFICER,
  ORGANIZATION.ACCOUNT_OWNER,
];

const OrganizationSection = () => {
  const { t } = useTranslation();
  const { roles, workplace_id } = userDataStorage.get() || {};
  if (!intersection(usedRoles, roles).length) return null;

  const elements = [
    { label: t(...menu_messages.workplaces), to: workplace_id ? `${PATHS.WORKPLACES}/${workplace_id}` : PATHS.WORKPLACES },
    { label: t(...menu_messages.incident_management), to: PATHS.INCIDENT_REPORTS },
    { label: t(...menu_messages.coworkers), to: PATHS.USERS },
    { label: t(...menu_messages.book_meeting), to: PATHS.BOOK_MEETING },
    { label: t(...menu_messages.ask_question), dialogType: COMMON_DIALOG_TYPES.ASK_QUESTION },
    { label: t(...menu_messages.meeting_advices), to: PATHS.MEETING_ADVICES },
    { label: t(...menu_messages.organization_administration), to: PATHS.ORGANIZATION_ADMINISTRATION },
  ];

  return (
    <MenuSection title={t(...menu_messages.organization)}>
      <MenuList elements={elements} />
    </MenuSection>
  );
};

export default OrganizationSection;
