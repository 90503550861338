import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import MenuList from 'components/Layout/_components/MenuList';
import MenuSection from 'components/Layout/_components/MenuSection';
import commonEndpoints from 'config/api/common';
import useApiCall from 'hooks/useApiCall';
import menu_messages from 'messages/menu_messages';
import PATHS from 'router/PATHS';

const ServiceSection = () => {
  const { t } = useTranslation();
  const { apiCall } = useApiCall();
  const [elements, setElements] = useState([]);

  const getData = async () => {
    const { data, status } = await apiCall(commonEndpoints.serviceAreas());
    if (status < 300) {
      setElements(data.map(({ id, name }) => ({ label: name, to: `${PATHS.CONTENT_BY_SERVICE_AREA}/${id}` })));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (!elements.length) return null;
  return (
    <MenuSection title={t(...menu_messages.service)}>
      <MenuList elements={elements} />
    </MenuSection>
  );
};

export default ServiceSection;
