import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button, ButtonBase, Typography } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import MUTATION_KEYS from 'config/api/MUTATION_KEYS';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { usersApi } from 'config/api/users';
import EVENTS from 'config/events/pubsub';
import general_messages from 'messages/general_messages';
import personal_info_messages from 'messages/personal_info_messages';
import userDataStorage from 'storages/userDataStorage';

import useStyles from './PictureDialog.styles';

const MIN_SCALE = 1;
const MAX_SCALE = 2;

const PictureDialog = ({ onClose, open, initialPicture }) => {
  const queryClient = useQueryClient();
  const { id: userId } = userDataStorage.get() || {};

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const inputRef = useRef();
  const editorRef = useRef();
  const [imageSrc, setImageSrc] = useState(initialPicture);
  const [fromBE, setFromBe] = useState(!!initialPicture);

  const [scaleSlider, setScaleSlider] = useState(0);
  const [rotateSlider, setRotateSlider] = useState(0);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const onSuccess = () => {
    enqueueSnackbar(t(...general_messages.data_saved), { variant: 'success' });
    PubSub.publish(EVENTS.USER_PROFILE_UPDATED);
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_USER_DATA, userId] });
    onClose();
  };

  const updatePictureMutation = useMutation(MUTATION_KEYS.UPDATE_PICTURE, usersApi.updateCurrentUserPicture, { onSuccess });
  const deletePictureMutation = useMutation(MUTATION_KEYS.DELETE_PICTURE, usersApi.deleteCurrentUserPicture, { onSuccess });

  const onScaleSliderChange = (e, newValue) => setScaleSlider(newValue);

  const onRotateSliderChange = (e, newValue) => setRotateSlider(newValue);

  useEffect(() => {
    const newScale = MIN_SCALE + scaleSlider * 0.01 * MAX_SCALE;
    setScale(newScale);
  }, [scaleSlider]);

  useEffect(() => {
    const newScale = rotateSlider * 3.6;
    setRotate(newScale);
  }, [rotateSlider]);

  const openFilePicker = () => inputRef.current.click();

  const clearPicture = () => {
    setFromBe(false);
    setImageSrc(undefined);
    inputRef.current.value = null;
  };

  const onChange = e => {
    setFromBe(false);
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    setScaleSlider(0);
    setRotateSlider(0);
  };

  const onSave = () => {
    if (imageSrc) {
      editorRef.current.getImageScaledToCanvas().toBlob(blob => {
        const formData = new FormData();

        const data = new File([blob], `profilePicture.png`, {
          type: 'image/png',
        });

        formData.append('data', data);
        updatePictureMutation.mutate(formData);
      });
    } else {
      deletePictureMutation.mutate();
    }
  };

  const disabled = useMemo(
    () => updatePictureMutation.isLoading || deletePictureMutation.isLoading,
    [updatePictureMutation.isLoading, deletePictureMutation.isLoading],
  );

  const styles = useStyles();

  const renderEditor = () => {
    if (fromBE && imageSrc)
      return (
        <>
          <img alt='user avatar' className={styles.image} src={imageSrc} />
          <div className={styles.settings}>
            {imageSrc && (
              <Typography className={styles.textButton} color='primary' component='button' onClick={openFilePicker} type='button'>
                {t(...personal_info_messages.upload_new_picture)}
              </Typography>
            )}
            {imageSrc && (
              <Typography className={styles.textButton} color='primary' component='button' onClick={clearPicture} type='button'>
                {t(...personal_info_messages.delete_picture)}
              </Typography>
            )}
          </div>
        </>
      );
    if (imageSrc)
      return (
        <>
          <AvatarEditor
            ref={editorRef}
            border={20}
            borderRadius={100}
            color={[255, 255, 255, 0.6]}
            crossOrigin='anonymous'
            height={200}
            image={imageSrc}
            rotate={rotate}
            scale={scale}
            width={200}
          />
          <div className={styles.settings}>
            <div className={styles.settingsRow}>
              <Typography>{t(...personal_info_messages.picture_scale)}:</Typography>
              <Slider disabled={disabled} onChange={onScaleSliderChange} value={scaleSlider} />
            </div>
            <div className={styles.settingsRow}>
              <Typography>{t(...personal_info_messages.picture_rotate)}:</Typography>
              <Slider disabled={disabled} onChange={onRotateSliderChange} value={rotateSlider} />
            </div>
            {imageSrc && (
              <Typography className={styles.textButton} color='primary' component='button' onClick={openFilePicker} type='button'>
                {t(...personal_info_messages.upload_new_picture)}
              </Typography>
            )}
            {imageSrc && (
              <Typography className={styles.textButton} color='primary' component='button' onClick={clearPicture} type='button'>
                {t(...personal_info_messages.delete_picture)}
              </Typography>
            )}
          </div>
        </>
      );
    return (
      <ButtonBase className={styles.addButton} onClick={openFilePicker}>
        <PersonAddIcon color='secondary' fontSize='large' />
        <Typography variant='button'>{t(...personal_info_messages.upload_picture)}</Typography>
      </ButtonBase>
    );
  };

  return (
    <DialogGrid
      dialogActions={
        <CenteredGrid gridGap={2} withoutPadding>
          <ColoredButton customColor='secondary' disabled={disabled} onClick={onSave} variant='outlined'>
            {t(...general_messages.save)}
          </ColoredButton>
          <Button onClick={onClose} variant='outlined'>
            {t(...general_messages.cancel)}
          </Button>
        </CenteredGrid>
      }
      onClose={onClose}
      open={open}
      // sending={sending}
      title={t(...personal_info_messages.edit_picture_title)}
    >
      <div className={styles.mainContent}>{renderEditor()}</div>
      <input
        ref={inputRef}
        accept='image/png, image/jpg, image/jpeg'
        className={styles.input}
        multiple={false}
        onChange={onChange}
        type='file'
      />
    </DialogGrid>
  );
};

PictureDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default PictureDialog;
