import React, { createContext, useState, useContext, useRef } from 'react';

import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-cycle
import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import ColoredButton from 'components/ColoredButton';
import general_messages from 'messages/general_messages';

const ConfirmationModalContext = createContext({});

const { Provider } = ConfirmationModalContext;

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: theme.sizes.button.narrow,
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(3),
    width: '100%',
  },
}));

const ConfirmationModalProvider = ({ children }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [confirmationOptions, setConfirmationOptions] = useState({});

  const awaitingConfirmation = useRef();

  const closeModal = () => {
    setOpen(false);
    setConfirmationOptions({});
  };

  const showConfirmationModal = options => {
    setOpen(true);
    setConfirmationOptions(options);
    return new Promise(resolve => {
      awaitingConfirmation.current = { resolve };
    });
  };

  const onCancel = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(false);
    }

    closeModal();
  };

  const onConfirm = () => {
    if (awaitingConfirmation.current) {
      awaitingConfirmation.current.resolve(true);
    }

    closeModal();
  };

  const styles = useStyles();
  return (
    <Provider value={{ showConfirmationModal }}>
      {children}
      <DialogGrid
        dialogActions={
          <div className={styles.wrapper}>
            <Button className={styles.button} onClick={onCancel} variant='outlined'>
              {t(...general_messages.no)}
            </Button>
            <ColoredButton className={styles.button} customColor='secondary' onClick={onConfirm} variant='outlined'>
              {t(...general_messages.yes)}
            </ColoredButton>
          </div>
        }
        onClose={onCancel}
        open={open}
        title={confirmationOptions.title}
      >
        {confirmationOptions.body}
      </DialogGrid>
    </Provider>
  );
};

ConfirmationModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useConfirmationModalContext = () => useContext(ConfirmationModalContext);

export default ConfirmationModalProvider;
