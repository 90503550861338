import React from 'react';

import { ButtonBase, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { contentTypeResolver } from 'config/translatableConstants/TRANSLATABLE_CONTENT_TYPES';
import PATHS from 'router/PATHS';

import useStyles from './GuideTile.styles';

const GuideTileLoader = () => {
  const styles = useStyles();

  return (
    <ButtonBase className={styles.tile} color='secondary'>
      <Skeleton height='2rem' width='60%' />
      <Skeleton height='4rem' width='100%' />
      <div className={styles.footer}>
        <Skeleton height='1.35rem' width='40%' />
        <Skeleton height='1.35rem' width='10%' />
      </div>
    </ButtonBase>
  );
};

const GuideTile = ({
  title,
  description,
  language,
  service_area,
  id,
  metadata,
  object_content_type,
  darker,
  heading,
  onClose,
  minimal,
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  const url = `${PATHS.GUIDES}/${id}/${metadata?.version_id || metadata?.versionId}?redirect_url=${location.pathname}`;
  const styles = useStyles({ darker });

  return (
    <ButtonBase className={styles.tile} color='secondary' component={Link} onClick={onClose} to={url}>
      <Typography align='left' component='h2' variant='h3'>
        {title || heading}
      </Typography>
      <Typography align='left' component='p' variant='body1'>
        {description}
      </Typography>
      {!minimal && (
        <div className={styles.footer}>
          <Typography className={styles.footerFont} component='span'>{`${t(...contentTypeResolver(object_content_type))}: ${t(
            service_area.name_translation_key,
            service_area.name,
          )}`}</Typography>
          <Typography className={styles.footerFont} component='span'>
            {language.language_code}
          </Typography>
        </div>
      )}
    </ButtonBase>
  );
};

export { GuideTileLoader };
export default GuideTile;
