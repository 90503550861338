import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import PasswordChangeForm from 'components/PasswordChangeForm';
import SimpleFormLayout from 'components/SimpleFormLayout';
import authEndpoints from 'config/api/auth';
import useApiCall from 'hooks/useApiCall';
import auth_messages from 'messages/auth_messages';
import general_messages from 'messages/general_messages';
import PATHS from 'router/PATHS';

const FORM_ID = 'PasswordResetForm';

const PasswordResetForm = ({ activation }) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(null);

  const { apiCall } = useApiCall();
  const { uid, token } = useParams();
  const history = useHistory();

  const onSubmit = ({ new_password, re_new_password }) => {
    const url = activation ? authEndpoints.activation() : authEndpoints.reset_password();
    return apiCall(url, { data: { new_password, re_new_password, uid, token } });
  };

  const onSuccess = () => history.push(PATHS.AUTH_PASSWORD_CHANGED);

  const afterSubmit = ({ status }) => {
    let error;
    if (status >= 300) error = t(...general_messages.api_error);
    return { error, onSuccess };
  };

  if (!uid || !token) history.push(PATHS.AUTH);

  return (
    <SimpleFormLayout
      disabled={isValid}
      FORM_ID={FORM_ID}
      isForm={false}
      submitLabel={activation ? t(...auth_messages.verify_account) : t(...auth_messages.request)}
      title={activation ? t(...auth_messages.account_activation) : t(...auth_messages.reset_password)}
    >
      <PasswordChangeForm afterSubmit={afterSubmit} byToken FORM_ID={FORM_ID} onValidationChange={setIsValid} updateFunction={onSubmit} />
    </SimpleFormLayout>
  );
};

PasswordResetForm.propTypes = {
  activation: PropTypes.bool,
};

PasswordResetForm.defaultProps = {
  activation: false,
};

export default PasswordResetForm;
