import React, { useEffect, useRef, useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { isNil } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import FormFileInput from 'components/FormFileInput';
import usersEndpoints from 'config/api/users';
import { logObjects } from 'config/constants/logs';
import useApiCall from 'hooks/useApiCall';
import useLoadingState from 'hooks/useLoadingState';
import general_messages from 'messages/general_messages';
import title_messages from 'messages/title_messages';
import user_messages from 'messages/user_messages';
import validation_messages from 'messages/validation_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext';
import logEvent from 'services/logEvent';
import userDataStorage from 'storages/userDataStorage';

const FORM_ID = 'user_file_form';

const useStyles = makeStyles(theme => ({
  instructions: {
    marginBottom: theme.spacing(3),
  },
  error: {
    padding: theme.spacing(2, 0),
    minHeight: '54px',
    textAlign: 'center',
  },
}));

const REFRESH_INTERVAL = 250;

const AddNewUserFromFileDialog = ({ onClose, open, refreshData }) => {
  const { t } = useTranslation();

  const { apiCall, getCancelToken } = useApiCall();
  const [error, setError] = useState(false);
  const { loading, setLoading, setLoaded } = useLoadingState(false);
  const tokenSource = getCancelToken();
  const snackbar = useSnackbar();
  const { showConfirmationModal } = useConfirmationModalContext();

  const timeoutRef = useRef();
  const clearRefTimeout = () => clearTimeout(timeoutRef.current);
  const [progress, setProgress] = useState();
  const [uploadId, setUploadId] = useState();
  const checkProgress = async () => {
    const { data, status } = await apiCall(usersEndpoints.checkUploadProgress(uploadId));
    if (status >= 400 || !data) {
      snackbar.enqueueSnackbar(t(...general_messages.something_went_wrong), { variant: 'error' });
      clearRefTimeout();
      return;
    }
    if (data.task_info.state === 'SUCCESS') {
      const { new: created, skip: skipped, error: uploadError, invalid } = data.task_info.result.totals;

      clearRefTimeout();
      setProgress(100);
      setTimeout(() => {
        onClose();
        refreshData();
        snackbar.enqueueSnackbar(t(...user_messages.upload_summary, { created, skipped, invalid: invalid + uploadError }), {
          variant: 'success',
        });
      }, 500);
    } else {
      const { current, total } = data.task_info.result;
      setProgress(Math.floor((current / total) * 100));
      timeoutRef.current = setTimeout(checkProgress, REFRESH_INTERVAL);
    }
  };
  const observeProgress = () => {
    setProgress(0);
    timeoutRef.current = setTimeout(checkProgress, REFRESH_INTERVAL);
  };
  useEffect(() => {
    if (uploadId) observeProgress();
    return clearRefTimeout;
  }, [uploadId]);

  const onSubmit = async ({ file }) => {
    setLoading();
    setError(false);
    const formData = new FormData();
    formData.append('file', file);
    // /* eslint-disable @typescript-eslint/no-use-before-define */
    const { status, data } = await apiCall(
      usersEndpoints.addByFileUpload(userDataStorage.get().organization),
      { data: formData },
      { tokenSource },
    );
    setUploadId(data.task_id);
    if (status >= 400) {
      setError(true);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      formik.setFieldValue('file', '');
    }
    setLoaded();
  };

  const formik = useFormik({
    initialValues: {
      file: '',
    },
    validateOnMount: true,
    validationSchema: yup.object({
      file: yup.string().required(t(...validation_messages.required)),
    }),
    onSubmit,
  });

  const beforeOnClose = async () => {
    if (!isNil(progress)) {
      const confirmed = await showConfirmationModal({
        title: t(...user_messages.abort_uploading_confirm_title),
        body: t(...user_messages.abort_uploading_confirm),
      });
      if (confirmed) {
        tokenSource.cancel();
        onClose();
      }
    } else {
      onClose();
    }
  };

  useEffect(() => {
    logEvent(logObjects.UPLOAD_USER_FILE);
  }, []);

  const styles = useStyles();
  return (
    <DialogGrid onClose={beforeOnClose} open={open} title={t(...title_messages.upload_user_file)}>
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        <CenteredGrid title={t(...title_messages.add_attachment)} width='sm'>
          <FormFileInput
            acceptedTypes={['.scsv', '.csv', '.txt']}
            formik={formik}
            id='file'
            label={t(...general_messages.attach_file)}
            progress={progress}
            required
          />
        </CenteredGrid>
        <CenteredGrid title={t(...user_messages.file_formatting)} withoutPadding>
          <Typography className={styles.instructions}>{t(...user_messages.file_formatting_instruction)}</Typography>
          <Typography>{t(...user_messages.file_example)}:</Typography>
          <Typography>{t(...user_messages.file_example_content)}</Typography>
        </CenteredGrid>
        <Typography className={styles.error} color='error'>
          {error && t(...user_messages.incorrect_file)}
        </Typography>
        <CenteredGrid withoutPadding>
          <ColoredButton customColor='secondary' disabled={!formik.isValid} showLoader={loading} type='submit' variant='outlined'>
            {t(...general_messages.add)}
          </ColoredButton>
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

AddNewUserFromFileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
};

export default AddNewUserFromFileDialog;
