import React, { useEffect } from 'react';

import { useMediaQuery, ButtonBase } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as LogoFull } from 'assets/logo_light.svg';
import { ReactComponent as LogoSymbol } from 'assets/logo_symbol.svg';
import MenuSection from 'components/Layout/_components/MenuSection';
import ContentSection from 'components/Layout/_components/sections/ContentSection';
import FooterSection from 'components/Layout/_components/sections/FooterSection';
import OrganizationSection from 'components/Layout/_components/sections/OrganizationSection';
import PocketSafeAdministrationSection from 'components/Layout/_components/sections/PocketSafeAdministrationSection';
import ServiceSection from 'components/Layout/_components/sections/ServiceSection';
import UserSection from 'components/Layout/_components/sections/UserSection';
import { useAuthContext } from 'reactContext/AuthContext';
import PATHS from 'router/PATHS';
// eslint-disable-next-line import/extensions
import useBrandingStore from 'storages/brandingStore.ts';
import useLayoutStore from 'storages/layoutStore';
import userDataStorage from 'storages/userDataStorage';
import { DRAWER_WIDTH } from 'styles/consts';

const useStyles = makeStyles(theme => ({
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  drawer: {
    width: DRAWER_WIDTH.OPEN,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: theme.palette.background.dark,
    color: theme.palette.text.inverted,
    overflowX: 'hidden',
  },
  drawerInner: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  drawerMainSection: {
    flexGrow: '1',
    marginBottom: '-2px',
  },
  drawerOpen: {
    width: DRAWER_WIDTH.OPEN,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: DRAWER_WIDTH.CLOSED,
  },

  icon: {
    color: theme.palette.text.inverted,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'sticky',
    top: 0,
    background: theme.palette.background.dark,
    zIndex: 1,
    flexDirection: ({ isOpen }) => (isOpen ? 'row' : 'column'),
  },
  logo: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  logoFull: {
    width: '102px',
    height: '16px',
    animationName: '$fadeIn',
    animationDuration: 300,
  },
  logoSymbol: {
    animationName: '$fadeIn',
    animationDuration: 300,
  },
  searchButton: {
    order: ({ isOpen }) => (isOpen ? '2' : '1'),
  },
}));

const FullLogoWithFallback = ({ className, logo }) => {
  if (logo?.full) return <img alt='' className={className} src={logo.full} />;
  if (logo?.symbol) return <img alt='' className={className} src={logo.symbol} />;
  return <LogoFull className={className} />;
};

const SymbolLogoWithFallback = ({ className, logo }) => {
  if (logo?.symbol) return <img alt='' className={className} src={logo.symbol} />;
  if (logo?.full) return <img alt='' className={className} src={logo.full} />;
  return <LogoSymbol className={className} />;
};

const MainMenuDesktop = () => {
  const { logo } = useBrandingStore();
  const userData = userDataStorage.get();
  const { isAuthorized } = useAuthContext();
  const downLg = useMediaQuery('@media screen and (max-width: 1200px)');

  const { isMenuOpen, closeMenu, toggleMenu } = useLayoutStore();

  useEffect(() => {
    if (downLg) closeMenu();
  }, [downLg]);

  const styles = useStyles({ isOpen: isMenuOpen });
  return (
    <Drawer
      classes={{
        paper: clsx(
          {
            [styles.drawerOpen]: isMenuOpen,
            [styles.drawerClose]: !isMenuOpen,
          },
          styles.drawer,
        ),
      }}
      className={clsx(styles.drawer, {
        [styles.drawerOpen]: isMenuOpen,
        [styles.drawerClose]: !isMenuOpen,
      })}
      variant='permanent'
    >
      <div className={styles.drawerInner}>
        <div className={styles.drawerMainSection}>
          <MenuSection className={styles.toolbar} collapsible={false} keepVisible>
            <ButtonBase className={styles.logo} component='a' href={process.env.REACT_APP_LANDING_PAGE_URL} target='_blank'>
              {isMenuOpen ? (
                <FullLogoWithFallback className={styles.logoFull} logo={logo} />
              ) : (
                <SymbolLogoWithFallback className={clsx('MuiSvgIcon-root', styles.logoSymbol, styles.icon)} logo={logo} />
              )}
            </ButtonBase>
            <IconButton className={styles.searchButton} component={Link} to={PATHS.GLOBAL_SEARCH}>
              <SearchIcon className={styles.icon} />
            </IconButton>
            <IconButton data-cy='menu_hamburger' onClick={toggleMenu}>
              <MenuIcon className={styles.icon} />
            </IconButton>
          </MenuSection>
          <div>
            <UserSection />
            {isAuthorized && userData?.hasPaymentMethod && (
              <>
                <ServiceSection />
                <OrganizationSection />
                <ContentSection />
                <PocketSafeAdministrationSection />
              </>
            )}
          </div>
        </div>
        <FooterSection />
      </div>
    </Drawer>
  );
};

export default MainMenuDesktop;
