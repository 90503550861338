import axios from 'axios';

import createUrlWithParams from '../../helpers/createUrlWithParams';

import METHOD from './_methods';

const incidentReportsEndpoints = {
  /**
   * pass guide_progress_id
   * @returns {{method: string, url: string}}
   */
  removeAttachment: attachment_id => ({ url: `api/v1/attachments/${attachment_id}/`, method: METHOD.DELETE }),
  createFromGuide: () => ({ url: '/api/v1/incident_manager/reports/', method: METHOD.POST }),
  getAll: () => ({ url: '/api/v1/incident_manager/reports/', method: METHOD.GET }),
  getOne: report_id => ({ url: `/api/v1/incident_manager/reports/${report_id}/`, method: METHOD.GET }),
  update: report_id => ({ url: `/api/v1/incident_manager/reports/${report_id}/`, method: METHOD.PUT }),
  updateFields: report_id => ({ url: `/api/v1/incident_manager/reports/${report_id}/fields/`, method: METHOD.PATCH }),
  goToNextPhase: report_id => ({ url: `/api/v1/incident_manager/reports/${report_id}/next_phase/`, method: METHOD.POST }),
  addAttachment: report_id => ({ url: `/api/v1/incident_manager/reports/${report_id}/add_attachment/`, method: METHOD.POST }),

  // PS adviser
  getAllAsAdviser: () => ({ url: '/api/v1/adviser/reports/', method: METHOD.GET }),
  getOneAsAdviser: report_id => ({ url: `/api/v1/adviser/reports/${report_id}/`, method: METHOD.GET }),
  createFromGuideAsAdviser: () => ({ url: '/api/v1/adviser/reports/', method: METHOD.POST }),

  // active user
  getAllAsActiveUser: () => ({ url: '/api/v1/active_user/reports/', method: METHOD.GET }),
  getOneAsActiveUser: report_id => ({ url: `/api/v1/active_user/reports/${report_id}/`, method: METHOD.GET }),
  updateAsActiveUser: report_id => ({ url: `/api/v1/active_user/reports/${report_id}`, method: METHOD.PUT }),
  createFromGuideAsActiveUser: () => ({ url: '/api/v1/active_user/reports/', method: METHOD.POST }),
  updateFieldsAsActiveUser: report_id => ({ url: `/api/v1/active_user/reports/${report_id}/fields/`, method: METHOD.PATCH }),
  goToNextPhaseAsActiveUser: report_id => ({ url: `/api/v1/active_user/reports/${report_id}/next_phase/`, method: METHOD.POST }),
  addAttachmentAsActiveUser: report_id => ({ url: `/api/v1/active_user/reports/${report_id}/add_attachment/`, method: METHOD.POST }),

  // incident manager
  getAllAsIncidentManager: () => ({ url: '/api/v1/incident_manager/reports/', method: METHOD.GET }),
  getAllAsIncidentManagerWithParams: params => ({
    url: createUrlWithParams('/api/v1/incident_manager/reports/', params),
    method: METHOD.GET,
  }),
  getAllAsIncidentManagerWithFilters: params => ({ url: createUrlWithParams('/api/v1/reports/search', params), method: METHOD.POST }),
  getOneAsIncidentManager: report_id => ({ url: `/api/v1/incident_manager/reports/${report_id}/`, method: METHOD.GET }),
  createFromGuideAsIncidentManager: () => ({ url: '/api/v1/incident_manager/reports/', method: METHOD.POST }),
  updateAsIncidentManager: report_id => ({ url: `/api/v1/incident_manager/reports/${report_id}/`, method: METHOD.PUT }),
  updateFieldsAsIncidentManager: report_id => ({ url: `/api/v1/incident_manager/reports/${report_id}/fields/`, method: METHOD.PATCH }),
  goToNextPhaseAsIncidentManager: report_id => ({ url: `/api/v1/incident_manager/reports/${report_id}/next_phase/`, method: METHOD.POST }),
  addAttachmentAsIncidentManager: report_id => ({
    url: `/api/v1/incident_manager/reports/${report_id}/add_attachment/`,
    method: METHOD.POST,
  }),

  // REPORT LOGS
  getReportLogsAsIncidentManager: report_id => ({ url: `api/v1/incident_manager/reports/${report_id}/manage_logs/`, method: METHOD.GET }),
  addReportLog: () => ({ url: 'api/v1/incident_manage_logs/', method: METHOD.POST }),
};

export const incidentReportsApi = {
  getReportsKPI: async () => {
    const { data } = await axios.get('/api/v1/reports/kpi/');
    return data;
  },
};

// @ts-ignore
export default incidentReportsEndpoints;
