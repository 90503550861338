import React, { useMemo } from 'react';

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { FormikProps } from 'formik';
import { get, isNil } from 'lodash';
import { TFunction, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

type Props = {
  dataSource: {
    sourceFunction: () => Promise<any>;
    dictionaryKey?: string;
    parser?: (data: any, t: TFunction) => { key: string | number; label: string }[];
  };
  staleTime?: number;
  required?: boolean;
  id: string;
  label: string;
  className?: string;
  disabled?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  color?: 'primary' | 'secondary';
  formik: FormikProps<any>;
};

type Data = { key: string | number; label: string }[];

const defaultParser = (data: Data) => data.map(({ label, key }) => ({ key, label }));

const FormDynamicSelect: React.FC<Props> = ({
  className,
  formik,
  id,
  label,
  disabled,
  variant,
  color,
  required,
  staleTime,
  dataSource,
}) => {
  const { t } = useTranslation();
  const { touched, errors, handleChange, values } = formik;
  const value = get(values, id);
  const error = get(errors, id);
  const isTouched = get(touched, id);

  const setTouched = () => {
    formik.setFieldTouched(id, true);
  };

  const { data } = useQuery(dataSource.dictionaryKey || `FormDynamicSelect-${id} options query`, dataSource.sourceFunction, {
    refetchOnWindowFocus: false,
    staleTime: isNil(staleTime) ? 60 * 60 * 1000 : staleTime,
  });

  const options = useMemo(() => {
    if (!data) return [];
    if (!dataSource.parser) return defaultParser(data.data);
    return dataSource.parser(data.data, t);
  }, [data]);

  const unifiedLabel = required ? `${label} * ` : label;

  return (
    <FormControl className={className} error={isTouched && Boolean(error)} variant={variant || 'outlined'}>
      <InputLabel id={`${id}-label`}>{unifiedLabel}</InputLabel>
      <Select
        color={color || 'primary'}
        disabled={disabled || !options.length}
        error={isTouched && Boolean(error)}
        id={id}
        label={unifiedLabel}
        labelId={`${id}-label`}
        onBlur={setTouched}
        onChange={handleChange(id)}
        value={value}
      >
        {options.map(({ key, label: optionLabel }) => (
          <MenuItem key={key} value={key}>
            {optionLabel}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error>{isTouched ? (error as string) : null}</FormHelperText>
    </FormControl>
  );
};

export default FormDynamicSelect;
