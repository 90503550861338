const table_messages = {
  no_data_cell: ['table>>no_data_cell', '-'],
  no_data_table: ['table>>no_data_table', 'No content to display'],
  last_edit_date_shortcut: ['table>>last_edit_date_shortcut', 'LE'],
  creation_date_shortcut: ['table>>creation_date_shortcut', 'CR'],
  author: ['table>>author', 'Author'],
  titleH1: ['table>>titleH1', 'H1'],
  date: ['table>>date', 'Date'],
  service_area: ['table>>service_area', 'Service Area'],
  audience: ['table>>audience', 'Audience'],
  name: ['table>>name', 'Name'],
  email: ['table>>email', 'Email'],
  last_login: ['table>>last_login', 'Last login'],
  status: ['table>>status', 'Status'],
  roles: ['table>>roles', 'Roles'],
  type: ['table>>type', 'Type'],
  guide_type: ['table>>guide_type', 'Guide type'],
  start: ['table>>start', 'Start'],
  customer_user_name: ['table>>customer_user_name', 'Customer username'],
  customer_organization_name: ['table>>customer_organization_name', 'Customer org. name'],
  advisor: ['table>>advisor', 'Advisor'],
  not_edited: ['table>>not_edited', 'Not edited in {{days}} days'],
  is_gdpr: ['table>>is_gdpr', 'Is GDPR'],
  is_ongoing: ['table>>is_ongoing', 'Is ongoing'],
  priority: ['table>>priority', 'Priority'],
  phase: ['table>>phase', 'Phase'],
};

export default table_messages;
