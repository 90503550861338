import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import PUBLISH_STATUSES from 'config/constants/PUBLISH_STATUSES';
import ensureIsArray from 'helpers/ensureIsArray';

const getRelatedContent = (data, type) => {
  const array = ensureIsArray(data);
  return array.filter(({ object_content_type }) => object_content_type === type).map(({ key }) => key);
};

const parseMetadataForRequest = (data, FORM) => {
  const finalData = { ...data };
  const { TAGS, RELATED_CONTENT, IS_ACTIVE } = FORM;

  finalData[TAGS] = finalData[TAGS] ? finalData[TAGS].map(({ key }) => key) : [];
  finalData.related_articles = getRelatedContent(data[RELATED_CONTENT], CONTENT_TYPES.ARTICLE);
  finalData.related_guides = getRelatedContent(data[RELATED_CONTENT], CONTENT_TYPES.GUIDE);
  finalData[IS_ACTIVE] = finalData[FORM.IS_ACTIVE] ? PUBLISH_STATUSES.PUBLISHED : PUBLISH_STATUSES.INACTIVE;
  return finalData;
};

export default parseMetadataForRequest;
