const EVENTS = {
  ACTIVITIES_UPDATED: 'ACTIVITIES_UPDATED',
  MEETINGS_UPDATED: 'MEETINGS_UPDATED',
  AVAILABILITY_UPDATED: 'AVAILABILITY_UPDATED',
  INCIDENT_REPORTS_UPDATED: 'INCIDENT_REPORTS_UPDATED',
  ORGANIZATION_DATA_UPDATED: 'ORGANIZATION_DATA_UPDATED',
  API_MUTATION_ERROR: 'API_MUTATION_ERROR',
  API_FETCH_ERROR: 'API_FETCH_ERROR',
  USER_PROFILE_UPDATED: 'USER_PROFILE_UPDATED',
  CONTEXT_MENU_OPENED: 'CONTEXT_MENU_OPENED',
};

export default EVENTS;
