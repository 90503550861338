import axios from 'axios';

import PATHS from 'router/PATHS';
import accessTokenStorage from 'storages/accessTokenStorage';
import refreshTokenStorage from 'storages/refreshTokenStorage';

import authEndpoints from './api/auth';
import logEndpoints from './api/log';
import instances from './constants/instances';

const isProduction = process.env.REACT_APP_INSTANCE === instances.PRODUCTION;
const urlsWithoutRefresh = [authEndpoints.login().url, logEndpoints.logEndpoint];

// let refreshTimeout;
let refreshing = false;
let lastRefresh = new Date().getTime();

export const refreshToken = async () => {
  const refresh = refreshTokenStorage.get();
  if (!refresh || refreshing) return null;
  if (new Date().getTime() - lastRefresh < 100) return null;
  lastRefresh = new Date().getTime();
  refreshing = true;
  const { data } = await axios({ ...authEndpoints.refresh(), data: { refresh } });

  accessTokenStorage.set(data.access);
  refreshTokenStorage.set(data.refresh);
  // use that if persist auth will be needed
  //   const userDataFormToken = jwtDecode(data.access);
  //   const expiryDate = new Date(userDataFormToken.exp * 1000).getTime();
  //   const now = new Date();
  //   const timeout = expiryDate - now - 3000;
  //   refreshTimeout = setTimeout(refreshToken, timeout);

  const authorization = `Bearer ${data.access}`;
  axios.defaults.headers.common.Authorization = authorization;
  refreshing = false;
  return authorization;
};

// const clearTimeoutOnLogout = url => {
//   const isLogout = url === authEndpoints.logout().url;
//   if (isLogout) window.clearTimeout(refreshTimeout);
// };

const axiosSetup = () => {
  const access = accessTokenStorage.get();

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.common.Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (access) axios.defaults.headers.common.Authorization = `Bearer ${access}`;
  const createAxiosResponseInterceptor = () => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        const status = error?.response?.status;
        const url = error?.config?.url;
        if (status !== 401 || urlsWithoutRefresh.some(disabledUrl => url.includes(disabledUrl))) {
          return Promise.reject(error);
        }

        if (status === 401 && url === authEndpoints.refresh().url) {
          accessTokenStorage.destroy();
          refreshTokenStorage.destroy();
          window.location.href = PATHS.AUTH;
          axios.defaults.headers.common.Authorization = undefined;
        }

        // prevent looping
        axios.interceptors.response.eject(interceptor);
        try {
          // eslint-disable-next-line no-param-reassign
          error.response.config.headers.Authorization = await refreshToken();
          return axios(error.response.config);
        } catch (axiosError) {
          accessTokenStorage.destroy();
          refreshTokenStorage.destroy();
          // TODO: user is not redirected after login after stale token logout
          // eslint-disable-next-line no-console
          console.log(window.location.href);
          window.location.href = PATHS.AUTH;
          // eslint-disable-next-line no-console
          if (!isProduction) console.error(axiosError);
          return Promise.reject(error);
        } finally {
          createAxiosResponseInterceptor();
        }
      },
    );
  };
  createAxiosResponseInterceptor();
};

export default axiosSetup;
