import React, { useState } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import FooterButton from 'components/FooterButton';
import Section from 'components/Section';
import Table from 'components/Table';
import meetingsEndpoints from 'config/api/meetings';
import usePagination from 'hooks/usePagination';
import general_messages from 'messages/general_messages';
import meeting_messages from 'messages/meeting_messages';
import table_messages from 'messages/table_messages';
import PATHS from 'router/PATHS';

import UserAvatar from '../../../../components/UserAvatar/UserAvatar';

const MeetingAdvicesPageAdvicesTable = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [sortAdvices, setSortAdvices] = useState({ key: null, direction: null });
  const onSortAdvices = (key, direction) => setSortAdvices({ key, direction });

  const {
    data: advicesData,
    hasNextPage: advicesHasNextPage,
    loading: advicesLoading,
    getData: advicesGetData,
  } = usePagination({
    endpointFunc: meetingsEndpoints.getAllReportsAsSecurityOfficer,
    limits: [5, 5, 5],
    dependencies: [sortAdvices],
    sort: sortAdvices,
    isGet: true,
  });

  const dataMappingForAdvices = [
    {
      id: 'advisor',
      width: '42%',
      label: t(...table_messages.advisor),
      get: data =>
        data?.adviser_profile && (
          <UserAvatar
            lazy
            showName
            userData={{
              firstName: data?.adviser_profile.first_name,
              lastName: data?.adviser_profile.last_name,
              imageUrl: data?.adviser_profile.image_url,
            }}
            userId={data?.adviser_id}
          />
        ),
    },
    {
      id: 'org_user_profile',
      width: '42%',
      label: t(...table_messages.customer_user_name),
      get: data =>
        data?.org_user_profile && (
          <UserAvatar
            lazy
            showName
            userData={{
              firstName: data?.org_user_profile.first_name,
              lastName: data?.org_user_profile.last_name,
              imageUrl: data?.org_user_profile.image_url,
            }}
            userId={data?.org_user_id}
          />
        ),
    },
    {
      id: 'meeting_date',
      width: '16%',
      label: t(...table_messages.date),
      get: data => data?.meeting_date && format(new Date(data.meeting_date), 'yyyy-MM-dd, HH:mm'),
    },
  ];

  const onAdviceClick = (event, { meeting_id }) => {
    history.push(`${PATHS.MEETING_REPORT}/${meeting_id}?readOnly='true'`);
  };

  return (
    <Section paddingTop={2} smallPadding smallTitle title={t(...meeting_messages.my_meeting_advices_title)}>
      <Table data={advicesData} dataMapping={dataMappingForAdvices} onRowClick={onAdviceClick} onSort={onSortAdvices} sort={sortAdvices} />
      {advicesHasNextPage && (
        <FooterButton loading={advicesLoading} onClick={advicesGetData}>
          {t(...general_messages.show_more)}
        </FooterButton>
      )}
    </Section>
  );
};

// MeetingAdvicesPageAdvicesTable.propTypes = {};

export default MeetingAdvicesPageAdvicesTable;
