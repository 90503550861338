import METHOD from './_methods';

const authEndpoints = {
  login: () => ({ url: 'api/v1/auth/jwt/create/', method: METHOD.POST }),
  logout: () => ({ url: 'api/v1/auth/jwt/logout/', method: METHOD.POST }),
  refresh: () => ({ url: 'api/v1/auth/jwt/refresh/', method: METHOD.POST }),

  reset_password_request: () => ({ url: 'api/v1/auth/users/reset_password/', method: METHOD.POST }),
  reset_password: () => ({ url: 'api/v1/auth/users/reset_password_confirm/', method: METHOD.POST }),
  set_password: () => ({ url: 'api/v1/auth/users/set_password/', method: METHOD.POST }),
  activation: () => ({ url: 'api/v1/auth/users/activation/', method: METHOD.POST }),
  create_user: () => ({ url: 'api/v1/auth/users/create_with_org/', method: METHOD.POST }),
  resend_activation: () => ({ url: 'api/v1/auth/users/resend_activation/', method: METHOD.POST }),
  delete_user: id => ({ url: `api/v1/auth/users/me/${id}`, method: METHOD.DELETE }),
  me: () => ({ url: 'api/v1/auth/users/me/', method: METHOD.GET }),
  ping: () => ({ url: 'api/v1/ping/', method: METHOD.GET }),
  updatePayment: () => ({ url: 'api/v1/payments/update_org_payment_field', method: METHOD.PUT }),
  getStripeUrl: () => {
    const successUrl = `${window.location.href}?stripe_success=true`;
    const cancelUrl = `${window.location.href}?stripe_failure=true`;
    const url = `api/v1/payments/get_stripe_update_payment_info_url?success_url=${successUrl}&cancel_url=${cancelUrl}`;
    return { url, method: METHOD.GET };
  },
};

export default authEndpoints;
