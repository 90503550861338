import React from 'react';

import { makeStyles, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import placeholder from 'assets/placeholders/article.jpg';
import ImageWithFallback from 'components/ImageWithFallback';
import { contentTypeResolver } from 'config/translatableConstants/TRANSLATABLE_CONTENT_TYPES';
import stripHTMLTags from 'helpers/stripHTMLTags';
import general_messages from 'messages/general_messages';
import PATHS from 'router/PATHS';

const useStyles = makeStyles(theme => ({
  paper: {
    overflow: 'hidden',
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    transitionDuration: theme.transitions.duration.shorter,
    transitionTimingFunction: theme.transitions.easing.easeIn,
    boxShadow: 'none',
    opacity: '0.98',
    cursor: 'pointer',
    minWidth: ({ smallTile }) => (smallTile ? '210px' : '270'),
    '&:hover': {
      opacity: '1',
      boxShadow: theme.shadows[1],
    },
  },
  imageWrapper: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    paddingBottom: '56%',
  },
  image: {
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    minHeight: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  content: {
    display: 'grid',
    gridGap: theme.spacing(2),
    padding: theme.spacing(3, 3, 0, 3),
    gridTemplateRows: ({ withImage }) => (withImage ? 'auto auto 1fr auto' : 'auto 1fr auto'),
    flexGrow: '1',
  },
  greenText: {
    textTransform: 'uppercase',
    color: theme.palette.text.secondaryColor,
    letterSpacing: '1px',
    fontSize: ({ smallTile }) => (smallTile ? '.65rem' : '.75rem'),
    fontWeight: 500,
  },
  teaser: {
    color: theme.palette.text.secondary,
    overflow: 'hidden',
    position: 'relative',
    maxHeight: '175px',
    '& p': {
      margin: 0,
    },
    '&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '20px',
      background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
      top: '155px',
    },
  },
  footer: {
    borderTop: `thin solid ${theme.palette.secondary[100]}`,
    padding: theme.spacing(2, 3),
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: theme.spacing(3),
  },
}));

const ArticleTileSkeleton = ({ withImage }) => {
  const styles = useStyles();
  return (
    <Paper className={styles.paper} elevation={0}>
      {withImage && (
        <div className={styles.imageWrapper}>
          <img alt='placeholder' className={styles.image} src={placeholder} />
        </div>
      )}
      <div className={styles.content}>
        <Typography component='p'>
          <Skeleton variant='text' width='50%' />
        </Typography>
        <Typography component='h3' variant='body1'>
          <Skeleton height='40px' variant='text' />
        </Typography>
        <Typography className={styles.teaser} component='p' variant='body2'>
          <Skeleton height='8em' variant='text' />
        </Typography>
      </div>
      <div className={styles.footer}>
        <Typography component='span'>
          <Skeleton variant='text' width='40px' />
        </Typography>
        <Typography component='span'>
          <Skeleton variant='text' width='40px' />
        </Typography>
      </div>
    </Paper>
  );
};

ArticleTileSkeleton.propTypes = {
  withImage: PropTypes.bool,
};

ArticleTileSkeleton.defaultProps = {
  withImage: false,
};

const ArticleTile = ({ data, minimal, withImage, smallTile }) => {
  if (!data) return <ArticleTileSkeleton minimal={minimal} withImage={withImage} />;
  const { t } = useTranslation();
  const { estimated_time, id, image_url, service_area, object_content_type, teaser, heading } = data;
  const styles = useStyles({ withImage, smallTile });

  return (
    <Paper className={styles.paper} component={Link} elevation={0} to={`${PATHS.ARTICLES}/${id}`}>
      {withImage && (
        <div className={styles.imageWrapper}>
          <ImageWithFallback alt={heading} className={styles.image} src={image_url || data.imageUrl} />
        </div>
      )}
      <div className={styles.content}>
        <Typography className={styles.greenText} component='h3'>
          {service_area?.name}
        </Typography>
        <Typography component='h3' variant='body1'>
          {stripHTMLTags(heading)}
        </Typography>
        <Typography className={styles.teaser} component='div' variant='body2'>
          {teaser && parse(teaser)}
        </Typography>
      </div>
      <div className={styles.footer}>
        <Typography className={styles.greenText} component='span'>
          {`${estimated_time || data.estimatedTime} ${t(...general_messages.minutes_shortcut).toUpperCase()}`}
        </Typography>
        <Typography className={styles.greenText} component='span'>
          {t(...contentTypeResolver(object_content_type || data.objectContentType))}
        </Typography>
      </div>
    </Paper>
  );
};

ArticleTile.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    metadata: PropTypes.shape({
      version_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    version_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image_url: PropTypes.string,
    heading: PropTypes.string,
    description: PropTypes.string,
    teaser: PropTypes.string,
    title: PropTypes.string,
    service_area: PropTypes.shape({
      name: PropTypes.string,
    }),
    estimated_time: PropTypes.number,
    object_content_type: PropTypes.string,
  }),
  minimal: PropTypes.bool,
  withImage: PropTypes.bool,
  smallTile: PropTypes.bool,
};

ArticleTile.defaultProps = {
  data: null,
  minimal: false,
  withImage: true,
  metadata: null,
  smallTile: false,
};

export default ArticleTile;
