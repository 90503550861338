import React from 'react';

import { useTranslation } from 'react-i18next';

import FullHeightContainer from 'components/FullHeightContainer';
import ManagePageHeader from 'components/ManagePageHeader';
import meeting_messages from 'messages/meeting_messages';

import MeetingAdvicesPageAdvicesTable from './_components/MeetingAdvicesPageAdvicesTable';
import MeetingAdvicesPageQuestionsTable from './_components/MeetingAdvicesPageQuestionsTable';
import MeetingAdvicesPageRightAdornment from './_components/MeetingAdvicesPageRightAdornment';

const MeetingAdvicesPage = () => {
  const { t } = useTranslation();

  return (
    <FullHeightContainer>
      <ManagePageHeader rightAdornment={<MeetingAdvicesPageRightAdornment />}>{t(...meeting_messages.advices_page_title)}</ManagePageHeader>
      <MeetingAdvicesPageAdvicesTable />
      <MeetingAdvicesPageQuestionsTable />
    </FullHeightContainer>
  );
};

export default MeetingAdvicesPage;
