import React, { useEffect, useMemo, useState } from 'react';

import { makeStyles, Typography, Container } from '@material-ui/core';
import { useFormik } from 'formik';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import DialogViewWrapper from 'components/DialogViewWrapper';
import Loader from 'components/Loader';
import RelatedContentOneColumn from 'components/RelatedContentOneColumn';
import guidesEndpoints from 'config/api/guides';
import { logObjects } from 'config/constants/logs';
import useApiCall from 'hooks/useApiCall';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import useLoadingState from 'hooks/useLoadingState';
import useRedirectFromQuery from 'hooks/useRedirectFromQuery';
import useSendingState from 'hooks/useSendingState';
import guide_messages from 'messages/guide_messages';
import validation_messages from 'messages/validation_messages';
import PATHS from 'router/PATHS';
import logEvent from 'services/logEvent';

import GuideAction from './_components/GuideAction';
import GuideActionButtons from './_components/GuideActionButtons';
import GuideComments from './_components/GuideComments';
import GuideSteps from './_components/GuideSteps';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  headImage: {
    maxWidth: '100%',
  },
}));

const FORM_ID = 'guide_form';

const SingleGuidePage = () => {
  const { guide_id, version_id, progress_id } = useParams();
  const redirect = useRedirectFromQuery();
  const history = useHistory();
  const { t } = useTranslation();
  const { apiCall } = useApiCall();
  const { loading, setLoading, setLoaded } = useLoadingState();
  const { sending, setSent, setSending } = useSendingState(false);
  const [localProgressId, setLocalProgressId] = useState(progress_id);
  const [isRoot, setIsRoot] = useState(true);
  const [guideData, setGuideData] = useState();
  const [currentStepId, setCurrentStepId] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [availableSteps, setAvailableSteps] = useState(null);

  /**
   * save user choices on step change
   * @param actions
   * @param comment
   * @returns {Promise<void>}
   */
  const saveUserData = async ({ actions, comment }) => {
    setSending();
    const actionsToUpdate = Object.values(actions).filter(action => action?.guide_action_id);
    const actionsToRemove = Object.values(actions).filter(action => action?.id && !action.guide_action_id);
    const shouldUpdateComment = !isNil(comment) && localProgressId;

    await Promise.all([
      ...actionsToUpdate.map(data => apiCall(guidesEndpoints.updateAction(), { data: { ...data, guide_progress_id: localProgressId } })),
      ...actionsToRemove.map(({ id }) => apiCall(guidesEndpoints.removeAction(id))),
      shouldUpdateComment &&
        apiCall(guidesEndpoints.updateStep(), { data: { comment, guide_progress_id: localProgressId, guide_step_id: currentStepId } }),
    ]);
    setSent();
  };

  const resetForm = () => {
    /* eslint-disable @typescript-eslint/no-use-before-define */
    if (formik) formik.resetForm();
    /* eslint-enable @typescript-eslint/no-use-before-define */
  };

  const onSubmit = async data => {
    setCurrentStepId(data.nextStepId);
    await saveUserData(data);
  };

  const onPrev = prevId => {
    const { actions } = currentStep;
    if (actions && actions.length) {
      actions.forEach(({ user_data }) => {
        if (user_data) apiCall(guidesEndpoints.removeAction(user_data.id));
      });
    }
    apiCall(guidesEndpoints.updateStep(), { data: { comment: '', guide_progress_id: localProgressId, guide_step_id: currentStepId } });
    setCurrentStepId(prevId);
  };

  const formik = useFormik({
    initialValues: {
      nextStep: {},
      nextStepId: '',
      comment: '',
      actions: {},
    },
    validationSchema: yup.object({
      nextStepId: yup.string().required(t(...validation_messages.required)),
    }),
    isInitialValid: false,
    onSubmit,
  });

  const openIncidentCreator = () => {
    history.push(`${PATHS.INCIDENT_REPORT}/?localProgressId=${localProgressId}&fromGuide=true&redirect_url=${PATHS.ROOT}`);
  };

  const closeWithSave = async isIncident => {
    await saveUserData(formik.values);
    if (isIncident) {
      openIncidentCreator();
    } else {
      redirect();
    }
  };

  /**
   * update previously saved progress
   * @returns {Promise<void>}
   */
  const updateInitialProgress = async () => {
    setIsRoot(false);

    const { data: progressData } = await apiCall(guidesEndpoints.getProgress(localProgressId));
    const id = progressData?.guide_step?.id;
    if (id) setCurrentStepId(id);
  };

  const setRootData = steps => {
    setIsRoot(true);
    setCurrentStepId(null);
    setCurrentStep(null);
    setAvailableSteps(steps || guideData.steps);
  };

  const populateUserData = ({ actions, user_data: step_user_data }) => {
    const parsedActions = {};
    const comment = step_user_data?.comment;
    if (actions) {
      actions.forEach(({ user_data, id: actionId }) => {
        parsedActions[actionId] = user_data;
      });
    }
    /* eslint-disable @typescript-eslint/no-use-before-define */
    formik.setFieldValue('actions', parsedActions);
    if (!isNil(comment)) formik.setFieldValue('comment', comment);
    /* eslint-enable @typescript-eslint/no-use-before-define */
  };

  /**
   * get general schema of guide and update
   * @returns {Promise<void>}
   */
  const getGuideData = async () => {
    setLoading();
    const { data } = await apiCall(guidesEndpoints.get(version_id));
    setGuideData(data);
    if (localProgressId) {
      await updateInitialProgress(localProgressId);
    } else {
      setRootData(data.steps);
    }
    setLoaded();
  };

  /**
   * get user choices on mount and step change
   * @param stepId
   * @returns {Promise<void>}
   */
  const getStepData = async stepId => {
    const params = {};
    if (localProgressId) params.guide_progress_id = localProgressId;
    const { data } = await apiCall(guidesEndpoints.getStep(stepId, params));
    const newSteps = data.children;
    setAvailableSteps(newSteps);
    setCurrentStep(data);
    populateUserData(data);
    setIsRoot(false);
  };

  /**
   * save user progress ONLY on step change
   * @param stepId
   * @returns {Promise<void>}
   */
  const saveUserProgress = async stepId => {
    let progressId = localProgressId;
    const data = { guide_root_id: guide_id, guide_step_id: currentStepId };
    if (!localProgressId) {
      const { data: progressData } = await apiCall(guidesEndpoints.createProgress(), { data });
      progressId = progressData.id;
      setLocalProgressId(progressId);
    }

    if (stepId) apiCall(guidesEndpoints.updateProgress(progressId), { data });
    else if (progressId) {
      setLocalProgressId(null);
      await apiCall(guidesEndpoints.deleteProgress(progressId));
      const urlParams = new URLSearchParams(history.location.search);
      history.push(`${PATHS.GUIDES}/${guide_id}/${version_id}/?${decodeURI(urlParams.toString())}`);
    }
  };

  useEffect(() => {
    resetForm();
    if (currentStepId) getStepData(currentStepId);
  }, [currentStepId]);

  useDidUpdateEffect(() => {
    saveUserProgress(currentStepId);
    logEvent(logObjects.GUIDE_STEP);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formik.setFieldValue('nextStepId', '');
  }, [currentStepId]);

  useEffect(() => {
    getGuideData();
    logEvent(logObjects.GUIDE);
  }, []);

  useEffect(() => {
    if (availableSteps?.length === 1) {
      formik.setFieldValue('nextStep', availableSteps[0]);
      formik.setFieldValue('nextStepId', availableSteps[0].id);
      setTimeout(formik.validateForm, 0);
    }
  }, [availableSteps]);

  const isNextDisabled = useMemo(() => {
    if (!currentStep) return false;
    if (availableSteps?.length <= 1) return false;
    return !formik.isValid && !currentStep.is_leaf;
  }, [formik.isValid, currentStep]);

  const styles = useStyles();
  return (
    <>
      <DialogViewWrapper title={t(...guide_messages.guide_page_title)}>
        {loading ? (
          <Loader inner />
        ) : (
          <form className={styles.wrapper} id={FORM_ID} onSubmit={formik.handleSubmit}>
            <Typography className={styles.title} component='h2' variant='h2'>
              {guideData?.heading}
            </Typography>
            {isRoot && <img alt={guideData?.heading} className={styles.headImage} src={guideData?.image_url} />}
            <GuideAction currentStep={currentStep} description={guideData?.description} formik={formik} isRoot={isRoot} />
            {!isRoot && <GuideComments formik={formik} />}
            {availableSteps?.length > 1 && (
              <GuideSteps availableSteps={availableSteps} currentStep={currentStep} formik={formik} isRoot={isRoot} />
            )}
            <GuideActionButtons
              closeWithSave={closeWithSave}
              currentStep={currentStep}
              disabled={isNextDisabled}
              form={FORM_ID}
              isIncident={guideData?.is_incident}
              onPrev={onPrev}
              setRootData={setRootData}
              showLoader={sending}
            />
          </form>
        )}
      </DialogViewWrapper>
      {isRoot && (
        <Container maxWidth='sm'>
          <RelatedContentOneColumn articles={guideData?.related_articles} guides={guideData?.related_guides} isLoading={loading} />
        </Container>
      )}
    </>
  );
};

export default SingleGuidePage;
