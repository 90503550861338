import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: ({ noBorder }) => (noBorder ? null : `3px solid ${theme.palette.primary[100]}`),
    padding: theme.spacing(5, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0),
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },

  subtitle: {
    fontSize: '1.125rem',
    fontWeight: 500,
    marginTop: theme.spacing(3),
  },

  rightAdornment: {
    paddingLeft: theme.spacing(2),
    alignSelf: 'flex-start',
    display: 'flex',
    gap: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      paddingTop: theme.spacing(2),
    },
  },
}));
