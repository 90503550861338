import AdvisoryPage from 'pages/AdvisoryPage';
import ArticlesPage from 'pages/ArticlesPage';
import AuthPage from 'pages/AuthPage';
import BookMeetingPage from 'pages/BookMeetingPage';
import DashboardPage from 'pages/DashboardPage';
import EditArticlePage from 'pages/EditArticlePage';
import EditGuidePage from 'pages/EditGuidePage';
import GlobalSearchPage from 'pages/GlobalSearchPage';
import GuidesPage from 'pages/GuidesPage';
import IncidentReportsPage from 'pages/IncidentReportsPage';
import ManageContentPage from 'pages/ManageContentPage';
import ManageMeetingSlotsPage from 'pages/ManageMeetingSlotsPage';
import MeetingAdvicesPage from 'pages/MeetingAdvicesPage';
import MeetingReportPage from 'pages/MeetingReportPage';
import MyProfilePage from 'pages/MyProfilePage';
import NotFoundPage from 'pages/NotFoundPage';
import OrganizationAdministrationPage from 'pages/OrganizationAdministrationPage';
import PaymentPage from 'pages/PaymentPage';
import QuestionsPage from 'pages/QuestionsPage';
import SelfTestsPage from 'pages/SelfTestsPage/SelfTestsPage';
import ServiceAreaPage from 'pages/ServiceAreaPage';
import SingleArticlePage from 'pages/SingleArticlePage';
import SingleGuidePage from 'pages/SingleGuidePage';
import SingleIncidentReportPage from 'pages/SingleIncidentReportPage';
import SingleSelfTestPage from 'pages/SingleSelfTestPage/SingleSelfTestPage';
import SingleWorkplacePage from 'pages/SingleWorkplacePage/SingleWorkplacePage';
import UsersPage from 'pages/UsersPage';
import WorkplacesPage from 'pages/WorkplacesPage/WorkplacesPage';
import WorkplacesTemplatePage from 'pages/WorkplacesTemplatePage/WorkplacesTemplatePage';
import PATHS from 'router/PATHS';

const routes = [
  {
    path: PATHS.ROOT,
    component: DashboardPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: `${PATHS.AUTH}/:type?/:uid?/:token?`,
    component: AuthPage,
    isPrivate: false,
    redirectIfAuthorized: true,
  },
  {
    path: PATHS.MY_PROFILE,
    component: MyProfilePage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.ARTICLES,
    component: ArticlesPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: `${PATHS.ARTICLES}/:id`,
    component: SingleArticlePage,
    isPrivate: false,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.MANAGE_CONTENT,
    component: ManageContentPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: `${PATHS.EDIT_GUIDE}/:id?`,
    component: EditGuidePage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: `${PATHS.EDIT_ARTICLE}/:id?`,
    component: EditArticlePage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.GUIDES,
    component: GuidesPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: `${PATHS.GUIDES}/:guide_id/:version_id/:progress_id?`,
    component: SingleGuidePage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.SELF_TESTS,
    component: SelfTestsPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: `${PATHS.SELF_TESTS}/:selfTestRootId/:selfTestVersionId/:selfTestProgressId?`,
    component: SingleSelfTestPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.USERS,
    component: UsersPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: `${PATHS.CONTENT_BY_SERVICE_AREA}/:service_area_id`,
    component: ServiceAreaPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.BOOK_MEETING,
    component: BookMeetingPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.INCIDENT_REPORTS,
    component: IncidentReportsPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: `${PATHS.INCIDENT_REPORT}/:report_id?`,
    component: SingleIncidentReportPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.MANAGE_MEETING_SLOTS,
    component: ManageMeetingSlotsPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.ADVISORY_PAGE,
    component: AdvisoryPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.MEETING_ADVICES,
    component: MeetingAdvicesPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: `${PATHS.MEETING_REPORT}/:meeting_id`,
    component: MeetingReportPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.ORGANIZATION_ADMINISTRATION,
    component: OrganizationAdministrationPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.PAYMENT,
    component: PaymentPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.GLOBAL_SEARCH,
    component: GlobalSearchPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.QUESTIONS,
    component: QuestionsPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.WORKPLACES,
    component: WorkplacesPage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: PATHS.WORKPLACES_TEMPLATE,
    component: WorkplacesTemplatePage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: `${PATHS.WORKPLACES}/:workplace_id`,
    component: SingleWorkplacePage,
    isPrivate: true,
    redirectIfAuthorized: false,
  },
  {
    path: '*',
    component: NotFoundPage,
    isPrivate: false,
    redirectIfAuthorized: false,
  },
];

export default routes;
