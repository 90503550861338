import { useState } from 'react';

const useBoolState = init => {
  const [state, setState] = useState(init);
  const setFalse = () => setState(false);
  const setTrue = () => setState(true);
  const toggle = () => setState(prevState => !prevState);
  return { state, setFalse, setTrue, toggle };
};

export default useBoolState;
