import { useHistory, useLocation } from 'react-router-dom';

import ensureIsArray from 'helpers/ensureIsArray';

const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = {};
  Array.from(new URLSearchParams(location.search).entries()).forEach(([key, value]) => {
    if (!queryParams.hasOwnProperty(key)) {
      queryParams[key] = value;
    } else {
      queryParams[key] = [...ensureIsArray(queryParams[key]), value];
    }
  });

  const deleteParam = name => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.delete(name);
    const paramsString = urlParams.toString();
    history.replace(`${location.pathname}?${paramsString}`);
  };

  const addParam = (name, value) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set(name, value);
    const paramsString = urlParams.toString();
    history.replace(`${location.pathname}?${paramsString}`);
  };

  return { queryParams, deleteParam, addParam };
};

export default useQueryParams;
