import { AuthorWithId, AuthorWithIdDTO } from '../types/Author';
import { SelfTestAdminUrl, SelfTestAdminUrlDTO } from '../types/General';
import { Language, LanguageDTO } from '../types/Language';
import { ServiceArea, ServiceAreaDTO } from '../types/ServiceArea';

export const parseAuthorForFE = (raw: AuthorWithIdDTO): AuthorWithId => ({
  firstName: raw.first_name || '',
  lastName: raw.last_name || '',
  id: raw.id || null,
});

export const parseLanguageForFE = (raw: LanguageDTO): Language => ({
  id: raw.id || null,
  languageCode: raw.language_code || '',
  label: raw.label || '',
});

export const parseServiceAreaForFE = (raw: ServiceAreaDTO): ServiceArea => ({
  id: raw.id || null,
  name: raw.name || '',
  description: raw.description || '',
  slug: raw.slug || '',
  nameTranslationKey: raw.name_translation_key || '',
  descriptionTranslationKey: raw.description_translation_key || '',
});

export const parseSelfTestDjangoAdminRedirectUrl = (raw: SelfTestAdminUrlDTO): SelfTestAdminUrl => ({
  addUrl: raw.add_url,
});
