import React, { useMemo, useRef } from 'react';

import { Container, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ArticleTile from 'components/ArticleTile';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import article_messages from 'messages/article_messages';

const useStyles = makeStyles(theme => ({
  section: {
    padding: theme.spacing(5, 0),
    background: theme.palette.background.level2,
  },
  title: {
    color: theme.palette.primary[300],
    marginBottom: theme.spacing(5),
  },
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(5),
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      padding: theme.spacing(0, 1),
    },
  },
  column: {
    '& > *': {
      marginBottom: theme.spacing(5),
    },
  },
}));

const RelatedArticles = ({ articles, innerClassName }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const articlesComponents = useMemo(
    /* eslint-disable react/no-array-index-key */
    () => {
      if (!articles || !Array.isArray(articles)) return [];
      if (upSm) {
        const even = [];
        const odd = [];
        articles.forEach((article, index) => {
          const contentTile = <ArticleTile key={article.id} data={article} type={CONTENT_TYPES.ARTICLE} withImage={index <= 1} />;
          if (index % 2) even.push(contentTile);
          else odd.push(contentTile);
        });
        return [odd, even];
      }
      return [
        articles.map((article, index) => (
          <ArticleTile key={article.id} data={article} type={CONTENT_TYPES.ARTICLE} withImage={index <= 1} />
        )),
      ];
    },
    [articles, upSm],
  );

  const styles = useStyles();
  if (!articles.length) return null;
  return (
    <section ref={ref} className={styles.section}>
      <Container className={innerClassName}>
        <div>
          <Typography className={styles.title} component='h2' variant='h2'>
            {t(...article_messages.related_articles)}
          </Typography>
          <div className={styles.wrapper}>
            {articlesComponents.map((articlesComponentsColumn, index) => (
              <div key={index} className={styles.column}>
                {articlesComponentsColumn}
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

RelatedArticles.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) })),
  innerClassName: PropTypes.string,
};

RelatedArticles.defaultProps = {
  articles: [],
  innerClassName: '',
};

export default RelatedArticles;
