import React from 'react';

import { useTranslation } from 'react-i18next';

import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import title_messages from 'messages/title_messages';
import ContentPage from 'pages/_common/ContentPage';
import audienceFilter from 'services/_filters/parsers/audienceFilter';
import customerLevelFilter from 'services/_filters/parsers/customerLevelFilter';
import depthLevelFilter from 'services/_filters/parsers/depthLevelFilter';
import serviceAreaFilter from 'services/_filters/parsers/serviceAreaFilter';
import tagFilter from 'services/_filters/parsers/tagFilter';

const getAvailableFiltersUrlsForGuides = () => ({
  serviceAreaFilter,
  tagFilter,
});

const staticFilters = {
  audience: audienceFilter,
  customer_level: customerLevelFilter,
  depth_level: depthLevelFilter,
};

const GuidesPage = () => {
  const { t } = useTranslation();
  return (
    <ContentPage
      contentType={[CONTENT_TYPES.GUIDE, CONTENT_TYPES.INCIDENT_GUIDE]}
      customTitle={t(...title_messages.guide)}
      filtersGetter={getAvailableFiltersUrlsForGuides}
      staticFilters={staticFilters}
    />
  );
};

export default GuidesPage;
