import axios from 'axios';

import METHOD from 'config/api/_methods';
import createUrlWithParams from 'helpers/createUrlWithParams';

const usersEndpoints = {
  getAll: params => ({ url: createUrlWithParams('/api/v1/members/', params), method: METHOD.GET }),
  create: params => ({ url: createUrlWithParams('/api/v1/members/', params), method: METHOD.POST }),
  update: id => ({ url: `/api/v1/members/${id}/`, method: METHOD.PATCH }),
  delete: id => ({ url: `/api/v1/members/${id}/`, method: METHOD.DELETE }),
  addByFileUpload: organization_id => ({ url: `api/v1/organization_import/${organization_id}/`, method: METHOD.POST }),

  checkUploadProgress: upload_id => ({ url: `api/v1/user_tasks/${upload_id}/`, method: METHOD.GET }),
  currentUser: () => ({ url: 'api/v1/current_user/', method: METHOD.GET }),

  // CURRENT USER
  updateCurrentUser: () => ({ url: 'api/v1/current_user/', method: METHOD.PATCH }),

  // ORGANIZATION
  getOrganization: id => ({ url: `/api/v1/organizations/${id}/`, method: METHOD.GET }),
  getOrganizationStats: params => ({ url: createUrlWithParams('/api/v1/org_statistics/', params), method: METHOD.GET }),
  updateOrganization: id => ({ url: `/api/v1/organizations/${id}/`, method: METHOD.PUT }),
  deactivateOrganization: id => ({ url: `/api/v1/organizations/${id}/`, method: METHOD.DELETE }),
};

// usage for react-query
export const usersApi = {
  setLanguage: data => axios.patch('/api/v1/auth/users/set_language/', data),
  updateCurrentUserPicture: data => axios.post('api/v1/current_user/picture/', data),
  deleteCurrentUserPicture: () => axios.delete('api/v1/current_user/picture/'),
  checkCampaignCode: code => axios.get(`api/v1/campaign_codes/${code}/`),
  updateUserContacts: (id, data) => axios.post(`api/v1/auth/users/${id}/`, data),
  getUserData: id => axios.get(`api/v1/auth/users/${id}/`),
  getOrganizationPricingInfo: id => axios.get(`api/v1/organizations/${id}/pricing/`),
  getUserKPI: () => {
    // TODO RFC12
    const fakeData = [
      { id: 1, value: 1, description: 'lorem ipsum dolor coś tam' },
      { id: 2, value: 2, description: 'lorem ipsum dolor coś tam' },
      { id: 3, value: 3, description: 'lorem ipsum dolor coś tam' },
      { id: 4, value: 4, description: 'lorem ipsum dolor coś tam' },
      { id: 5, value: 5, description: 'lorem ipsum dolor coś tam' },
      { id: 6, value: 6, description: 'lorem ipsum dolor coś tam' },
      { id: 7, value: 7, description: 'lorem ipsum dolor coś tam' },
    ];
    return new Promise(res => setTimeout(() => res(fakeData), 1000));
  },
};

export default usersEndpoints;
