import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import ColoredButton from 'components/ColoredButton';
import FormCheckbox from 'components/FormCheckbox';
import FormSelect from 'components/FormSelect';
import FormTextInput from 'components/FormTextInput';
import FormTitle from 'components/FormTitle';
import SimpleFormLayout from 'components/SimpleFormLayout';
import authEndpoints from 'config/api/auth';
import commonEndpoints from 'config/api/common';
import usersEndpoints, { usersApi } from 'config/api/users';
import { TRANSLATABLE_EMPLOYEES_NUMBER_DICTIONARY } from 'config/dictionaries/EMPLOYES_NUMBER_SELECT';
import EVENTS from 'config/events/pubsub';
import { TRANSLATABLE_ORGANIZATION_COUNTRIES_DICTIONARY } from 'config/translatableConstants/TRANSLATABLE_ORGANIZATION_COUNTRIES';
import emailValidator from 'config/validators/emailValidator';
import useApiCall from 'hooks/useApiCall';
import useQueryParams from 'hooks/useQueryParams';
import auth_messages from 'messages/auth_messages';
import general_messages from 'messages/general_messages';
import label_messages from 'messages/label_messages';
import validation_messages from 'messages/validation_messages';
import PATHS from 'router/PATHS';
import userDataStorage from 'storages/userDataStorage';

const FORM = {
  ORGANIZATION_NAME: 'name',
  ORGANIZATION_NUMBER: 'organization_number',
  COUNTRY: 'country',
  BUSINESS_TYPE: 'business_type',
  EMPLOYEES_NUMBER: 'organization_size',

  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',

  BILLING_EMAIL: 'billing_email',
  BILLING_STREET: 'billing_street',
  BILLING_POSTAL: 'billing_postal',
  BILLING_CITY: 'billing_city',
  BILLING_REF: 'billing_reference',

  CAMPAIGN_CODE: 'campaign_code',

  TERMS: 'terms',
  GDPR: 'gdpr',
};

const RegisterForm = ({ initialData, isEdit, onClose }) => {
  const { t, i18n } = useTranslation();
  const { apiCall, sending } = useApiCall();
  const { enqueueSnackbar } = useSnackbar();
  const { queryParams } = useQueryParams();
  const history = useHistory();
  const userData = userDataStorage.get();

  const emailInput = useRef();
  const campaignCodeInput = useRef();

  const register = async ({ organizations, profile }) => {
    const data = {
      profile,
      organizations: [organizations],
    };
    const { status, data: responseData } = await apiCall(authEndpoints.create_user(), { data });
    if (status === 400 && responseData.email) {
      enqueueSnackbar(t(...validation_messages.email_in_use), { variant: 'error' });
      emailInput.current.focus();
    }
    if (status < 300) {
      history.push(PATHS.AUTH_REGISTRATION_LINK_SENT, data);
    }
  };

  const update = async ({ organizations, profile }) => {
    const data = {
      name: organizations.name || '',
      country: organizations.country || '',
      campaign_code: organizations.campaign_code,
      infos: {
        contact_first_name: profile.first_name,
        contact_last_name: profile.last_name,
        contact_email: profile.email,
        ...organizations.infos,
      },
    };
    const { status, data: responseData } = await apiCall(usersEndpoints.updateOrganization(userData?.organization), { data });
    if (status < 300) {
      enqueueSnackbar(t(...general_messages.data_saved), { variant: 'success' });
      PubSub.publish(EVENTS.ORGANIZATION_DATA_UPDATED, responseData);
      onClose();
    }
  };

  const [businessesTypes, setBusinessesTypes] = useState([]);
  const getDictionaries = async () => {
    const { data } = await apiCall(commonEndpoints.getBusinessTypes());
    setBusinessesTypes(data.map(({ value, label }) => ({ key: value, label: [`general>>business_types>>${value}`, label] })));
  };

  const validateCampaignCode = async codeToValidate => {
    if (!codeToValidate) return true;
    try {
      const { status } = await usersApi.checkCampaignCode(codeToValidate);
      return status === 200;
    } catch ({ response }) {
      let message;
      if (response.status === 404) {
        message = auth_messages.campaign_code_error.not_exist;
      } else if (response.status === 400) {
        // eslint-disable-next-line default-case
        switch (response.data.message) {
          case 'outdated':
            message = auth_messages.campaign_code_error.outdated;
            break;
          case 'limit_reached':
            message = auth_messages.campaign_code_error.limit_reached;
            break;
        }
      } else {
        message = general_messages.api_error;
      }
      campaignCodeInput.current.focus();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      formik.setFieldError('organizations.campaign_code', t(message));
      return false;
    }
  };

  useEffect(() => {
    getDictionaries();
  }, []);

  const onSubmit = async data => {
    const isCampaignCodeValid = await validateCampaignCode(data.organizations.campaign_code);
    if (!isCampaignCodeValid) return;
    if (isEdit) update(data);
    else register(data);
  };

  const getCampaignCodeFromUrl = useCallback(
    () => queryParams.campaignCode || queryParams.CampaignCode || queryParams.campaign_code || queryParams.campaigncode,
    [queryParams],
  );

  const formik = useFormik({
    initialValues: {
      profile: {
        [FORM.FIRST_NAME]: initialData.contact_first_name || '',
        [FORM.LAST_NAME]: initialData.contact_last_name || '',
        [FORM.EMAIL]: initialData.contact_email || '',
      },
      organizations: {
        [FORM.ORGANIZATION_NAME]: initialData.name || '',
        [FORM.ORGANIZATION_NUMBER]: initialData.organization_number || '',
        [FORM.COUNTRY]: initialData.country || '',
        [FORM.CAMPAIGN_CODE]: initialData.campaign_code || getCampaignCodeFromUrl() || '',

        infos: {
          [FORM.BILLING_EMAIL]: initialData.billing_email || '',
          [FORM.BILLING_STREET]: initialData.billing_street || '',
          [FORM.BILLING_POSTAL]: initialData.billing_zip_code || '',
          [FORM.BILLING_CITY]: initialData.billing_city || '',
          [FORM.BILLING_REF]: initialData.billing_reference || '',
          [FORM.BUSINESS_TYPE]: initialData.business_type || '',
          [FORM.EMPLOYEES_NUMBER]: initialData.organization_size || '',
        },
      },
      [FORM.TERMS]: false,
      [FORM.GDPR]: false,
    },
    onSubmit,
    validationSchema: yup.object({
      profile: yup.object().shape({
        [FORM.FIRST_NAME]: yup.string(t(...validation_messages.required)).required(t(...validation_messages.required)),
        [FORM.LAST_NAME]: yup.string(t(...validation_messages.required)).required(t(...validation_messages.required)),
        [FORM.EMAIL]: emailValidator({
          required: t(...validation_messages.required),
          valid: t(...validation_messages.email_valid),
        }),
      }),
      organizations: yup.object().shape({
        [FORM.ORGANIZATION_NAME]: isEdit
          ? null
          : yup.string(t(...validation_messages.required)).required(t(...validation_messages.required)),
        [FORM.ORGANIZATION_NUMBER]: isEdit
          ? null
          : yup.string(t(...validation_messages.required)).required(t(...validation_messages.required)),
        [FORM.COUNTRY]: yup.string(t(...validation_messages.required)).required(t(...validation_messages.required)),
      }),
      [FORM.GDPR]: isEdit ? null : yup.boolean().oneOf([true], t(...validation_messages.agreement_required)),
      [FORM.TERMS]: isEdit ? null : yup.boolean().oneOf([true], t(...validation_messages.agreement_required)),
    }),
  });

  useEffect(() => {
    // validate on autofill
    formik.validateForm();
  }, []);
  const goToStripe = async () => {
    const { data, status } = await apiCall(authEndpoints.getStripeUrl());
    if (status < 300) {
      window.location.replace(data.url);
    }
  };

  return (
    <SimpleFormLayout
      additionalButtons={() =>
        isEdit ? (
          <ColoredButton customColor='secondary' onClick={goToStripe}>
            {t(...auth_messages.payment.update_payment_method)}
          </ColoredButton>
        ) : null
      }
      disabled={!formik.isValid}
      onSubmit={formik.handleSubmit}
      sending={sending}
      submitLabel={isEdit ? t(...auth_messages.update_organization_info) : t(...auth_messages.create_account)}
      title={isEdit ? null : t(...auth_messages.register_subheading)}
    >
      <FormTitle>{t(...auth_messages.register_sections.company)}</FormTitle>
      <FormTextInput
        disabled={isEdit}
        formik={formik}
        id={`organizations.${FORM.ORGANIZATION_NAME}`}
        label={t(...label_messages.organization_name)}
        required
      />
      <FormTextInput
        disabled={isEdit}
        formik={formik}
        id={`organizations.${FORM.ORGANIZATION_NUMBER}`}
        label={t(...label_messages.organization_number)}
        required
      />
      <FormSelect
        formik={formik}
        id={`organizations.${FORM.COUNTRY}`}
        label={t(...label_messages.country)}
        options={TRANSLATABLE_ORGANIZATION_COUNTRIES_DICTIONARY}
        required
      />
      <FormSelect
        formik={formik}
        id={`organizations.infos.${FORM.BUSINESS_TYPE}`}
        label={t(...label_messages.business_type)}
        options={businessesTypes}
        required
      />
      <FormSelect
        formik={formik}
        id={`organizations.infos.${FORM.EMPLOYEES_NUMBER}`}
        label={t(...label_messages.employees_number)}
        options={TRANSLATABLE_EMPLOYEES_NUMBER_DICTIONARY}
        required
      />

      <FormTitle>
        {isEdit ? t(...auth_messages.register_sections.contact_person) : t(...auth_messages.register_sections.personal)}
      </FormTitle>
      <FormTextInput formik={formik} id={`profile.${FORM.FIRST_NAME}`} label={t(...label_messages.first_name)} required />
      <FormTextInput formik={formik} id={`profile.${FORM.LAST_NAME}`} label={t(...label_messages.last_name)} required />
      <FormTextInput
        formik={formik}
        id={`profile.${FORM.EMAIL}`}
        inputRef={emailInput}
        label={t(...label_messages.email)}
        required
        type='email'
      />
      <FormTitle>{t(...auth_messages.campaign_code)}</FormTitle>
      <FormTextInput
        formik={formik}
        id={`organizations.${FORM.CAMPAIGN_CODE}`}
        inputRef={campaignCodeInput}
        label={t(...label_messages.campaign_code)}
      />

      {!isEdit && (
        <Box margin='2rem 0 0 0'>
          <FormCheckbox
            formik={formik}
            id={FORM.TERMS}
            label={
              <>
                <Trans i18nKey={auth_messages.accept_terms[0]}>I have read and accept</Trans>
                &nbsp;
                <a href={`/assets/documents/${i18n.language}/ps_user_terms_and_conditions.pdf`} rel='noreferrer' target='_blank'>
                  <Trans i18nKey={auth_messages.terms[0]}>terms and conditions</Trans>
                </a>
                &nbsp;*
              </>
            }
          />
          <FormCheckbox
            formik={formik}
            id={FORM.GDPR}
            label={
              <>
                <Trans i18nKey={auth_messages.accept_gdpr[0]}>I have read and accept</Trans>
                &nbsp;
                <a href={`/assets/documents/${i18n.language}/ps_gdpr_policy.pdf`} rel='noreferrer' target='_blank'>
                  <Trans i18nKey={auth_messages.gdpr[0]}>the GDPR privacy terms</Trans>
                </a>
                &nbsp;*
              </>
            }
          />
        </Box>
      )}
    </SimpleFormLayout>
  );
};

RegisterForm.propTypes = {
  initialData: PropTypes.shape({
    name: PropTypes.string,
    organization_number: PropTypes.string,
    business_type: PropTypes.string,
    organization_size: PropTypes.string,
    contact_first_name: PropTypes.string,
    contact_last_name: PropTypes.string,
    contact_email: PropTypes.string,
    billing_email: PropTypes.string,
    billing_street: PropTypes.string,
    billing_zip_code: PropTypes.string,
    billing_city: PropTypes.string,
    billing_reference: PropTypes.string,
  }),
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
};

RegisterForm.defaultProps = {
  initialData: {},
  isEdit: false,
  onClose: () => {},
};

export default RegisterForm;
