const ROLES = {
  PS_ROLES: {
    CONTENT_EDITOR: 'pocketsafe_content_editor',
    CUSTOMER_ADMINISTRATOR: 'pocketsafe_customer_administrator',
    SECURITY_ONLINE: 'pocketsafe_security_online',
    SECURITY_SUPERVISOR: 'pocketsafe_security_supervisor',
  },
  ORGANIZATION: {
    ACCOUNT_OWNER: 0,
    INCIDENT_MANAGER: 1,
    USER_ADMINISTRATOR: 2,
    SECURITY_OFFICER: 3,
    CONTRACT_ADMINISTRATOR: 4,
    ACTIVE_USER: 5,
  },
};

export default ROLES;
