import React, { useState } from 'react';

import { FormControl, OutlinedInput, InputLabel, InputAdornment, IconButton, Input, FormHelperText } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';

const FormPasswordInput = ({ formik, id, label, variant, inputProps, required }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { touched, errors, handleChange, values, handleBlur } = formik;

  const handleClickShowPassword = () => setPasswordVisible(prev => !prev);
  const handleMouseDownPassword = event => event.preventDefault();

  const InputComponent = variant === 'outlined' ? OutlinedInput : Input;

  const unifiedLabel = required ? `${label} * ` : label;

  return (
    <FormControl error={touched[id] && Boolean(errors[id])} variant={variant}>
      <InputLabel htmlFor={id}>{unifiedLabel}</InputLabel>
      <InputComponent
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              edge='end'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {passwordVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        id={id}
        inputProps={{ 'data-cy': `${id}_input`, ...inputProps }}
        label={unifiedLabel}
        name={id}
        onBlur={handleBlur}
        onChange={handleChange}
        type={passwordVisible ? 'text' : 'password'}
        value={values[id]}
      />
      <FormHelperText data-cy={`${id}_input_error`} error>
        {touched[id] && errors[id]}
      </FormHelperText>
    </FormControl>
  );
};

FormPasswordInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  inputProps: PropTypes.shape({}),
  formik: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.shape({}),
  }).isRequired,
  required: PropTypes.bool,
};

FormPasswordInput.defaultProps = {
  variant: 'outlined',
  inputProps: {},
  required: false,
};

export default FormPasswordInput;
