import React, { MouseEventHandler, useMemo } from 'react';

import { Tooltip } from '@material-ui/core';
import { useQuery } from 'react-query';

import UserCard from 'components/UserCard/UserCard';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { usersApi } from 'config/api/users';
import getInitials from 'helpers/getInitials';
import { SimpleUserProfile } from 'types/UserProfile';

import useStyles from './UserAvatar.styles';

type Props = {
  size?: 's' | 'm' | 'l';
  userId?: string;
  disableUserCard?: boolean;
  lazy?: boolean;
  showName?: boolean;
  // TODO use SimpleUser type
  userData?:
    | {
        firstName: string;
        lastName: string;
        imageUrl?: string;
      }
    | SimpleUserProfile;
};

const UserAvatar: React.FC<Props> = ({ size, userId, disableUserCard, userData, lazy, showName }) => {
  const { data, isLoading } = useQuery([QUERY_KEYS.GET_USER_DATA, userId], () => usersApi.getUserData(userId), {
    enabled: !!userId && !lazy,
  });

  const imageUrl = useMemo(() => userData?.imageUrl || data?.data.profile.image_url, [userData, data]);

  const { content, lastName, firstName } = useMemo(() => {
    const _firstName = userData?.firstName || data?.data.profile.first_name || '';
    const _lastName = userData?.lastName || data?.data.profile.last_name || '';
    const result = { content: '', firstName: _firstName, lastName: _lastName };
    if (!imageUrl) {
      result.content = getInitials({ firstName: _firstName, lastName: _lastName });
    }
    return result;
  }, [userData, data, imageUrl]);

  const styles = useStyles({ size: size || 's', imageUrl });

  const stopPropagation: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  if (disableUserCard) return <div className={styles.avatarWrapper}>{content}</div>;

  return (
    <>
      <Tooltip
        classes={{
          tooltip: styles.tooltip,
        }}
        enterDelay={1000}
        enterNextDelay={1000}
        enterTouchDelay={200}
        interactive
        leaveDelay={1000}
        onClick={stopPropagation}
        PopperProps={{ onClick: stopPropagation }}
        title={<UserCard isLoading={isLoading} lazy={lazy} userData={data?.data.profile} userId={userId} />}
        // placement='right'
      >
        <div className={styles.avatarWrapper}>{content}</div>
      </Tooltip>
      {showName && <span className={styles.name}>{`${firstName} ${lastName}`}</span>}
    </>
  );
};

export default UserAvatar;
