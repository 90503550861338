import React from 'react';

import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ColoredButton from 'components/ColoredButton';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(3),
    width: '100%',
  },
}));

const GuideActionButtons = ({ currentStep, onPrev, form, setRootData, isIncident, closeWithSave, showLoader, disabled }) => {
  const { t } = useTranslation();
  const { showConfirmationModal } = useConfirmationModalContext();

  const prev = async () => {
    const confirmation = await showConfirmationModal({
      title: t(...guide_messages.loose_step_progress_title),
      body: t(...guide_messages.loose_step_progress),
    });
    if (confirmation) {
      if (currentStep.parent_id) onPrev(currentStep.parent_id);
      else setRootData();
    }
  };

  const createReportIncidentClick = () => {
    if (currentStep?.is_leaf) {
      closeWithSave(isIncident);
    }
  };

  const getLabel = () => {
    if (currentStep?.is_leaf) return isIncident ? t(...guide_messages.report_incident) : t(...guide_messages.end_guide);
    return t(...general_messages.next);
  };

  const styles = useStyles();
  return (
    <div className={styles.wrapper}>
      <Button color='secondary' disabled={!currentStep} onClick={prev} variant='outlined'>
        {t(...general_messages.previous)}
      </Button>
      <ColoredButton
        color='secondary'
        customColor='secondary'
        disabled={disabled}
        form={form}
        onClick={createReportIncidentClick}
        showLoader={showLoader}
        type='submit'
        variant='outlined'
      >
        {getLabel()}
      </ColoredButton>
    </div>
  );
};

GuideActionButtons.propTypes = {
  currentStep: PropTypes.shape({ parent_id: PropTypes.string, is_leaf: PropTypes.bool }),
  onPrev: PropTypes.func.isRequired,
  closeWithSave: PropTypes.func.isRequired,
  setRootData: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  isIncident: PropTypes.bool,
  showLoader: PropTypes.bool,
  disabled: PropTypes.bool,
};

GuideActionButtons.defaultProps = {
  currentStep: null,
  isIncident: false,
  showLoader: false,
};

export default GuideActionButtons;
