const QUERY_KEYS = {
  PRICING_INFO: 'pricing_info',
  GET_USER_DATA: 'get_user_data',
  GET_USER_KPI: 'get_user_kpi',
  GET_WORKPLACE_TEMPLATE: 'get_workplace_template',
  CREATE_WORKPLACE_TEMPLATE: 'create_workplace_template',
  UPDATE_WORKPLACE_TEMPLATE: 'update_workplace_template',
  DELETE_WORKPLACE_TEMPLATE: 'delete_workplace_template',
  GET_ALL_WORKPLACES: 'get_all_workplaces',
  CREATE_WORKPLACE: 'create workplace',
  UPDATE_WORKPLACE: 'update workplace',
  GET_WORKPLACE: 'get workplace',
  GET_WORKPLACE_TEMPLATE_VALUES: 'get workplace template values',
  SET_DEFAULT_WORKPLACE: 'set default workplace',
  GET_SINGLE_SELF_TEST: 'get self test data',
  GET_SELF_TEST_PROGRESS_INSTANCE: 'get self test progress instance',
  CREATE_SELF_TEST_PROGRESS: 'create self test progress instance',
  ANSWER_QUESTION: 'answer question',
  GET_RECOMMENDED_CONTENT: 'get recommended content for dashboard',
  GET_SELF_TEST_RESULT: 'finish test and get self test result',
  GET_SELF_TEST_RESULT_PROGRESS_DATA: 'get progress data after finishing test',
  EDIT_TEST_ANSWER: 'edit self test question answer',
};

export default QUERY_KEYS;
