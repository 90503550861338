import React, { useEffect, useMemo, useState } from 'react';

import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid';
import ColoredButton from 'components/ColoredButton/ColoredButton';
import FormAutocomplete from 'components/FormAutocomplete';
import FormTextInput from 'components/FormTextInput';
import FormTitle from 'components/FormTitle';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { TemplateSectionWithValue } from 'config/api/workplaces/_types';
import { workplacesApi } from 'config/api/workplaces/workplaces';
import ROLES from 'config/constants/ROLES';
import useGetWorkplaceFullData from 'hooks/useGetWorkplaceFullData/useGetWorkplaceFullData';
import usePermissions from 'hooks/usePermissions';
import useResponsiblePermission from 'hooks/useResponsiblePremission';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';
import workplace_messages from 'messages/workplace_messages';
import coworkerOptionsParser from 'services/autocompleteServices/coworkerOptionsParser/coworkerOptionsParser';
import coworkerParamsGetter from 'services/autocompleteServices/coworkerParamsGetter/coworkerParamsGetter';
import coworkerResultComponent from 'services/autocompleteServices/coworkerResultComponent/coworkerResultComponent';
import coworkersInitialParser from 'services/autocompleteServices/coworkersInitialParser/coworkersInitialParser';

import ButtonsGrid from '../_components/ButtonsGrid';
import DialogGrid from '../_components/DialogGrid';

type Props = {
  open: boolean;
  onClose: () => void;
  refreshData: () => Promise<any>;
  workplaceId: number;
};

export type FormInput = {
  base: {
    location: string;
    name: string;
    responsibles: any[];
  };
  templateValues: Record<string, string>;
};

const getInitialTemplateValues = (templateValues?: TemplateSectionWithValue[]) => {
  if (!templateValues) return {};
  const result: Record<string, string> = {};
  templateValues.forEach(({ contentElements }) => {
    contentElements.forEach(({ id, value }) => {
      result[id] = value;
    });
  });

  return result;
};
const FORM_ID = 'EditWorkplaceDialog';
const EditWorkplaceDialog: React.FC<Props> = ({ onClose, open, refreshData, workplaceId }) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const { data } = useGetWorkplaceFullData(workplaceId);
  const [dataPopulated, setDataPopulated] = useState<boolean>(false);

  const baseDataMutation = useMutation(QUERY_KEYS.UPDATE_WORKPLACE, workplacesApi.updateWorkplace);
  const templateUpdateDataMutation = useMutation(QUERY_KEYS.UPDATE_WORKPLACE, workplacesApi.updateWorkplaceTemplateValues);
  const templateCreateDataMutation = useMutation(QUERY_KEYS.UPDATE_WORKPLACE, workplacesApi.createWorkplaceTemplateValues);

  const [isAccountOwner] = usePermissions([ROLES.ORGANIZATION.ACCOUNT_OWNER]);
  const isResponsible = useResponsiblePermission(data?.workplaceBaseData.responsibles);
  const onSubmit = async (formData: FormInput) => {
    const promises = [];
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (isAccountOwner && !isEqual(formik.initialValues.base, formData.base)) {
      promises.push(baseDataMutation.mutateAsync({ ...formData.base, id: workplaceId }));
    }

    const initialReduce: Record<string, { value: string; id: number }> = {};
    const [templateValues] =
      data?.templateValues.map(({ contentElements }) =>
        contentElements.reduce((acc, curr) => {
          acc[`${curr.id}`] = { value: curr.value, id: curr.templateValueId };
          return acc;
        }, initialReduce),
      ) || [];

    Object.keys(formData.templateValues).forEach(key => {
      const fieldToUpdate = templateValues[key];
      const valueToUpdate = formData.templateValues[key];
      if (fieldToUpdate.value === valueToUpdate) {
        return;
      }
      if (fieldToUpdate.id) {
        promises.push(
          templateUpdateDataMutation.mutateAsync({
            content: +key,
            value: valueToUpdate,
            workplaceId,
            id: fieldToUpdate.id,
          }),
        );
      } else {
        promises.push(
          templateCreateDataMutation.mutateAsync({
            content: +key,
            value: valueToUpdate,
            workplaceId,
          }),
        );
      }
    });

    await Promise.all(promises);
    await refreshData();
    enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
    onClose();
  };

  const formik = useFormik<FormInput>({
    initialValues: {
      base: {
        location: data?.workplaceBaseData.location || '',
        responsibles: coworkersInitialParser(data?.workplaceBaseData.responsibles || []),
        name: data?.workplaceBaseData.name || '',
      },
      templateValues: getInitialTemplateValues(data?.templateValues),
    },
    validationSchema: yup.object({
      base: yup.object({
        location: yup.string().required(t(validation_messages.required)),
        name: yup.string().required(t(validation_messages.required)),
      }),
    }),
    onSubmit,
  });

  useEffect(() => {
    if (!dataPopulated && data) {
      setDataPopulated(true);
      formik.setValues({
        templateValues: getInitialTemplateValues(data.templateValues),
        base: {
          location: data.workplaceBaseData.location || '',
          responsibles: coworkersInitialParser(data.workplaceBaseData.responsibles || []),
          name: data.workplaceBaseData.name || '',
        },
      });
    }
  }, [data]);

  const isLoading = useMemo(
    () => baseDataMutation.isLoading || templateUpdateDataMutation.isLoading || templateCreateDataMutation.isLoading,
    [baseDataMutation.isLoading, templateUpdateDataMutation.isLoading, templateCreateDataMutation.isLoading],
  );

  return (
    // @ts-ignore
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          {/* @ts-ignore */}
          <ColoredButton customColor='none' disabled={isLoading} onClick={onClose} variant='outlined'>
            {t(general_messages.cancel)}
          </ColoredButton>
          {/* @ts-ignore */}
          <ColoredButton customColor='secondary' disabled={isLoading} form={FORM_ID} type='submit' variant='outlined'>
            {t(general_messages.save)}
          </ColoredButton>
        </ButtonsGrid>
      }
      onClose={onClose}
      open={open}
      title={t(workplace_messages.editWorkplace.dialogTitle)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        {/* @ts-ignore */}
        <CenteredGrid gridGap={2} withoutPadding>
          <FormTitle>{t(workplace_messages.form.basicSectionName)}</FormTitle>
          <FormTextInput disabled={!isAccountOwner} formik={formik} id='base.name' label={t(workplace_messages.form.workplaceName)} />
          <FormTextInput disabled={!isAccountOwner} formik={formik} id='base.location' label={t(workplace_messages.form.localization)} />
          <FormAutocomplete
            apiCallParamsGetter={coworkerParamsGetter}
            customizeLabel={coworkerResultComponent}
            disabled={!isAccountOwner}
            formik={formik}
            id='base.responsibles'
            label={t(workplace_messages.form.responsibles)}
            optionsParser={coworkerOptionsParser}
          />
          {data?.templateValues.map(({ sectionName, contentElements }) => (
            <>
              <FormTitle key={sectionName}>{sectionName}</FormTitle>
              {contentElements.map(({ name, id }) => (
                <FormTextInput
                  key={id}
                  disabled={!isResponsible && !isAccountOwner}
                  formik={formik}
                  id={`templateValues.${id}`}
                  label={name}
                />
              ))}
            </>
          ))}
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

export default EditWorkplaceDialog;
