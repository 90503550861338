import React from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

const FormTagsPicker = ({ options, formik, id, label }) => {
  const { values, setFieldValue } = formik;

  const onChange = (event, newValues) => {
    const valuesToSet = newValues.map(value => {
      const { inputValue } = value;
      if (inputValue) {
        const modifiedValue = value;
        modifiedValue.label = inputValue;
        return modifiedValue;
      }
      return value;
    });
    setFieldValue(id, valuesToSet);
  };

  return (
    <Autocomplete
      filterOptions={(opts, { inputValue }) => {
        const filtered = opts.filter(option => option.label.toLowerCase().includes(inputValue.toLowerCase()));
        if (inputValue !== '' && !filtered.some(option => option.label.toLowerCase() === inputValue.toLowerCase())) {
          filtered.push({
            key: inputValue,
            label: `Add "${inputValue}"`,
            inputValue,
          });
        }

        return filtered;
      }}
      freeSolo
      getOptionLabel={option => option.label}
      limitTags={4}
      multiple
      onChange={onChange}
      options={options}
      renderInput={params => <TextField {...params} label={label} variant='outlined' />}
      renderOption={option => option.label}
      value={values[id] || []}
    />
  );
};

FormTagsPicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func,
    values: PropTypes.shape({}),
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};

FormTagsPicker.defaultProps = {
  options: [],
};

export default FormTagsPicker;
