import React from 'react';

import { FormControl, FormHelperText } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import useStyles from './FormDateInput.styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#A3D3BD',
      main: '#74C3A0',
      dark: '#4EB88C',
      contrastText: '#F2F2F2',
    },
    secondary: {
      light: '#073AA1',
      main: '#053195',
      dark: '#042889',
      contrastText: '#F2F2F2',
    },
  },
});

const FormDateInput = ({ id, formik, label, dateTime, ...rest }) => {
  const { values, setFieldValue } = formik;
  const onChange = date => setFieldValue(id, date);

  const { touched, errors } = formik;
  const error = get(errors, id);
  const isTouched = get(touched, id);
  const Component = dateTime ? DateTimePicker : DatePicker;

  const styles = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <FormControl>
        <Component
          autoOk
          className={styles.input}
          color='secondary'
          format={dateTime ? 'yyyy/MM/dd H:m' : 'MM/dd/yyyy'}
          inputVariant='outlined'
          label={label}
          onChange={onChange}
          value={values[id] || null}
          variant='inline'
          {...rest}
        />
        <FormHelperText error htmlFor={`${id}`}>
          {isTouched && error}
        </FormHelperText>
      </FormControl>
    </ThemeProvider>
  );
};

FormDateInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setFieldValue: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.bool,
  }).isRequired,
  dateTime: PropTypes.bool,
};

FormDateInput.defaultProps = {
  dateTime: false,
};

export default FormDateInput;
