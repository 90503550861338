import React from 'react';

import { Checkbox, makeStyles, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CheckboxTile from 'components/CheckboxTile';
import GUIDE_ACTION_TYPES from 'config/constants/GUIDE_ACTION_TYPES';
import guide_messages from 'messages/guide_messages';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}));

const GuideAction = ({ currentStep, formik, isRoot, description }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = formik;

  const onCheck = (event, checked) => {
    const { value } = event.currentTarget;
    const newValues = { ...values.actions };
    const prevValues = newValues[value] || {};
    if (checked) {
      newValues[value] = { ...prevValues, guide_action_id: `${value}` };
    } else {
      newValues[value] = { ...prevValues, guide_action_id: '' };
    }

    setFieldValue('actions', newValues);
  };

  const freeTextChange = (event, id) => {
    const { value } = event.currentTarget;
    const newValues = { ...values.actions };
    newValues[id].freetext = value;
    setFieldValue('actions', newValues);
  };

  const checked = id => !!values.actions[id]?.guide_action_id;

  const styles = useStyles();

  if (isRoot) return <Typography variant='body1'>{description}</Typography>;
  return (
    <div className={styles.wrapper}>
      <Typography component='h2' variant='h3'>
        {currentStep?.heading}
      </Typography>
      <Typography variant='body1'>{currentStep?.description}</Typography>
      {currentStep?.actions
        ? currentStep.actions.map(({ id, heading, action_type, description: actionDescription }) => (
            <div key={id} className={styles.wrapper}>
              <CheckboxTile
                checked={checked(id)}
                control={<Checkbox />}
                description={actionDescription}
                expandable
                label={heading}
                onChange={onCheck}
                selected={checked(id)}
                value={id}
              />
              {action_type === GUIDE_ACTION_TYPES.FREETEXT ? (
                <TextField
                  disabled={!checked(id)}
                  label={t(...guide_messages.action_describe_placeholder)}
                  multiline
                  onChange={e => freeTextChange(e, id)}
                  value={values.actions[id]?.freetext || ''}
                  variant='outlined'
                />
              ) : null}
            </div>
          ))
        : null}
    </div>
  );
};

GuideAction.propTypes = {
  currentStep: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        heading: PropTypes.string,
        description: PropTypes.string,
      }),
    ).isRequired,
  }),
  formik: PropTypes.shape({
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  isRoot: PropTypes.bool.isRequired,
  description: PropTypes.string,
};

GuideAction.defaultProps = {
  description: null,
  currentStep: null,
};

export default GuideAction;
