import { useHistory, useLocation } from 'react-router-dom';

import PATHS from 'router/PATHS';

const useRedirectFromQuery = () => {
  const { search } = useLocation();
  const history = useHistory();

  return (redirectKey = 'redirect_url', fallback = PATHS.ROOT) => {
    const urLSearchParams = new URLSearchParams(search);
    const redirectUrl = urLSearchParams.get(redirectKey) || fallback;
    history.push(redirectUrl);
  };
};

export default useRedirectFromQuery;
