import React, { useEffect, useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CenteredGrid from 'components/CenteredGrid';
import FormPasswordInput from 'components/FormPasswordInput';
import { passwordTests } from 'config/validators/passwordRules';
import auth_messages from 'messages/auth_messages';
import validation_messages from 'messages/validation_messages';

const useStyles = makeStyles(theme => ({
  error: {
    minHeight: theme.spacing(3),
    fontSize: '0.875rem',
  },
  content: {
    marginBottom: theme.spacing(4),
  },
  errorLi: {
    color: theme.palette.error.main,
  },
  successLi: {
    color: theme.palette.text.secondaryColor,
  },
}));

const passwordValidationInitialState = {
  hasWhitespace: null,
  lowercaseLetter: null,
  uppercaseLetter: null,
  specialCharacter: null,
  digit: null,
  length: null,
};

const PasswordChangeForm = ({ FORM_ID, afterSubmit, updateFunction, onValidationChange, byToken }) => {
  const { t } = useTranslation();
  const [generalError, setGeneralError] = useState(null);
  const [passwordValidation, setPasswordValidation] = useState(passwordValidationInitialState);

  const onSubmit = async data => {
    const { error, onSuccess } = afterSubmit(await updateFunction(data));
    if (error) setGeneralError(error);
    if (!error && onSuccess) onSuccess();
  };

  const passwordMessages = {
    required: t(...validation_messages.password_required),
    match: t(...validation_messages.password_match),
  };

  const formik = useFormik({
    initialValues: {
      ...(!byToken && { current_password: '' }),
      new_password: '',
      re_new_password: '',
    },
    onSubmit,
    validationSchema: yup.object({
      ...(!byToken && { current_password: yup.string(passwordMessages.required).required(passwordMessages.required) }),
      new_password: yup.string(passwordMessages.required).required(passwordMessages.required),
      re_new_password: yup
        .string(passwordMessages.required)
        .oneOf([yup.ref('new_password'), null], passwordMessages.match)
        .required(passwordMessages.required),
    }),
    validate: ({ new_password }) => {
      if (!new_password) return null;
      const newState = passwordValidationInitialState;
      let isInvalid;

      Object.entries(passwordTests).forEach(([key, test]) => {
        const newValue = test(new_password);
        if (!newValue) isInvalid = true;
        newState[key] = newValue;
      });

      setPasswordValidation(newState);
      return isInvalid ? { new_password: "Password rules aren't fulfilled" } : null;
    },
  });

  useEffect(() => {
    onValidationChange(!formik.isValid || !formik.dirty);
  }, [formik.dirty, formik.isValid]);

  const styles = useStyles();

  const resolveClassName = field => {
    if (field === null) return '';
    return field ? styles.successLi : styles.errorLi;
  };

  return (
    <>
      <div className={styles.content}>
        <Typography variant='h6'>{t(...auth_messages.password_rules_heading)}</Typography>
        <Typography component='div' variant='body2'>
          <ul>
            <li className={resolveClassName(passwordValidation.length)}>{t(...validation_messages.errors.range)}</li>
            <li className={resolveClassName(passwordValidation.uppercaseLetter)}>{t(...validation_messages.errors.uppercase_letter)}</li>
            <li className={resolveClassName(passwordValidation.lowercaseLetter)}>{t(...validation_messages.errors.lowercase_letter)}</li>
            <li className={resolveClassName(passwordValidation.digit)}>{t(...validation_messages.errors.digit)}</li>
            <li className={resolveClassName(passwordValidation.specialCharacter)}>{t(...validation_messages.errors.special_character)}</li>
            <li className={resolveClassName(passwordValidation.hasWhitespace)}>{t(...validation_messages.errors.whitespaces)}</li>
          </ul>
        </Typography>
      </div>
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        <CenteredGrid gridGap={1} withoutPadding>
          {!byToken && (
            <FormPasswordInput formik={formik} id='current_password' label={`${t(...auth_messages.current_password)}`} required />
          )}
          <FormPasswordInput formik={formik} id='new_password' label={`${t(...auth_messages.password)}`} required />
          <FormPasswordInput formik={formik} id='re_new_password' label={`${t(...auth_messages.password_repeat)}`} required />
          <Typography align='center' className={styles.error} color='error'>
            {generalError}
          </Typography>
        </CenteredGrid>
      </form>
    </>
  );
};

PasswordChangeForm.propTypes = {
  FORM_ID: PropTypes.string.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  updateFunction: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func.isRequired,
  byToken: PropTypes.bool,
};

PasswordChangeForm.defaultProps = {
  byToken: false,
};

export default PasswordChangeForm;
