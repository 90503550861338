import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import FormTitle from 'components/FormTitle';
import themeObj from 'styles/theme';

const useStyles = makeStyles(theme => ({
  grid: {
    width: '100%',
    maxWidth: ({ width }) => theme.sizes.container[width],
    gridGap: ({ gridGap }) => theme.spacing(gridGap),
    display: 'grid',
    margin: '0 auto',
    paddingBottom: ({ withoutPadding, tight }) => !withoutPadding && theme.spacing(tight ? 3 : 6),
  },
}));

const CenteredGrid = ({
  component: Component,
  className,
  width,
  gridGap,
  children,
  title,
  withoutPadding,
  rightAdornment,
  tight,
  ...rest
}) => {
  const styles = useStyles({ width, gridGap, withoutPadding, tight });
  return (
    <Component className={clsx(styles.grid, className)} {...rest}>
      {title && <FormTitle rightAdornment={rightAdornment}>{title}</FormTitle>}
      {children}
    </Component>
  );
};

CenteredGrid.propTypes = {
  component: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.oneOf(Object.keys(themeObj.sizes.container)),
  children: PropTypes.oneOfType(PropTypes.node, PropTypes.arrayOf(PropTypes.node)).isRequired,
  gridGap: PropTypes.number,
  withoutPadding: PropTypes.bool,
  tight: PropTypes.bool,
  rightAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

CenteredGrid.defaultProps = {
  component: 'div',
  className: null,
  width: 'sm',
  gridGap: 0,
  title: null,
  withoutPadding: false,
  rightAdornment: undefined,
  tight: false,
};

export default CenteredGrid;
