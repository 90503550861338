import React from 'react';

import { Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import ButtonsGrid from 'components/_dialogs/_components/ButtonsGrid';
import CenteredGrid from 'components/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
// eslint-disable-next-line import/extensions
import FormDynamicSelect from 'components/FormDynamicSelect/FormDynamicSelect.tsx';
import FormTextInput from 'components/FormTextInput';
import { meetingsApi } from 'config/api/meetings';
import { questionsApi } from 'config/api/questions';
import general_messages from 'messages/general_messages';
import meeting_messages from 'messages/meeting_messages';
import questions_messages from 'messages/questions_messages';
import validation_messages from 'messages/validation_messages';
// eslint-disable-next-line import/no-cycle
import { COMMON_DIALOG_TYPES, useCommonDialogsContext } from 'reactContext/CommonDialogsContext';
import knowledgeAreaParser from 'services/_dictionaries/parsers/knowledgeAreaParser';

import DialogGrid from '../_components/DialogGrid';

import useStyles from './AskQuestionDialog.styles';

const FORM_ID = 'ask_question';
const FORM = {
  KNOWLEDGE_AREA: 'knowledge_area',
  QUESTION: 'text',
};

const AskQuestionDialog = ({ open, onClose }) => {
  const { t, i18n } = useTranslation();
  const { openCommonDialog } = useCommonDialogsContext();
  const mutation = useMutation(questionsApi.postQuestionSecurityOfficer, {
    onSuccess: () => {
      openCommonDialog(COMMON_DIALOG_TYPES.ASK_QUESTION_SUCCESS);
    },
  });

  const onSubmit = data => {
    mutation.mutate(data);
  };

  const formik = useFormik({
    initialValues: {
      [FORM.KNOWLEDGE_AREA]: undefined,
      [FORM.QUESTION]: '',
    },
    onSubmit,
    validationSchema: yup.object({
      [FORM.KNOWLEDGE_AREA]: yup.string().required(t(validation_messages.required)),
      [FORM.QUESTION]: yup.string().required(t(validation_messages.required)),
    }),
  });

  const styles = useStyles();
  return (
    <DialogGrid
      dialogActions={
        <ButtonsGrid>
          <ColoredButton customColor='none' disabled={mutation.isLoading} onClick={onClose} variant='outlined'>
            {t(...general_messages.cancel)}
          </ColoredButton>
          <ColoredButton customColor='secondary' disabled={mutation.isLoading} form={FORM_ID} type='submit' variant='outlined'>
            {t(...general_messages.send)}
          </ColoredButton>
        </ButtonsGrid>
      }
      maxWidth='xs'
      onClose={onClose}
      open={open}
      title={t(...questions_messages.dialog_title)}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        <CenteredGrid gridGap={1.5} tight>
          <Typography>{t(questions_messages.dialog_description)}</Typography>
          <FormDynamicSelect
            className={styles.dropdown}
            dataSource={{
              sourceFunction: meetingsApi.getKnowledgeAreas,
              dictionaryKey: `${FORM.KNOWLEDGE_AREA}_${i18n.language}`,
              parser: knowledgeAreaParser,
            }}
            formik={formik}
            id={FORM.KNOWLEDGE_AREA}
            label={t(meeting_messages.knowledge_area_label)}
          />
          <FormTextInput formik={formik} id={FORM.QUESTION} label={t(questions_messages.question_input_label)} multiline rows={3} />
        </CenteredGrid>
      </form>
    </DialogGrid>
  );
};

export default AskQuestionDialog;
