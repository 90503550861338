import React from 'react';

import { Checkbox } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import CheckboxTile from 'components/CheckboxTile';

const FormCheckboxTile = ({ label, formik, id, required, ...rest }) => {
  const { values, handleChange } = formik;
  const checked = get(values, id);
  const unifiedLabel = required ? `${label} * ` : label;

  return (
    <CheckboxTile
      checked={checked}
      control={<Checkbox />}
      label={unifiedLabel}
      onChange={handleChange(id)}
      required={required}
      selected={checked}
      {...rest}
    />
  );
};

FormCheckboxTile.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    handleChange: PropTypes.func,
  }).isRequired,
  required: PropTypes.bool,
};

FormCheckboxTile.defaultProps = {
  required: false,
};

export default FormCheckboxTile;
