import React, { useRef } from 'react';

import { Button, IconButton, Link, makeStyles, Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { isNil, get } from 'lodash';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  input: { visibility: 'hidden', display: 'inline', position: 'absolute', height: 0, width: 0, left: '-99999999999px' },
  button: {
    overflow: 'hidden',
    position: 'relative',
    zIndex: 1,
    width: '100%',
    minHeight: '57px',
    cursor: 'pointer',
    background: ({ progress }) => (isNil(progress) ? 'none' : theme.palette.grey[300]),
    border: ({ progress }) => (isNil(progress) ? `2px solid ${theme.palette.primary[400]}` : 'none'),
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14.5px 14px',
    color: ({ hasValue }) => (hasValue ? theme.palette.text.primary : theme.palette.text.secondary),
    '&:before': {
      display: ({ progress }) => (isNil(progress) ? 'none' : 'block'),
      transition: 'width 500ms linear',
      content: '""',
      background: theme.palette.primary[100],
      left: 0,
      width: ({ progress }) => `${progress}%`,
      height: '100%',
      position: 'absolute',
      zIndex: -1,
    },
  },
  icon: {
    color: theme.palette.secondary[900],
  },
  attachmentRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  attachmentName: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const resolveAccepted = types => {
  const result = [];
  types.forEach(type => {
    if (type === 'image') result.push('image/png', 'image/gif', 'image/jpeg');
    else result.push(type);
  });
  return result.join(', ');
};

const FormFileInput = ({ formik, id, label, acceptedTypes, buttonType, progress, multiple, disabled, listOnly, required }) => {
  const ref = useRef();
  const { setFieldValue, values } = formik;

  const openFilePicker = () => ref.current.click();

  const value = get(values, id, []);

  const getLabel = () => {
    if (value?.name && !multiple) return value.name;
    return required ? `${label} *` : label;
  };

  const onRemove = (e, index) => {
    const newValue = [...value];
    newValue.splice(index, 1);
    setFieldValue(id, newValue);
  };

  const onChange = e => {
    setFieldValue(id, multiple ? [...value, ...e.currentTarget.files] : e.currentTarget.files[0]);
  };

  const styles = useStyles({ hasValue: values[id]?.name, progress });
  return (
    <>
      {multiple && value && (
        <div>
          {value.map((file, index) => {
            const isRealFile = file instanceof File;
            const href = isRealFile ? URL.createObjectURL(file) : new URL(file.url, process.env.REACT_APP_API_URL).href;
            return (
              <div key={index} className={styles.attachmentRow}>
                <Link className={styles.attachmentName} href={href} target='blank'>
                  <AttachFileIcon className={styles.icon} fontSize='small' />
                  <span>{file.name}</span>
                </Link>
                {!listOnly && (
                  <IconButton onClick={e => onRemove(e, index)} size='small'>
                    <DeleteOutlinedIcon className={styles.icon} color='secondary' fontSize='small' />
                  </IconButton>
                )}
              </div>
            );
          })}
        </div>
      )}
      {!listOnly && (
        <>
          {buttonType || multiple ? (
            <Button color='secondary' onClick={openFilePicker} variant='outlined'>
              {getLabel()}
            </Button>
          ) : (
            <button className={styles.button} onClick={openFilePicker} type='button'>
              <>
                <Typography>{getLabel()}</Typography>
                {isNil(progress) ? <AttachFileIcon className={styles.icon} /> : <Typography>{progress}%</Typography>}
              </>
            </button>
          )}
          <input
            ref={ref}
            accept={resolveAccepted(acceptedTypes)}
            className={styles.input}
            disabled={!isNil(progress) || disabled || listOnly}
            id={id}
            multiple={multiple}
            onChange={onChange}
            type='file'
          />
        </>
      )}
    </>
  );
};

FormFileInput.propTypes = {
  id: PropTypes.string.isRequired,
  progress: PropTypes.number,
  label: PropTypes.string.isRequired,
  acceptedTypes: PropTypes.arrayOf(PropTypes.string),
  buttonType: PropTypes.bool,
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func,
    values: PropTypes.shape({}),
  }).isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  listOnly: PropTypes.bool,
  required: PropTypes.bool,
};

FormFileInput.defaultProps = {
  acceptedTypes: [],
  buttonType: false,
  progress: null,
  multiple: false,
  disabled: false,
  listOnly: false,
  required: false,
};

export default FormFileInput;
