const parseFiltersForRequest = (filters, fallbacks = {}) => {
  const filtersToRequest = Object.entries(filters).reduce((acc, [key, values]) => {
    const value = values.map(({ id }) => `${id}`);

    return {
      ...acc,
      ...(value.length && { [key]: value }),
    };
  }, {});
  Object.entries(fallbacks).forEach(([key, value]) => {
    if (!filtersToRequest.hasOwnProperty(key)) {
      filtersToRequest[key] = value;
    }
  });
  return filtersToRequest;
};

export default parseFiltersForRequest;
