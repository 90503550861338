import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import GuideTile from 'components/GuideTile';
import Loader from 'components/Loader';
import guidesEndpoints from 'config/api/guides';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import useApiCall from 'hooks/useApiCall';
import general_messages from 'messages/general_messages';

const useStyles = makeStyles(theme => ({
  tilesGrid: {
    display: 'grid',
    gridGap: theme.spacing(2.5),
  },
  tile: {
    background: theme.palette.secondary[50],
    display: 'grid',
    gridGap: theme.spacing(2),
    padding: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadiusLarge,
    justifyContent: 'stretch',
  },
}));

const GuideMeDialog = ({ onClose, open }) => {
  const { t } = useTranslation();
  const { apiCall, loading, cancelRequest } = useApiCall();
  const [guides, setGuides] = useState([]);

  const getGuides = async () => {
    const { data } = await apiCall(guidesEndpoints.getIncidentGuides());
    setGuides(data);
  };

  useEffect(() => {
    getGuides();
    return cancelRequest;
  }, []);

  const styles = useStyles();

  return (
    <DialogGrid onClose={onClose} open={open} title={t(...general_messages.guide_me)}>
      <div className={styles.tilesGrid}>
        {loading ? (
          <Loader inner />
        ) : (
          guides &&
          guides.map(({ heading, description, language, service_area, id, version_id }) => (
            <GuideTile
              key={id}
              description={description}
              id={id}
              language={language}
              metadata={{ version_id }}
              object_content_type={CONTENT_TYPES.GUIDE}
              onClose={onClose}
              service_area={service_area}
              title={heading}
            />
          ))
        )}
      </div>
    </DialogGrid>
  );
};

GuideMeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default GuideMeDialog;
