import React, { useCallback, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { set } from 'lodash';
import { useSnackbar } from 'notistack';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import ColoredButton from 'components/ColoredButton/ColoredButton';
import QUERY_KEYS from 'config/api/QUERY_KEYS';
import { TemplateSection } from 'config/api/workplaces/_types';
import { prepareTemplateSectionForBE } from 'config/api/workplaces/parsers';
import { workplacesApi } from 'config/api/workplaces/workplaces';
import isTouchDevice from 'helpers/isTouchDevice';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';
import workplace_messages from 'messages/workplace_messages';
import { useConfirmationModalContext } from 'reactContext/ConfirmationModalContext';
import PATHS from 'router/PATHS';

import TextButton from '../TextButton/TextButton';

import WorkplaceTemplateSection from './_components/WorkplaceTemplateSection/WorkplaceTemplateSection';
import useStyles from './WorkplaceTemplateEditor.styles';

export type Content = { name: string; id: string; order: number };

type Error = {
  response: { data: string[] };
};

type Props = {
  initialTemplate?: TemplateSection[];
};

type FormInput = {
  section: {
    [key: string]: string;
  };
  content: {
    [key: string]: string;
  };
};
const swapElements = (array: any[], index1: number, index2: number): any[] => {
  const _array = [...array];
  const temp = _array[index1];
  // eslint-disable-next-line no-param-reassign
  _array[index1] = _array[index2];
  // eslint-disable-next-line no-param-reassign
  _array[index2] = temp;
  return _array;
};

export const ItemTypes = {
  SECTION: 'section',
  CONTENT: 'content',
};

const WorkplaceTemplateEditor: React.FC<Props> = ({ initialTemplate }) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [template, setTemplate] = useState<TemplateSection[]>(initialTemplate || []);
  // @ts-ignore
  const { showConfirmationModal } = useConfirmationModalContext();

  const onError = (err: Error) =>
    Object.values(err.response.data).forEach(errorMessage => enqueueSnackbar(errorMessage as string, { variant: 'error' }));

  const { mutateAsync: updateTemplateMutation } = useMutation(
    QUERY_KEYS.UPDATE_WORKPLACE_TEMPLATE,
    workplacesApi.updateWorkplaceSectionTemplate,
    { onError },
  );
  const { mutateAsync: createTemplateMutation } = useMutation(
    QUERY_KEYS.CREATE_WORKPLACE_TEMPLATE,
    workplacesApi.createWorkplaceSectionTemplate,
    { onError },
  );
  const { mutateAsync: deleteTemplateMutation } = useMutation(
    QUERY_KEYS.DELETE_WORKPLACE_TEMPLATE,
    workplacesApi.deleteWorkplaceSectionTemplate,
  );

  useEffect(() => {
    if (initialTemplate) setTemplate(initialTemplate);
  }, [initialTemplate]);

  const validate = useCallback(
    ({ section, content }: FormInput) => {
      const errors: { [key: string]: string[] } = {};
      Object.entries(content).forEach(([key, value]) => {
        if (!value) errors[`content.${key}`] = t(validation_messages.required);
      });
      Object.entries(section).forEach(([key, value]) => {
        if (!value) errors[`section.${key}`] = t(validation_messages.required);
      });
      return errors;
    },
    [t],
  );

  const onSubmit = async (formData: FormInput) => {
    const { toCreate, toUpdate, toDelete } = prepareTemplateSectionForBE(formData, template, initialTemplate || []);
    await Promise.all([
      ...toUpdate.map(section => updateTemplateMutation(section)),
      ...toCreate.map(section => createTemplateMutation(section)),
      ...toDelete.map(id => deleteTemplateMutation(id)),
    ]);
    await queryClient.invalidateQueries(QUERY_KEYS.GET_WORKPLACE_TEMPLATE);
    await enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
    setTimeout(() => history.push(PATHS.WORKPLACES), 500);
  };

  const onCancel = () => {
    history.goBack();
  };

  const formik = useFormik<FormInput>({
    onSubmit,
    initialValues: {
      section: {},
      content: {},
    },
    validate,
  });

  useEffect(() => {
    // update formik state
    if (initialTemplate) {
      const valuesToSet = {
        section: {},
        content: {},
      };
      initialTemplate.forEach(({ id: sectionId, sectionName, contentElements }) => {
        set(valuesToSet, `section.${sectionId}`, sectionName);
        contentElements.forEach(({ id: contentId, name }) => {
          set(valuesToSet, `content.${contentId}`, name);
        });
      });
      formik.setValues(valuesToSet as FormInput);
    }
  }, [initialTemplate]); // check if it's enough or every template change needs it

  const onAddSection = () => {
    const _id = uuid();
    setTemplate(prev => [...prev, { sectionName: '', id: _id, contentElements: [], order: template.length + 1 }]);
    formik.setFieldValue(`section.${_id}`, '');
  };
  const onAddContentInSection = (sectionId: string) => () => {
    const _id = uuid();
    setTemplate(prev => {
      const _template = [...prev];
      const indexToChange = _template.findIndex(({ id }) => id === sectionId);
      const elementToChange = _template[indexToChange];
      _template.splice(indexToChange, 1, {
        ...elementToChange,
        contentElements: [...elementToChange.contentElements, { name: '', id: _id, order: elementToChange.contentElements.length + 1 }],
      });
      return _template;
    });
    formik.setFieldValue(`content.${_id}`, '');
  };

  const removeFromFormik = (key: string) => {
    formik.setFieldValue(key, undefined);
    formik.unregisterField(key);
  };

  const onDeleteSection = (section: TemplateSection) => async () => {
    if (section.contentElements.length > 0) {
      const decision = await showConfirmationModal({
        title: t(workplace_messages.alerts.removeSectionChildTitle),
        body: t(workplace_messages.alerts.removeSectionChildBody, { sectionName: section.sectionName }),
      });
      if (!decision) return;
    }
    const isFromBE = () => {
      if (!initialTemplate) return false;
      return initialTemplate.find(({ id }) => id === section.id);
    };
    if (isFromBE()) {
      const decision = await showConfirmationModal({
        title: t(workplace_messages.alerts.removeSectionWithSavedDataTitle),
        body: t(workplace_messages.alerts.removeSectionWithSavedDataBody, { sectionName: section.sectionName }),
      });
      if (!decision) return;
    }

    setTemplate(prev => prev.filter(({ id }) => section.id !== id));
    removeFromFormik(`section.${section.id}`);
  };
  const onDeleteContent = (sectionId: string, content: Content) => async () => {
    const isFromBE = () => {
      if (!initialTemplate) return false;
      const section = initialTemplate.find(({ id }) => id === sectionId);
      if (!section) return false;
      return !!section.contentElements.find(({ id }) => id === content.id);
    };
    if (isFromBE()) {
      const decision = await showConfirmationModal({
        title: t(workplace_messages.alerts.removeContentWithSavedDataTitle),
        body: t(workplace_messages.alerts.removeContentWithSavedDataBody, { contentName: content.name }),
      });
      if (!decision) return;
    }

    setTemplate(prev => {
      const _template = [...prev];
      const indexToChange = _template.findIndex(({ id }) => id === sectionId);
      const elementToChange = _template[indexToChange];
      _template.splice(indexToChange, 1, {
        ...elementToChange,
        contentElements: elementToChange.contentElements.filter(({ id }) => id !== content.id),
      });
      return _template;
    });
    removeFromFormik(`content.${content.id}`);
  };

  const onDNDSection = (dragIndex: number, hoverIndex: number) => {
    setTemplate(prev => swapElements(prev, dragIndex, hoverIndex));
  };

  const onDNDContent = (sectionId: string) => (dragIndex: number, hoverIndex: number) => {
    setTemplate(prev => {
      const _template = [...prev];
      const indexToChange = _template.findIndex(({ id }) => id === sectionId);
      const elementToChange = _template[indexToChange];
      _template.splice(indexToChange, 1, {
        ...elementToChange,
        contentElements: swapElements(elementToChange.contentElements, dragIndex, hoverIndex),
      });
      return _template;
    });
  };

  const styles = useStyles({ isEmpty: template.length === 0 });

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend} context={window}>
        {template.map((section, index) => (
          <WorkplaceTemplateSection
            key={section.id}
            contentElements={section.contentElements}
            formik={formik}
            index={index}
            onAddContentInSection={onAddContentInSection(section.id)}
            onDeleteContent={onDeleteContent}
            onDeleteSection={onDeleteSection(section)}
            onDNDContent={onDNDContent(section.id)}
            onDNDSection={onDNDSection}
            sectionId={section.id}
          />
        ))}
        <div className={styles.footer}>
          <TextButton onClick={onAddSection}>{t(workplace_messages.template.addSection)}</TextButton>
          <div className={styles.buttonsWrapper}>
            {/* @ts-ignore */}
            <ColoredButton customColor='none' onClick={onCancel} variant='outlined'>
              {t(general_messages.cancel)}
            </ColoredButton>
            {/* @ts-ignore */}
            <ColoredButton customColor='secondary' type='submit' variant='outlined'>
              {t(general_messages.save)}
            </ColoredButton>
          </div>
        </div>
      </DndProvider>
    </form>
  );
};

export default WorkplaceTemplateEditor;
