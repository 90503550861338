import React, { useEffect } from 'react';

import { Box, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ColoredButton from 'components/ColoredButton';
import authEndpoints from 'config/api/auth';
import ROLES from 'config/constants/ROLES';
import useApiCall from 'hooks/useApiCall';
import usePermissions from 'hooks/usePermissions';
import useQueryParams from 'hooks/useQueryParams';
import auth_messages from 'messages/auth_messages';
import PATHS from 'router/PATHS';
import userDataStorage from 'storages/userDataStorage';

import useStyles from './PaymentPage.styles';

const PaymentPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { queryParams } = useQueryParams();
  const { apiCall, loading } = useApiCall();
  const [isAccountOwner] = usePermissions([ROLES.ORGANIZATION.ACCOUNT_OWNER]);
  const { enqueueSnackbar } = useSnackbar();

  const verifyPayment = async () => {
    const { data, status } = await apiCall(authEndpoints.updatePayment());
    if (status < 300) {
      userDataStorage.update({ hasPaymentMethod: data.has_payment_method });
      enqueueSnackbar(t(...auth_messages.payment.success), { variant: 'success' });
      history.push(PATHS.ROOT);
    } else {
      enqueueSnackbar(t(...auth_messages.payment.failed), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (queryParams.stripe_success) verifyPayment();
    if (queryParams.stripe_failure) enqueueSnackbar(t(...auth_messages.payment.failed), { variant: 'error' });
  }, [queryParams.stripe_success, queryParams.stripe_failure]);

  const goToStripe = async () => {
    const { data, status } = await apiCall(authEndpoints.getStripeUrl());
    if (status < 300) {
      window.location.replace(data.url);
    }
  };

  const styles = useStyles();
  return (
    <Box alignItems='center' display='flex' height='100vh' justifyContent='center' width='100%'>
      <div className={styles.wrapper}>
        <Typography align='center' component='h2' variant='h2'>
          {isAccountOwner
            ? t(...auth_messages.payment.configuration_needed_info_for_owner)
            : t(...auth_messages.payment.configuration_needed_info_for_member)}
        </Typography>
        {isAccountOwner && (
          <ColoredButton customColor='secondary' onClick={goToStripe} showLoader={loading}>
            {t(...auth_messages.payment.add_payment_method)}
          </ColoredButton>
        )}
      </div>
    </Box>
  );
};

export default PaymentPage;
