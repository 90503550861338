import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  blockWrapper: {
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  goBack: {
    marginRight: theme.spacing(1),
  },
}));
