const workplace_messages = {
  workplaces_page_header: ['workplaces>>workplaces_page_header', 'Workplaces ({{workplacesCount}})'],
  buttons: {
    new_workplace: ['workplace>>buttons>>new_workplace', 'New workplace'],
    edit_categories: ['workplace>>buttons>>edit_categories', 'Edit categories'],
  },
  table_header: {
    workplace: ['workplaces>>table_header>>workplace', 'Workplace'],
    responsible: ['workplaces>>table_header>>responsible', 'Responsible'],
    localization: ['workplaces>>table_header>>localization', 'Localization'],
  },
  remove: {
    confirmation_title: ['workplaces>>remove>>confirmation_title', 'Removing workplace'],
    confirmation_body: [
      'workplaces>>remove>>confirmation_body',
      'Are you sure you want to delete "{{workplaceName}}" workplace? This workplace is default for {{usersNumber}} users',
    ],
  },
  help: {
    title: ['workplaces>>help>>tile', 'Workplaces - how it works?'],
    body: [
      'workplaces>>help>>body',
      'It works perfectly - lorem ipsum, description is needed here, and <b>HTML</b> is also possible, so we need some more description here please',
    ],
  },
  newWorkplace: {
    dialogTitle: ['workplaces>>newWorkplace>>dialogTitle', 'New workplace'],
  },
  editWorkplace: {
    dialogTitle: ['workplaces>>editWorkplace>>dialogTitle', 'Edit workplace'],
  },
  singleWorkplace: {
    pageTitle: ['workplaces>>singleWorkplace>>pageTitle', 'Workplace'],
    responsibles: ['workplaces>>singleWorkplace>>responsibles', 'Workplace responsible'],
    emptyResponsibles: ['workplaces>>singleWorkplace>>emptyResponsibles', 'Not assigned yet'],
    emptySection: ['workplaces>>singleWorkplace>>emptySection', '---'],
  },
  form: {
    basicSectionName: ['workplaces>>form>>basicSectionName', 'Workplace info'],
    workplaceName: ['workplaces>>form>>workplaceName', 'Workplace name'],
    localization: ['workplaces>>form>>localization', 'Localization'],
    responsibles: ['workplaces>>form>>responsibles', 'Responsibles'],
  },
  template: {
    pageTitle: ['workplaces>>template>>pageTitle', 'Information category'],
    addSection: ['workplaces>>template>>addSection', 'Add section'],
    sectionLabel: ['workplaces>>template>>sectionLabel', 'Section name'],
    contentLabel: ['workplaces>>template>>contentLabel', 'Content'],
  },
  alerts: {
    removeSectionWithSavedDataTitle: ['workplaces>>alerts>>removeSectionWithSavedDataTitle', 'Data loss warning'],
    removeSectionWithSavedDataBody: [
      'workplaces>>alerts>>removeSectionWithSavedDataBody',
      'Data saved in every workspace in section "{{sectionName}}" and nested content fields will be lost, do you want to continue? ',
    ],
    removeContentWithSavedDataTitle: ['workplaces>>alerts>>removeContentWithSavedDataTitle', 'Data loss warning'],
    removeContentWithSavedDataBody: [
      'workplaces>>alerts>>removeContentWithSavedDataBody',
      'Data saved in every workspace in content "{{contentName}}" will be lost, do you want to continue? ',
    ],
    removeSectionChildTitle: ['workplaces>>alerts>>removeSectionChildTitle', 'Data loss warning'],
    removeSectionChildBody: [
      'workplaces>>alerts>>removeSectionChildBody',
      'You are trying to remove section with nested content fields, fields will be also removed. Do you want to continue?',
    ],
  },
};

export default workplace_messages;
