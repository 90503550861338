import React, { useState } from 'react';

import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DialogGrid from 'components/_dialogs/_components/DialogGrid';
import CenteredGrid from 'components/CenteredGrid';
import PasswordChangeForm from 'components/PasswordChangeForm';
import authEndpoints from 'config/api/auth';
import useApiCall from 'hooks/useApiCall';
import auth_messages from 'messages/auth_messages';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';

const FORM_ID = 'ChangePasswordDialog';

const ChangePasswordDialog = ({ onClose, open }) => {
  const { t } = useTranslation();
  const { apiCall, sending } = useApiCall();
  const { enqueueSnackbar } = useSnackbar();
  const [isValid, setIsValid] = useState(null);

  const submit = ({ current_password, new_password }) =>
    apiCall(authEndpoints.set_password(), {
      data: { current_password, new_password },
    });

  const onSuccess = () => {
    enqueueSnackbar(t(...auth_messages.password_changed), { variant: 'success' });
    onClose();
  };

  const afterSubmit = ({ status }) => {
    let error;
    if (status === 400) error = t(...validation_messages.errors.credentials);
    return { error, onSuccess };
  };

  return (
    <DialogGrid
      color='secondary'
      dialogActions={
        <CenteredGrid gridGap={2} withoutPadding>
          <Button color='primary' disabled={isValid} form={FORM_ID} type='submit' variant='contained'>
            {t(...auth_messages.request)}
          </Button>
          <Button color='secondary' onClick={onClose} variant='outlined'>
            {t(...general_messages.cancel)}
          </Button>
        </CenteredGrid>
      }
      onClose={onClose}
      open={open}
      sending={sending}
      title={t(...general_messages.change_password)}
    >
      <PasswordChangeForm afterSubmit={afterSubmit} FORM_ID={FORM_ID} onValidationChange={setIsValid} updateFunction={submit} />
    </DialogGrid>
  );
};

ChangePasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ChangePasswordDialog;
