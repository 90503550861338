import axios from 'axios';

import METHOD from 'config/api/_methods';
import createUrlWithParams from 'helpers/createUrlWithParams';

import { parseRecommendedItemForFE } from './general/parsers';

const commonEndpoints = {
  serviceAreas: () => ({ url: '/api/v1/service_area/', method: METHOD.GET }),
  serviceAreaInfo: service_area_id => ({ url: `/api/v1/service_area/${service_area_id}/`, method: METHOD.GET }),
  tags: () => ({ url: '/api/v1/tag/', method: METHOD.GET }),
  search: params => ({ url: createUrlWithParams('/api/v1/search/', params), method: METHOD.POST }),
  searchAll: params => ({ url: createUrlWithParams('/api/v1/search_all/', params), method: METHOD.GET }),
  activities: params => ({ url: createUrlWithParams('/api/v1/activities/', params), method: METHOD.POST }),
  getBusinessTypes: () => ({ url: '/api/v1/business_types/', method: METHOD.GET }),
  getAuthors: () => ({ url: '/api/v1/authors/', method: METHOD.POST }),
  getAdvisers: params => ({ url: createUrlWithParams('/api/v1/advisers/', params), method: METHOD.GET }),
};

// usage for react-query
export const commonApi = {
  getEnvSettings: () => axios.get('/api/v1/common/get_current_environment_settings'),
  getRecommendedContent: async () => {
    const result = await axios.get('/api/v1/recommended_content');
    return parseRecommendedItemForFE(result.data);
  },
};

export default commonEndpoints;
