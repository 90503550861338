import React from 'react';

import ArticleTile from 'components/ArticleTile';
import GuideTile from 'components/GuideTile';
import SelfTestTile from 'components/SelfTestTile/SelfTestTile';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import type { RecommendedSectionItem } from 'types/General';

type Props = { data: RecommendedSectionItem };

// TODO we need completed at and progress id for guides

const RecommendedItem: React.FC<Props> = ({ data }) => {
  const { objectContentType } = data;

  const resolveContentItem = () => {
    if (objectContentType.includes(CONTENT_TYPES.GUIDE) || objectContentType.includes(CONTENT_TYPES.INCIDENT_GUIDE)) {
      return (
        // @ts-ignore
        <GuideTile
          description={data.description}
          heading={data.heading}
          id={data.id}
          language={data.language}
          metadata={data.metadata}
          object_content_type={data.objectContentType}
          service_area={data.serviceArea}
          title={data.title}
        />
      );
    }
    if (objectContentType === CONTENT_TYPES.SELF_TEST) {
      return <SelfTestTile data={data} />;
    }
    if (objectContentType === CONTENT_TYPES.ARTICLE) {
      return <ArticleTile data={data} />;
    }
    throw Error('Incorrect content type');
  };

  return <>{resolveContentItem()}</>;
};

export default RecommendedItem;
