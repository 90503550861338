import React from 'react';

import { Typography, Paper, ButtonBase, IconButton } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { ContentItem } from 'config/api/selfTests/_types';
import self_test_messages from 'messages/self_test_messages';
import PATHS from 'router/PATHS';

import { contentTypeResolver } from '../../config/translatableConstants/TRANSLATABLE_CONTENT_TYPES';

import useStyles from './SelfTestTile.styles';

const SelfTestTileLoader = () => {
  const styles = useStyles();
  return (
    <Paper className={styles.tile} elevation={0}>
      <Skeleton height='2rem' width='60%' />
      <Skeleton height='4rem' width='100%' />
      <div>
        <Skeleton height='1.35rem' width='40%' />
        <Skeleton height='1.35rem' width='10%' />
      </div>
    </Paper>
  );
};

type Props = {
  data: ContentItem;
};

const SelfTestTile: React.FC<Props> = ({ data }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const { heading, description, metadata, language, serviceArea, objectContentType, id } = data;

  return (
    <ButtonBase className={styles.tile} color='secondary' disableRipple>
      <Link
        className={styles.selfTestAnchor}
        to={generatePath(`${PATHS.SELF_TESTS}/:selfTestRootId/:selfTestVersionId/`, {
          selfTestRootId: id,
          selfTestVersionId: metadata.versionId || data.versionId,
        })}
      >
        <Typography align='left' component='h2' variant='h3'>
          {heading}
        </Typography>
        <Typography align='left' className={styles.description} component='p' variant='body1'>
          {description}
        </Typography>
        <div className={styles.footer}>
          <div className={styles.footerItems}>
            {t(self_test_messages.get_started)}
            <IconButton className={styles.icon}>
              <ArrowForward />
            </IconButton>
          </div>
          <div className={styles.footerItems}>
            <Typography className={styles.footerFont} component='span'>{`${t(contentTypeResolver(objectContentType))}: ${t(
              serviceArea.nameTranslationKey,
              serviceArea.name,
            )}`}</Typography>
            <Typography className={styles.footerFont} component='span'>
              {language.languageCode}
            </Typography>
          </div>
        </div>
      </Link>
    </ButtonBase>
  );
};

export { SelfTestTileLoader };
export default SelfTestTile;
