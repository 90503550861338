import React, { useMemo } from 'react';

import { FormControl, FormHelperText, Input, InputLabel, OutlinedInput } from '@material-ui/core';
import { get } from 'lodash';
import PropTypes from 'prop-types';

const FormTextInput = ({
  formik,
  id,
  label,
  variant,
  disabled,
  type,
  multiline,
  color,
  blockedChars,
  inputRef,
  inputProps,
  required,
  readonly,
  readonlyInfo,
  rows,
  size,
  className,
}) => {
  const { touched, errors, handleChange, values, handleBlur } = formik;
  const InputComponent = variant === 'outlined' ? OutlinedInput : Input;

  const value = get(values, id, '');
  const error = get(errors, id);
  const isTouched = get(touched, id);
  const onChange = e => {
    const { value: inputValue } = e.target;
    if (!blockedChars || !inputValue) handleChange(e);
    else {
      e.target.value = inputValue.replace(blockedChars, '');
      handleChange(e);
    }
  };

  const unifiedLabel = useMemo(() => {
    if (!label) return '';
    return required ? `${label} * ` : label;
  }, [label]);

  return (
    <FormControl className={className} error={isTouched && Boolean(error)} size={size} variant={variant}>
      {unifiedLabel && (
        <InputLabel color={color} htmlFor={`${id}`}>
          {unifiedLabel}
        </InputLabel>
      )}
      <InputComponent
        color={color}
        disabled={disabled || readonly}
        error={isTouched && Boolean(error)}
        fullWidth
        helperText={isTouched && error}
        id={`${id}`}
        inputProps={{ 'data-cy': `${id}_input`, ...inputProps }}
        inputRef={inputRef}
        label={unifiedLabel}
        multiline={multiline}
        name={`${id}`}
        onBlur={handleBlur}
        onChange={onChange}
        rows={rows}
        type={type}
        value={value}
        variant={variant}
      />
      <FormHelperText data-cy={`${id}_input_error`} error={isTouched && Boolean(error)} htmlFor={`${id}`}>
        {isTouched && error}
        {readonly && readonlyInfo}
      </FormHelperText>
    </FormControl>
  );
};

FormTextInput.propTypes = {
  blockedChars: PropTypes.instanceOf(RegExp),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  inputRef: PropTypes.shape({}),
  formik: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.shape({}),
  }).isRequired,
  inputProps: PropTypes.shape({}),
  readonlyInfo: PropTypes.string,
  readonly: PropTypes.bool,
  rows: PropTypes.number,
  size: PropTypes.string,
  className: PropTypes.string,
};

FormTextInput.defaultProps = {
  variant: 'outlined',
  disabled: false,
  multiline: false,
  type: 'text',
  color: 'primary',
  blockedChars: null,
  inputRef: null,
  inputProps: {},
  required: false,
  readonlyInfo: null,
  readonly: false,
  rows: undefined,
};

export default FormTextInput;
