import React, { useEffect, useState } from 'react';

import { Container } from '@material-ui/core';
import { lastDayOfMonth } from 'date-fns';

import usersEndpoints from 'config/api/users';
import useApiCall from 'hooks/useApiCall';
import userDataStorage from 'storages/userDataStorage';

import OrganizationAdministrationInfo from './_components/OrganizationAdministrationInfo';
import OrganizationAdministrationManage from './_components/OrganizationAdministrationManage';
import useStyles from './OrganizationAdministrationPage.styles';

const OrganizationAdministrationPage = () => {
  const { organization } = userDataStorage.get() || {};
  const { apiCall, loading } = useApiCall();
  const [organizationData, setOrganizationData] = useState(null);
  const [statistics, setStatistics] = useState(null);

  const getOrganizationData = async () => {
    const { data } = await apiCall(usersEndpoints.getOrganization(organization));
    setOrganizationData({
      name: data.name,
      organization_number: data.organization_number,
      country: data.country,
      campaign_code: data.campaign_code,
      ...data.infos,
    });
  };

  const getStatistics = async () => {
    const begin = new Date();
    begin.setDate(1);
    begin.setMonth(0);
    const end = new Date();
    end.setDate(lastDayOfMonth(new Date()).getDate());
    const params = {
      'begin-date__gte': begin.toISOString(),
      'end-date__lte': end.toISOString(),
    };
    const { data } = await apiCall(usersEndpoints.getOrganizationStats(params));
    setStatistics(data);
  };

  useEffect(() => {
    getOrganizationData();
    getStatistics();
  }, []);

  const styles = useStyles();
  return (
    <Container>
      <div className={styles.container}>
        <OrganizationAdministrationInfo data={statistics} loading={loading} />
        <OrganizationAdministrationManage data={organizationData} loading={loading} organizationId={organization} />
      </div>
    </Container>
  );
};

export default OrganizationAdministrationPage;
