import React from 'react';

import stringShortener from 'helpers/stringShortener';
import uppercaseFirstLetter from 'helpers/uppercaseFirstLetter';

const relatedContentResultComponent = ({ label, friendly_id, object_content_type }) => (
  <>
    <span>ID {friendly_id}</span>
    <span>{uppercaseFirstLetter(object_content_type)}</span>
    <span>{stringShortener(label, 40)}</span>
  </>
);

export default relatedContentResultComponent;
