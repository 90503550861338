/**
 *
 * @param {String} string
 * @param {Number = 0} maxLength
 * @returns {string}
 */
const stringShortener = (string, maxLength = 0) => {
  if (!string || Number.isNaN(maxLength)) return '';
  if (typeof string !== 'string') return '';

  return string.length > maxLength ? `${string.substring(0, maxLength).trim()}...` : string;
};

export default stringShortener;
