const guide_messages = {
  guide_page_title: ['guide>>guide_page_title', 'We are here to help if you get stuck'],
  delete_step: ['guide>>labels>>delete_step', 'Delete this step'],
  heading: ['guide>>heading', 'Heading'],
  description: ['guide>>description', 'Description'],
  actions: ['guide>>actions', 'Actions'],
  guide: ['guide>>guide', 'Guide'],
  guide_specifics: ['guide>>guide_specifics', 'Guide specifics'],
  incident_guide: ['guide>>incident_guide', 'Incident guide'],
  step: ['guide>>step', 'Step'],
  steps: ['guide>>steps', 'Steps'],
  actions_heading: ['guide>>actions>>heading', 'Heading of action'],
  type_of_action: ['guide>>type_of_action', 'Type of action'],
  action_describe_placeholder: ['guide>>action_describe_placeholder', 'Describe action as well as you can'],
  loose_step_progress_title: ['guide>>loose_step_progress_title', 'Clear actions'],
  loose_step_progress: ['guide>>loose_step_progress', 'If you go back, you‘ll loose all documented actions. Continue?'],
  comments_placeholder: ['guide>>comments_placeholder', 'Add notes and comments here (optional)'],
  report_incident: ['guides>>report_incident', 'Report incident'],
  end_guide: ['guides>>end_guide', 'End guide'],
  nested_elements: ['guide>>nested_elements', 'Nested elements'],
  incident_steps_header: ['guide>>incident_steps_header', 'Steps taken during incident guide'],
  incident_management_log: ['guide>>incident_management_log', 'Incident management log'],
  add_incident_log_title: ['guide>>add_incident_log_title', 'Add incident log entry'],
  submit_incident: ['guide>>submit_incident', 'Submit incident'],
  go_to_next_phase: ['guide>>go_to_next_phase', 'Go to next phase'],
  continue_guide: ['guide>>continue_guide', 'Continue guide'],
  go_to_report: ['guide>>go_to_report', 'Go to the report'],
  report_template: ['guide>>report_template', 'Report template'],
  nested_elements_question: [
    'guide>>nested_elements_question',
    `If you delete this step, all child steps will be deleted as well. Continue?`,
  ],
  remove_step_confirmation: ['guide>>remove_step', 'Remove step'],
  remove_step_confirmation_question: ['guide>>remove_step_question', 'Are you sure you want to remove this step?'],
  action_types: {
    freetext: ['guide>>action_types>>freetext', 'Freetext'],
    general: ['guide>>action_types>>general', 'General action'],
  },
  types: {
    incident_guide: ['guide>>type>>incident_guide', 'Incident guide'],
    guide: ['guide>>type>>guide', 'Guide'],
  },
  status: {
    completed: ['guide>>status>>completed', 'Completed'],
    in_progress: ['guide>>status>>in_progress', 'In progress'],
  },
  report_information_labels: {
    name: ['guide>>report_information_labels>>name', 'Name'],
    categorization: ['guide>>report_information_labels>>categorization', 'Categorization'],
    status: ['guide>>report_information_labels>>status', 'Status'],
    start: ['guide>>report_information_labels>>start', 'Start'],
    last_edit: ['guide>>report_information_labels>>last_edit', 'Last edit'],
    type: ['guide>>report_information_labels>>type', 'Type'],
  },
  incident_phases: {
    identify_and_report: ['guide>>incident_phases>>identify_and_report', 'Identify and report'],
    receive: ['guide>>incident_phases>>receive', 'Receive'],
    manage_the_incident: ['guide>>incident_phases>>manage_the_incident', 'Manage the incident'],
    close_and_send_feedback: ['guide>>incident_phases>>close_and_send_feedback', 'Close and send feedback'],
    closed: ['guide>>incident_phases>>closed', 'Closed'],
  },
  open_report: ['guide>>open_report', 'Open report'],
  close_incident_report: ['guide>>close_incident_report', 'Close this incident report'],
  is_gdpr_label: ['guide>>is_gdpr_label', 'Has incident exposed information about an individual (GDPR)'],
  is_ongoing_label: ['guide>>is_ongoing_label', 'Is the incident ongoing'],
  priority_label: ['guide>>priority_label', 'What is the priority of incident'],
  priorities: {
    low: ['guide>>priorities>>low', 'Low'],
    medium: ['guide>>priorities>>medium', 'Medium'],
    high: ['guide>>priorities>>high', 'High'],
    highest: ['guide>>priorities>>highest', 'Highest'],
  },
};

export default guide_messages;
