import StorageService from 'services/StorageService';

const parser = ({ profile, organization_roles, organizations, groups, ...rest }) => {
  const { email, first_name, last_name } = profile;
  return {
    ...rest,
    email,
    first_name,
    last_name,
    roles: organization_roles.map(({ role }) => role),
    organization: organizations[0]?.id,
    ps_roles: groups.map(({ name }) => name),
  };
};

const userDataStorage = new StorageService(process.env.REACT_APP_USER_DATA_NAME, { parser });

export default userDataStorage;
