import React, { useState } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import FormTextInput from 'components/FormTextInput';
import SimpleFormLayout from 'components/SimpleFormLayout';
import authEndpoints from 'config/api/auth';
import emailValidator from 'config/validators/emailValidator';
import useApiCall from 'hooks/useApiCall';
import auth_messages from 'messages/auth_messages';
import validation_messages from 'messages/validation_messages';
import PATHS from 'router/PATHS';

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const [generalError, setGeneralError] = useState(null);

  const { apiCall, sending } = useApiCall();
  const history = useHistory();

  const onSubmit = async ({ email }) => {
    const data = { email: email.toLowerCase() };
    const { status } = await apiCall(authEndpoints.reset_password_request(), { data });
    if (status === 204)
      history.push({
        pathname: PATHS.AUTH_PASSWORD_RESET_LINK_SENT,
        state: data,
      });
  };

  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit,
    validationSchema: yup.object({
      email: emailValidator({
        required: t(...validation_messages.email_required),
        valid: t(...validation_messages.email_valid),
      }),
    }),
  });

  const onFormChange = () => setGeneralError(null);

  return (
    <SimpleFormLayout
      disabled={!formik.isValid}
      generalError={generalError}
      onChange={onFormChange}
      onSubmit={formik.handleSubmit}
      sending={sending}
      submitLabel={t(...auth_messages.request)}
      title={t(...auth_messages.reset_password)}
    >
      <FormTextInput formik={formik} id='email' label={t(...auth_messages.email)} required type='email' />
    </SimpleFormLayout>
  );
};

ForgotPasswordForm.propTypes = {};

export default ForgotPasswordForm;
