import React, { useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import LinkIcon from '@material-ui/icons/Link';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DescriptionText from 'components/DescriptionText';
import MobileGuttersContainer from 'components/MobileGuttersContainer';
import articleEndpoints from 'config/api/article';
import useApiCall from 'hooks/useApiCall';
import useDidUpdateEffect from 'hooks/useDidUpdateEffect';
import article_messages from 'messages/article_messages';
import general_messages from 'messages/general_messages';

const useStyles = makeStyles(theme => ({
  toolbar: {
    margin: theme.spacing(5, 0),
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridGap: theme.spacing(3),
    borderTop: `thin solid ${theme.palette.primary[300]}`,
    borderBottom: `thin solid ${theme.palette.primary[300]}`,
    justifyItems: 'start',
  },
  toolbarElement: {
    padding: 0,
    border: 'none',
    background: 'none',
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: theme.spacing(1.5),
    alignItems: 'center',
    color: theme.palette.text.secondaryColor,
    fontSize: '0.925rem',
    fontWeight: 300,
    fontFamily: theme.typography.fontFamilyBase,
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const ArticleHeader = ({ teaser, estimated_time, id, is_saved }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { apiCall } = useApiCall();
  const [isSaved, setIsSaved] = useState(is_saved);

  const copyLink = async () => {
    const { origin, pathname } = window.location;
    await navigator.clipboard.writeText(origin + pathname);
    enqueueSnackbar(t(...general_messages.link_copied), { variant: 'success' });
  };

  const saveArticle = async () => {
    if (isSaved) {
      const { status } = await apiCall(articleEndpoints.removeFromSaved(id));
      if (status < 300) {
        enqueueSnackbar(t(...article_messages.removed_from_saved), { variant: 'success' });
        setIsSaved(false);
      }
    } else {
      const { status } = await apiCall(articleEndpoints.addToSaved(), { data: { article: id } });
      if (status < 300) {
        enqueueSnackbar(t(...article_messages.saved), { variant: 'success' });
        setIsSaved(true);
      }
    }
  };

  useDidUpdateEffect(() => {
    setIsSaved(is_saved);
  }, [is_saved]);

  const styles = useStyles();
  return (
    <div>
      <MobileGuttersContainer>
        <DescriptionText component='div'>{teaser && parse(teaser)}</DescriptionText>
      </MobileGuttersContainer>
      <div className={styles.toolbar}>
        <Typography className={clsx(styles.toolbarElement, styles.clickable)} component='button' onClick={saveArticle}>
          {isSaved ? <BookmarkIcon /> : <BookmarkBorderIcon />}
          {isSaved ? t(...general_messages.remove_from_saved) : t(...general_messages.save)}
        </Typography>
        <Typography className={clsx(styles.toolbarElement, styles.clickable)} component='button' onClick={copyLink}>
          <LinkIcon />
          {t(...general_messages.copy_link)}
        </Typography>
        <div className={styles.toolbarElement}>
          <AccessTimeIcon />
          {`${estimated_time} ${t(...general_messages.minutes_shortcut).toLowerCase()}`}
        </div>
      </div>
    </div>
  );
};

ArticleHeader.propTypes = {
  teaser: PropTypes.string.isRequired,
  is_saved: PropTypes.bool.isRequired,
  estimated_time: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ArticleHeader;
