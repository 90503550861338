import React, { useEffect, useMemo, useState } from 'react';

import { Button, IconButton, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useFormik } from 'formik';
import { differenceBy, get } from 'lodash';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import CenteredGrid from 'components/CenteredGrid';
import ColoredButton from 'components/ColoredButton';
import CustomAccordion from 'components/CustomAccordion';
import DialogViewWrapper from 'components/DialogViewWrapper';
import FormCheckboxTile from 'components/FormCheckboxTile';
import FormFileInput from 'components/FormFileInput';
import FormSelect from 'components/FormSelect';
import FormTitle from 'components/FormTitle';
import IncidentLogs from 'components/IncidentLogs';
import InformationSummary from 'components/InformationSummary';
import Loader from 'components/Loader';
import TakenStepsSummary from 'components/TakenStepsSummary';
import incidentReportsEndpoints from 'config/api/incident_reports';
import GUIDE_STATUSES from 'config/constants/GUIDE_STATUSES';
import INCIDENT_PHASES from 'config/constants/INCIDENT_PHASES';
import INCIDENT_PRIORITIES from 'config/constants/INCIDENT_PRIORITIES';
import { logObjects } from 'config/constants/logs';
import ROLES from 'config/constants/ROLES';
import { incidentPhaseResolver } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PHASES';
import { TRANSLATABLE_INCIDENT_PRIORITIES_DICTIONARY } from 'config/translatableConstants/TRANSLATABLE_INCIDENT_PRIORITIES';
import parseDate from 'helpers/parseDate';
import useApiCall from 'hooks/useApiCall';
import useLoadingState from 'hooks/useLoadingState';
import usePermissions from 'hooks/usePermissions';
import useQueryParams from 'hooks/useQueryParams';
import useRedirectFromQuery from 'hooks/useRedirectFromQuery';
import useSendingState from 'hooks/useSendingState';
import general_messages from 'messages/general_messages';
import guide_messages from 'messages/guide_messages';
import report_messages from 'messages/report_messages';
import title_messages from 'messages/title_messages';
import logEvent from 'services/logEvent';

import FormTextInput from '../../components/FormTextInput';

import IncidentFormBlock from './_components/IncidentFormBlock';
import useStyles from './SingleIncidentReportPage.styles';

const isIncidentManagementPhase = phase => phase !== INCIDENT_PHASES.IDENTIFY_AND_REPORT;
const isLastPhase = phase => phase === INCIDENT_PHASES.CLOSED;

const getPreviousPhases = (currentPhase, isIncidentManagement) => {
  const incidentPhasesArray = Object.values(INCIDENT_PHASES);
  const currentIndex = incidentPhasesArray.indexOf(currentPhase);
  const previousArray = incidentPhasesArray.slice(0, currentIndex);
  if (isIncidentManagement) {
    return previousArray;
  }
  return previousArray.filter(phase => !isIncidentManagementPhase(phase));
};

const FORM_ID = 'incident_create_form';

const FORM = {
  IS_GDPR: 'is_gdpr',
  IS_ONGOING: 'is_ongoing',
  PRIORITY: 'priority',
  ATTACHMENTS: 'attachments',
  NAME: 'name',
  CATEGORIZATION: 'categorization',
};

const SingleIncidentReportPage = () => {
  const [isIncidentManagement] = usePermissions([ROLES.ORGANIZATION.INCIDENT_MANAGER]);
  const { t } = useTranslation();
  const { report_id } = useParams();
  const { apiCall } = useApiCall();
  const history = useHistory();
  const { deleteParam, queryParams } = useQueryParams();
  const { enqueueSnackbar } = useSnackbar();
  const redirect = useRedirectFromQuery();

  const [incidentData, setIncidentData] = useState(null);
  const [attachmentsInDb, setAttachmentsInDb] = useState([]);

  const disabled = isLastPhase(incidentData?.phase) || incidentData?.progress_status === GUIDE_STATUSES.COMPLETED || queryParams.readOnly;
  const { setLoading, setLoaded } = useLoadingState(false);
  // TODO add loader
  const { sending, setSending, setSent } = useSendingState(false);

  const overrideBackButton = () => {
    if (queryParams.fromGuide) {
      deleteParam('fromGuide');
      history.goBack();
      window.onpopstate = () => {};
    }
  };

  const formik = useFormik({
    initialValues: {
      [FORM.IS_GDPR]: false,
      [FORM.IS_ONGOING]: false,
      [FORM.PRIORITY]: '',
      [FORM.NAME]: '',
      [FORM.CATEGORIZATION]: '',
    },
    enableReinitialize: true,
  });

  // LOADING AND CREATING INCIDENT
  const updateFormik = data => {
    const newFieldsData = data.fields_data.reduce((acc, { field_id, value }) => {
      acc[field_id] = value || '';
      return acc;
    }, {});
    const attachments = get(data, FORM.ATTACHMENTS, []);
    setAttachmentsInDb(attachments);
    newFieldsData[FORM.ATTACHMENTS] = attachments;
    const formData = {
      [FORM.IS_GDPR]: get(data, FORM.IS_GDPR, false),
      [FORM.IS_ONGOING]: get(data, FORM.IS_ONGOING, false),
      [FORM.PRIORITY]: get(data, FORM.PRIORITY, INCIDENT_PRIORITIES.MEDIUM),
      [FORM.NAME]: get(data, FORM.NAME, ''),
      [FORM.CATEGORIZATION]: get(data, FORM.CATEGORIZATION, ''),
    };
    formik.setValues({ ...newFieldsData, ...formData });
  };

  const initReportFormGuide = async () => {
    const { status, data } = await apiCall(incidentReportsEndpoints.createFromGuideAsActiveUser(), {
      data: { guide_progress_id: queryParams.localProgressId },
    });

    if (status < 300) {
      setIncidentData(data);
      updateFormik(data);
      deleteParam('localProgressId');
      const pathnameWithId = history.location.pathname + data.id + history.location.search;
      history.replace(pathnameWithId);
    }
  };

  const getData = async () => {
    setLoading();
    const { data } = await apiCall(
      isIncidentManagement
        ? incidentReportsEndpoints.getOneAsIncidentManager(report_id)
        : incidentReportsEndpoints.getOneAsActiveUser(report_id),
    );
    setIncidentData(data);
    updateFormik(data);
    setLoaded();
  };

  useEffect(() => {
    if (queryParams.localProgressId) {
      window.onpopstate = overrideBackButton;
      initReportFormGuide();
    } else {
      getData();
    }
  }, []);

  // SAVING
  const getApiCallsForAttachments = () => {
    const { values } = formik;
    const toRemove = differenceBy(attachmentsInDb, values[FORM.ATTACHMENTS], 'id');
    const toAdd = values[FORM.ATTACHMENTS] ? values[FORM.ATTACHMENTS].filter(file => file instanceof File) : [];
    const calls = [];
    toRemove.forEach(({ id }) => {
      calls.push(
        apiCall(
          isIncidentManagement
            ? incidentReportsEndpoints.removeAttachmentAsIncidentManager(id)
            : incidentReportsEndpoints.removeAttachmentAsActiveUser(id),
        ),
      );
    });
    toAdd.forEach(file => {
      const data = new FormData();
      data.append('file', file);
      calls.push(
        apiCall(
          isIncidentManagement
            ? incidentReportsEndpoints.addAttachmentAsIncidentManager(report_id)
            : incidentReportsEndpoints.addAttachmentAsActiveUser(report_id),
          { data },
          { showError: false },
        ),
      );
    });
    return calls;
  };

  const onSave = async () => {
    setSending();
    const { values } = formik;
    const steps = Object.entries(formik.values).reduce((result, [field_id, value]) => {
      if (!Object.values(FORM).includes(field_id)) result.push({ field_id, value });
      return result;
    }, []);
    const data = {
      [FORM.IS_GDPR]: values[FORM.IS_GDPR],
      [FORM.IS_ONGOING]: values[FORM.IS_ONGOING],
      [FORM.PRIORITY]: values[FORM.PRIORITY],
      [FORM.NAME]: values[FORM.NAME],
      [FORM.CATEGORIZATION]: values[FORM.CATEGORIZATION],
    };
    const apiCallsForAttachments = getApiCallsForAttachments();
    let success;
    const promises = [...apiCallsForAttachments];
    if (steps.length) {
      if (isIncidentManagement) {
        promises.push(apiCall(incidentReportsEndpoints.updateFieldsAsIncidentManager(report_id), { data: steps }));
      } else {
        promises.push(apiCall(incidentReportsEndpoints.updateFieldsAsActiveUser(report_id), { data: steps }));
      }
      const [{ status: fieldsStatus }] = await Promise.all(promises);
      success = fieldsStatus < 300;
    }

    if (isIncidentManagementPhase(incidentData?.phase)) {
      const { status: updateStatus } = await apiCall(
        isIncidentManagement
          ? incidentReportsEndpoints.updateAsIncidentManager(report_id)
          : incidentReportsEndpoints.updateAsActiveUser(report_id),
        { data },
      );
      success = updateStatus < 300;
    }
    if (success) {
      enqueueSnackbar(t(...general_messages.data_saved), { variant: 'success' });
    }
    await getData();
    setSent();
    return success;
  };

  useEffect(() => {
    if (isIncidentManagementPhase) logEvent(logObjects.INCIDENT_MANAGEMENT);
    else logEvent(logObjects.INCIDENT_REPORT);
  }, []);

  const onReport = async () => {
    const saved = await onSave();
    if (saved) {
      await apiCall(
        isIncidentManagement
          ? incidentReportsEndpoints.goToNextPhaseAsIncidentManager(report_id)
          : incidentReportsEndpoints.goToNextPhaseAsActiveUser(report_id),
      );
      if (incidentData?.phase === INCIDENT_PHASES.CLOSE_AND_SEND_FEEDBACK) redirect();
      else await getData();
    }
  };

  const isOwnerPhase = useMemo(() => {
    if (!incidentData) return true;
    if (isIncidentManagementPhase(incidentData.phase)) return false;
    const settings = incidentData.template_blocks.find(({ phase }) => phase === INCIDENT_PHASES.IDENTIFY_AND_REPORT);
    return !settings?.is_editable;
  }, [incidentData]);

  const informationSummaryData = useMemo(() => {
    if (!incidentData) return null;

    const name = { label: guide_messages.report_information_labels.name, value: incidentData.name };
    const createdAt = { label: guide_messages.report_information_labels.start, value: parseDate(incidentData.created_at) };
    const status = {
      label: guide_messages.report_information_labels.status,
      value: t(...get(guide_messages.status, incidentData.progress_status, ['In progress'])),
    };
    const updatedAt = { label: guide_messages.report_information_labels.last_edit, value: parseDate(incidentData.updated_at) };

    return isOwnerPhase ? [name, createdAt, status, updatedAt] : [createdAt, updatedAt, status];
  }, [incidentData]);

  const actionButtonLabel = () => {
    if (incidentData?.phase === INCIDENT_PHASES.IDENTIFY_AND_REPORT) return t(...guide_messages.submit_incident);
    if (incidentData?.phase === INCIDENT_PHASES.CLOSE_AND_SEND_FEEDBACK) return t(...guide_messages.close_incident_report);
    return t(...guide_messages.go_to_next_phase);
  };

  const onClose = () => redirect();

  const renderDialogActions = () =>
    isIncidentManagementPhase(incidentData?.phase) && !isIncidentManagement ? (
      <CenteredGrid gridGap={2} width='sm' withoutPadding>
        <Button onClick={onClose} variant='outlined'>
          {t(...general_messages.close)}
        </Button>
      </CenteredGrid>
    ) : (
      <CenteredGrid gridGap={2} width='sm' withoutPadding>
        <ColoredButton customColor='secondary' disabled={!formik.isValid || sending || isOwnerPhase} onClick={onReport} variant='outlined'>
          {actionButtonLabel()}
        </ColoredButton>
        <Button disabled={sending || isOwnerPhase} onClick={onSave}>
          {t(...general_messages.save_progress)}
        </Button>
      </CenteredGrid>
    );

  useEffect(() => {
    if (isIncidentManagementPhase) logEvent(logObjects.INCIDENT_MANAGEMENT);
    else logEvent(logObjects.INCIDENT_REPORT);
  }, []);
  const styles = useStyles();
  return (
    <DialogViewWrapper contentSize='lg' title={t(...report_messages.report_page_title)}>
      {incidentData ? (
        <>
          <Typography className={styles.title} component='h2' variant='h2'>
            {history.action !== 'POP' && (
              <IconButton className={styles.goBack} onClick={() => history.goBack()}>
                <ArrowBack />
              </IconButton>
            )}
            {t(...guide_messages.report_incident)}
          </Typography>
          <CenteredGrid gridGap={2} tight title={t(...general_messages.information)} width='lg'>
            <InformationSummary data={informationSummaryData} />
            {isIncidentManagement && (
              <FormTextInput
                disabled={disabled}
                formik={formik}
                id={FORM.NAME}
                label={t(...guide_messages.report_information_labels.name)}
              />
            )}
            {isIncidentManagement && (
              <FormTextInput
                disabled={disabled}
                formik={formik}
                id={FORM.CATEGORIZATION}
                label={t(...guide_messages.report_information_labels.categorization)}
              />
            )}
            {isIncidentManagementPhase(incidentData?.phase) && isIncidentManagement && (
              <>
                <FormCheckboxTile disabled={disabled} formik={formik} id={FORM.IS_GDPR} label={t(...guide_messages.is_gdpr_label)} />
                <FormCheckboxTile disabled={disabled} formik={formik} id={FORM.IS_ONGOING} label={t(...guide_messages.is_ongoing_label)} />
                <FormSelect
                  disabled={disabled}
                  formik={formik}
                  id={FORM.PRIORITY}
                  label={t(...guide_messages.priority_label)}
                  options={TRANSLATABLE_INCIDENT_PRIORITIES_DICTIONARY}
                />
              </>
            )}
            <FormFileInput
              formik={formik}
              id={FORM.ATTACHMENTS}
              label={t(...title_messages.add_attachment)}
              listOnly={isOwnerPhase}
              multiple
            />
          </CenteredGrid>
          {isIncidentManagement && (
            <>
              <CenteredGrid
                component={CustomAccordion}
                heading={<FormTitle>{t(...guide_messages.incident_management_log)}</FormTitle>}
                id='logs'
                width='sm'
              >
                <IncidentLogs readOnly={disabled} reportId={report_id} />
              </CenteredGrid>
            </>
          )}
          <CenteredGrid
            component={CustomAccordion}
            heading={<FormTitle>{t(...guide_messages.incident_steps_header)}</FormTitle>}
            id='steps'
            width='sm'
          >
            <TakenStepsSummary steps={incidentData?.steps} />
          </CenteredGrid>
          {getPreviousPhases(incidentData?.phase, isIncidentManagement).map(phase => (
            <CenteredGrid
              key={phase}
              component={CustomAccordion}
              heading={<FormTitle>{t(incidentPhaseResolver(phase))}</FormTitle>}
              id='prevPhases'
              width='sm'
            >
              <div className={styles.blockWrapper}>
                {incidentData?.template_blocks
                  .filter(block => block.phase === phase)
                  .map(block => (
                    <IncidentFormBlock key={block.id} notEditable {...block} formik={formik} />
                  ))}
              </div>
            </CenteredGrid>
          ))}
          {(isIncidentManagement || !isIncidentManagementPhase(incidentData?.phase)) && (
            <form id={FORM_ID} onSubmit={formik.handleSubmit}>
              <CenteredGrid
                component={CustomAccordion}
                heading={<FormTitle>{t(incidentPhaseResolver(incidentData?.phase))}</FormTitle>}
                id='currPhase'
                width='sm'
                withoutPadding
              >
                <div className={styles.blockWrapper}>
                  {incidentData?.template_blocks
                    .filter(block => block.phase === incidentData?.phase)
                    .map(block => (
                      <IncidentFormBlock key={block.id} notEditable={!block.is_editable || disabled} {...block} formik={formik} />
                    ))}
                </div>
              </CenteredGrid>
            </form>
          )}
          {!disabled && renderDialogActions()}
        </>
      ) : (
        <Loader inner />
      )}
    </DialogViewWrapper>
  );
};

export default SingleIncidentReportPage;
