import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import SimpleFormLayout from 'components/SimpleFormLayout';
import authEndpoints from 'config/api/auth';
import useApiCall from 'hooks/useApiCall';
import auth_messages from 'messages/auth_messages';
import validation_messages from 'messages/validation_messages';
import PATHS from 'router/PATHS';

const RegisterEmailSent = () => {
  const { t } = useTranslation();
  const { state: data } = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { apiCall, sending } = useApiCall();

  if (!data) {
    history.push({
      pathname: PATHS.AUTH,
    });
  }

  const onSubmit = async e => {
    e.preventDefault();
    const { status, data: responseData } = await apiCall(authEndpoints.create_user(), { data });
    if (status === 400 && responseData.email) {
      enqueueSnackbar(t(...validation_messages.email_in_use), { variant: 'error' });
    }
    if (status === 204)
      enqueueSnackbar(t(...auth_messages.activation_email_notification), {
        variant: 'success',
      });
  };

  return (
    <SimpleFormLayout
      buttonVariant='outlined'
      onSubmit={onSubmit}
      sending={sending}
      submitLabel={t(...auth_messages.resend_activation_email)}
      title={t(...auth_messages.reset_password_sent)}
    >
      <Typography component='h3' variant='h3'>
        {t(...auth_messages.check_email)}
      </Typography>
      <Box pt={1}>
        <Typography variant='body1'>{t(...auth_messages.activation_link)}</Typography>
      </Box>
    </SimpleFormLayout>
  );
};

export default RegisterEmailSent;
