const title_messages = {
  guide: ['titles>>guide', 'Guide library'],
  article: ['titles>>article', 'Article library'],
  activity: ['section>>activity', 'Activity'],
  saved_articles: ['section>>saved_articles', 'My saved articles'],
  manage_content: ['headers>>manage_content', 'Manage content'],
  personal_info: ['section>>personal_info', 'Personal info'],
  contacts: ['section>>contacts', 'Contacts'],
  content_update: ['headings>>content_update', 'Content in the updating'],
  user_administration: ['headings>>user_administration', 'User administration'],
  upload_user_file: ['headings>>upload_user_file', 'Upload a file with coworkers'],
  add_coworker: ['headings>>add_coworker', 'Add coworker'],
  edit_coworker: ['headings>>edit_coworker', 'Edit coworker'],
  add_attachment: ['section>>add_attachment', 'Add attachment'],
  advisory_page: ['section>>advisory_page', 'Advisory page'],
  organization_administration: ['section>>organization_administration', 'Organization administration'],
  self_tests: ['titles>>self_tests', 'Self Tests'],
  recommended_content: ['titles>>recommended_content', 'Recommended content'],
};

export default title_messages;
