import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  tile: {
    background: ({ darker }) => theme.palette.secondary[darker ? 100 : 50],
    display: 'grid',
    gridGap: theme.spacing(2),
    padding: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadiusLarge,
    justifyContent: 'stretch',
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  footerFont: {
    textTransform: 'uppercase',
    color: theme.palette.text.secondaryColor,
    letterSpacing: '1px',
    fontSize: '.75rem',
    fontWeight: 500,
  },
}));
