import React, { useRef } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import articleEndpoints from 'config/api/article';
import stripHTMLTags from 'helpers/stripHTMLTags';
import useLazyApiCall from 'hooks/useLazyApiCall';
import article_messages from 'messages/article_messages';
import general_messages from 'messages/general_messages';

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '300px',
    display: 'block',
  },
  wrapper: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadiusLarge,
    border: `thin solid ${theme.palette.secondary[100]}`,
    display: 'grid',
    gridGap: theme.spacing(2),
    transitionDuration: theme.transitions.duration.shorter,
    transitionTimingFunction: theme.transitions.easing.easeIn,
    '&:hover': {
      border: `thin solid ${theme.palette.secondary[200]}`,
      boxShadow: theme.shadows[1],
    },
  },
  spreedLine: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: theme.palette.text.secondaryColor,
  },
  topLineContent: {
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme.palette.text.secondaryColor,
    letterSpacing: '1px',
    fontSize: '.75rem',
  },
  title: {
    color: theme.palette.text.primary,
  },
}));

const RelatedArticleLink = ({ id }) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  const { data, loading } = useLazyApiCall(ref, articleEndpoints.get(id));

  const styles = useStyles();
  return (
    <Link ref={ref} className={styles.link} to={id}>
      <span className={styles.wrapper}>
        <span className={styles.spreedLine}>
          <Typography className={styles.topLineContent} component='span'>
            {loading ? <Skeleton width={100} /> : data?.service_area?.name || null}
          </Typography>
          <Typography className={styles.topLineContent} component='span'>
            {loading ? <Skeleton width={20} /> : `${data?.estimated_time} ${t(...general_messages.minutes_shortcut)}`}
          </Typography>
        </span>
        <Typography className={styles.title} component='span' variant='body1'>
          {loading ? <Skeleton height='2.5rem' width='100%' /> : stripHTMLTags(data?.heading) || null}
        </Typography>
        <span className={styles.spreedLine}>
          <Typography component='span'>{t(...article_messages.related_article)}</Typography>
          <ArrowForward color='inherit' />
        </span>
      </span>
    </Link>
  );
};

RelatedArticleLink.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default RelatedArticleLink;
