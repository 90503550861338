import table_messages from 'messages/table_messages';

import booleanFilter from '../booleanFilter';

const isOngoingFilter = {
  sectionName: table_messages.is_ongoing,
  filters: booleanFilter,
  isSingleSelect: true,
};

export default isOngoingFilter;
