import React from 'react';

import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';

import workplace_messages from 'messages/workplace_messages';

import DialogGrid from '../_components/DialogGrid';

type Props = {
  onClose: () => void;
  open: boolean;
};

const WorkplacesHelpDialog: React.FC<Props> = ({ onClose, open }) => {
  const { t } = useTranslation();
  return (
    // @ts-ignore
    <DialogGrid onClose={onClose} open={open} title={t(workplace_messages.help.title)}>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(t(workplace_messages.help.body)) }} />
    </DialogGrid>
  );
};

export default WorkplacesHelpDialog;
