import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import organization_administration_messages from 'messages/organization_administration_messages';
import RegisterForm from 'pages/AuthPage/_components/AuthTile/_components/RegisterForm';

import DialogGrid from '../_components/DialogGrid';

const EditOrganizationDataDialog = ({ open, onClose, initialData }) => {
  const { t } = useTranslation();
  return (
    <DialogGrid onClose={onClose} open={open} title={t(...organization_administration_messages.update_info_dialog_title)}>
      <RegisterForm initialData={initialData} isEdit onClose={onClose} />
    </DialogGrid>
  );
};

EditOrganizationDataDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialData: PropTypes.shape({}).isRequired,
};

export default EditOrganizationDataDialog;
