import React, { useEffect, useState } from 'react';

import { FormHelperText, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import METHOD from 'config/api/_methods';
import ensureIsArray from 'helpers/ensureIsArray';
import useBoolState from 'hooks/useBoolState';
import usePagination from 'hooks/usePagination';
import general_messages from 'messages/general_messages';

import useStyles from './SelectPaginated.styles';

const SelectPaginated = ({
  endpointFunc,
  dataParser,
  disabled,
  required,
  label,
  error,
  variant,
  value,
  onChange,
  className,
  onInitialLoad,
  uniqueValues,
  initialOptions,
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState(initialOptions);
  const { state: open, setTrue: setOpen, setFalse: setClose } = useBoolState(false);
  const { data, hasNextPage, getData, initialLoading } = usePagination({
    endpointFunc,
    limits: [100, 100, 100],
    isGet: endpointFunc().method === METHOD.GET,
  });

  useEffect(() => {
    if (data) {
      if (uniqueValues) {
        const newOptions = [...initialOptions, ...dataParser(data)];
        setOptions(uniqBy(newOptions, 'key'));
      } else {
        setOptions([...initialOptions, ...dataParser(data)]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (onInitialLoad && options.length) onInitialLoad(options);
  }, [initialLoading, options]);

  const handleClose = ({ target }) => {
    if (target.dataset.role !== 'get-more') setClose();
  };

  const handleOpen = () => {
    setOpen();
  };

  const unifiedLabel = required ? `${label} * ` : label;
  const styles = useStyles();
  return (
    <FormControl className={className} error={Boolean(error)} variant={variant}>
      <InputLabel id={unifiedLabel}>{unifiedLabel}</InputLabel>
      <Select
        disabled={disabled || !options.length}
        label={unifiedLabel}
        labelId={unifiedLabel}
        onChange={onChange}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        value={value}
      >
        {options.map(({ key, label: optionLabel }) => (
          <MenuItem key={key} value={key}>
            {t(...ensureIsArray(optionLabel))}
          </MenuItem>
        ))}
        {hasNextPage && (
          <button className={styles.getMoreButton} data-role='get-more' onClick={getData} type='button'>
            {t(...general_messages.show_more)}
          </button>
        )}
      </Select>
      <FormHelperText error>{error}</FormHelperText>
    </FormControl>
  );
};

SelectPaginated.propTypes = {
  endpointFunc: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  dataParser: PropTypes.func,
  error: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onInitialLoad: PropTypes.func,
  initialOptions: PropTypes.array,
  uniqueValues: PropTypes.bool,
};

SelectPaginated.defaultProps = {
  variant: 'outlined',
  disabled: false,
  required: false,
  error: null,
  value: '',
  dataParser: e => e,
  className: undefined,
  onInitialLoad: () => {},
  initialOptions: [],
  uniqueValues: false,
};

export default SelectPaginated;
