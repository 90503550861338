import React from 'react';

import { Chip, useMediaQuery, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import stringShortener from 'helpers/stringShortener';

const FilterChip = ({ name, id, deleteFilter, addFilter, isSelected, sectionKey }) => {
  const { t } = useTranslation();
  const onClick = () => {
    if (isSelected) deleteFilter({ id, sectionKey });
    else addFilter({ id, name, sectionKey });
  };

  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Chip
      icon={isSelected ? <CheckIcon /> : undefined}
      label={stringShortener(t(name), 35)}
      onClick={onClick}
      size={upSm ? 'medium' : 'small'}
    />
  );
};

FilterChip.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  deleteFilter: PropTypes.func.isRequired,
  addFilter: PropTypes.func,
  isSelected: PropTypes.bool,
  sectionKey: PropTypes.string.isRequired,
};

FilterChip.defaultProps = {
  isSelected: false,
  addFilter: () => {},
};

export default FilterChip;
