/**
 *
 * @param {String} url
 * @param {Object?} params
 * @param {Boolean} removeEmpty
 * @returns {string|*}
 */
import { isNil } from 'lodash';

const createUrlWithParams = (url, params, removeEmpty = true) => {
  if (!url) throw new Error('Url is required');
  if (!params) return url;
  if (removeEmpty) {
    Object.entries(params).forEach(([key, value]) => {
      // eslint-disable-next-line no-param-reassign
      if (isNil(value)) delete params[key];
    });
  }
  const urlParams = new URLSearchParams(params).toString();
  return `${url}?${urlParams}`;
};

export default createUrlWithParams;
