import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { ReactComponent as PenIcon } from 'assets/icons/pen.svg';
import ColoredButton from 'components/ColoredButton';
import ContactRowContent from 'components/ContactRowContent/ContactRowContent';
import MUTATION_KEYS from 'config/api/MUTATION_KEYS';
import { usersApi } from 'config/api/users';
import general_messages from 'messages/general_messages';
import validation_messages from 'messages/validation_messages';

import useStyles from './UserCardSection.styles';

type Props = {
  title: string;
  subtitle?: string | React.ReactNode;
  data: {
    email: { value?: string; id: string };
    phoneNumber: { value?: string; id: string };
  };
  editable?: boolean;
  isLoading: boolean;
  shouldBeHidden: boolean;
};

const UserCardSection: React.FC<Props> = ({ title, subtitle, data, editable, isLoading, shouldBeHidden }) => {
  const { email, phoneNumber } = data;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const userMutation = useMutation(MUTATION_KEYS.UPDATE_USER, usersApi.updateUserContacts, {
    onSuccess: () => {
      enqueueSnackbar(t(general_messages.data_saved), { variant: 'success' });
      setIsEdit(false);
    },
  });

  const onSubmit = (dataToUpdate: any) => {
    userMutation.mutate(dataToUpdate);
  };

  const formik = useFormik({
    initialValues: {
      [email.id]: email.value,
      [phoneNumber.id]: phoneNumber.value,
    },
    onSubmit,
    validationSchema: yup.object({
      [email.id]: yup.string().required(t(validation_messages.required)),
      [phoneNumber.id]: yup.string().required(t(validation_messages.required)),
    }),
  });

  const setEditMode = () => setIsEdit(true);

  const styles = useStyles();
  if (shouldBeHidden) return null;
  return (
    <form className={styles.contactSection}>
      <header className={styles.header}>
        <div className={styles.headerTitle}>
          <Typography component='h2' variant='h4'>
            {title}
          </Typography>
          {subtitle && (
            <Typography component='h3' variant='h5'>
              {subtitle}
            </Typography>
          )}
        </div>
        {editable &&
          (isEdit ? (
            // @ts-ignore
            <ColoredButton customColor='none' disabled={userMutation.isLoading} icon onClick={formik.handleSubmit}>
              <SaveOutlinedIcon />
            </ColoredButton>
          ) : (
            // @ts-ignore
            <ColoredButton customColor='none' icon onClick={setEditMode}>
              <PenIcon />
            </ColoredButton>
          ))}
      </header>
      <ContactRowContent
        content={email.value}
        formik={formik}
        id={email.id}
        isEdit={isEdit}
        isLoading={isLoading}
        label={t(general_messages.email)}
      />
      <ContactRowContent
        content={phoneNumber.value}
        formik={formik}
        id={phoneNumber.id}
        isEdit={isEdit}
        isLoading={isLoading}
        label={t(general_messages.phone)}
      />
    </form>
  );
};

export default UserCardSection;
