import instances from 'config/constants/instances';

const isProduction = process.env.REACT_APP_INSTANCE === instances.PRODUCTION;

const getMs = (number, unit) => {
  if (!number || !unit) {
    throw new Error('Pass unit and number');
  }
  switch (unit) {
    case 'seconds':
      return number * 1000;
    case 'minutes':
      return number * 1000 * 60;
    case 'hours':
      return number * 1000 * 60 * 60;
    case 'days':
      return number * 1000 * 60 * 60 * 24;
    case 'weeks':
      return number * 1000 * 60 * 60 * 24 * 7;
    default:
      return number;
  }
};

/**
 * API to communicate with storages
 */
export default class StorageService {
  /**
   * @param {String} key
   * @param {sessionStorage|localStorage} [storage=localStorage] - where keep data
   * @param {Boolean} [trackTime=false] - should track time
   * @param {Boolean} [string=false] - should parse data as string
   * @param {Function} [parser=() => {})] - parser for set function
   */
  constructor(key, { storage = 'localStorage', trackTime = false, string = false, parser = data => data } = {}) {
    const possibleStorage = ['sessionStorage', 'localStorage'];
    this.key = key;
    this.storage = storage;
    this.trackTime = trackTime && !string;
    this.string = string;
    this.parser = parser;
    if (!possibleStorage.includes(this.storage)) {
      throw Error(`${this.storage} is not a valid storage!`);
    }
  }

  /**
   *
   * @param {Number?} number
   * @param {seconds| minutes | hours | days | weeks?} unit
   * @returns {any|undefined}
   */
  get = (number, unit) => {
    const { key, storage } = this;
    try {
      const serialized = window[storage].getItem(key);
      if (serialized === null) {
        return undefined;
      }
      const object = this.string ? serialized : JSON.parse(serialized);
      if (this.trackTime) {
        object._stale = new Date().getTime() > object._lastUpdated + getMs(number, unit);
      }
      return object;
    } catch {
      return undefined;
    }
  };

  /**
   * @param {Object} inputData
   */
  set = inputData => {
    const { key, storage, trackTime } = this;
    const data = this.parser(inputData);
    try {
      if (trackTime) data._lastUpdated = new Date().getTime();
      const serialized = this.string ? data : JSON.stringify(data);
      window[storage].setItem(key, serialized);
    } catch (error) {
      // eslint-disable-next-line no-console
      if (!isProduction) console.error(error);
    }
  };

  update = inputData => {
    const { key, storage, trackTime } = this;
    const data = { ...this.get(), ...inputData };
    try {
      if (trackTime) data._lastUpdated = new Date().getTime();
      const serialized = this.string ? data : JSON.stringify(data);
      window[storage].setItem(key, serialized);
    } catch (error) {
      // eslint-disable-next-line no-console
      if (!isProduction) console.error(error);
    }
  };

  destroy = () => {
    const { key, storage } = this;
    window[storage].removeItem(key);
  };
}
