import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import defaultPlaceholder from 'assets/placeholders/article.jpg';
import removeTrailingSlash from 'helpers/removeTrailingSlash';

const getSrc = (url, { imageError, placeholder }) => {
  if (imageError || !url) return placeholder;
  let src;
  try {
    src = new URL(url).href;
  } catch (e) {
    src = `${removeTrailingSlash(process.env.REACT_APP_API_URL)}${url}`;
  }
  return src;
};

const ImageWithFallback = ({ src, placeholder, alt, ...rest }) => {
  const [imageError, setImageError] = useState(false);
  const onImageError = () => setImageError(true);
  useEffect(() => {
    if (!src) setImageError(true);
    else setImageError(false);
  }, [src]);
  return <img alt={alt} onError={onImageError} src={getSrc(src, { placeholder, imageError })} {...rest} />;
};

ImageWithFallback.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  placeholder: PropTypes.any,
};

ImageWithFallback.defaultProps = {
  src: undefined,
  alt: '',
  placeholder: defaultPlaceholder,
};

export default ImageWithFallback;
