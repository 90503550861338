import { format } from 'date-fns';

import instances from 'config/constants/instances';

const isProduction = process.env.REACT_APP_INSTANCE === instances.PRODUCTION;

const parseDate = value => {
  if (!value) return '';
  try {
    const date = new Date(value);
    return format(date, 'yyyy-MM-dd');
  } catch (e) {
    // eslint-disable-next-line no-console
    if (!isProduction) console.error(e);
    return '';
  }
};

export default parseDate;
