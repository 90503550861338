import React, { useEffect, useState } from 'react';

import { InputLabel, OutlinedInput, FormControl, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';

import DialogViewWrapper from 'components/DialogViewWrapper';
import Loader from 'components/Loader';
import commonEndpoints from 'config/api/common';
import CONTENT_TYPES from 'config/constants/CONTENT_TYPES';
import { contentTypeResolver } from 'config/translatableConstants/TRANSLATABLE_CONTENT_TYPES';
import stringShortener from 'helpers/stringShortener';
import useApiCall from 'hooks/useApiCall';
import useDebounce from 'hooks/useDebounce';
import useLoadingState from 'hooks/useLoadingState';
import search_messages from 'messages/search_messages';
import PATHS from 'router/PATHS';

import useStyles from './GlobalSearchPage.styles';

const generateLink = (
  type,
  location,
  { article_id, incident_report_id, completed_at, version_id, guide_id, progress_id, self_test_id },
) => {
  switch (type) {
    case CONTENT_TYPES.ARTICLE:
      return `${PATHS.ARTICLES}/${article_id}`;
    case CONTENT_TYPES.GUIDE:
    case CONTENT_TYPES.INCIDENT_GUIDE: {
      if (completed_at) {
        return `${PATHS.GUIDES}/${guide_id}/${version_id}/?redirect_url=${location.pathname}`;
      }
      return `${PATHS.GUIDES}/${guide_id}/${version_id}/${progress_id}/?redirect_url=${location.pathname}`;
    }
    case CONTENT_TYPES.INCIDENT_REPORT:
      return `${PATHS.INCIDENT_REPORT}/${incident_report_id}`;
    case CONTENT_TYPES.SELF_TEST:
      return `${PATHS.SELF_TESTS}/${self_test_id}/${version_id}/`;
    default:
      return null;
  }
};

const getDate = (type, { created_at, updated_at, completed_at }) => {
  let dateToPass;
  switch (type) {
    case CONTENT_TYPES.ARTICLE:
    case CONTENT_TYPES.INCIDENT_REPORT:
      dateToPass = created_at;
      break;
    case CONTENT_TYPES.GUIDE:
    case CONTENT_TYPES.INCIDENT_GUIDE:
      dateToPass = completed_at || updated_at;
      break;
    default:
      dateToPass = null;
  }
  return dateToPass ? new Date(dateToPass) : null;
};

const Results = ({ results, loading, phrase }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const styles = useStyles();
  if (loading) return <Loader color='secondary' inner size={30} />;
  if (!results) return <Typography>{t(...search_messages.search_motivation)}</Typography>;
  if (results && results.length)
    return results.map(({ object_content_type, heading, ...rest }) => {
      const to = generateLink(object_content_type, location, rest);
      const date = getDate(object_content_type, rest);
      return (
        <Link key={rest.guide_id || rest.article_id || rest.incident_report_id} className={styles.resultsRow} to={to}>
          <Typography>{t(...contentTypeResolver(object_content_type))}</Typography>
          <Typography dangerouslySetInnerHTML={{ __html: stringShortener(sanitizeHtml(heading), 80) }} />
          {date && <Typography>{format(date, 'yyyy-MM-dd')}</Typography>}
        </Link>
      );
    });
  if (!!results && !results.length) return <Typography>{t(...search_messages.no_matches, { phrase })}</Typography>;
  return null;
};

Results.propTypes = {
  loading: PropTypes.bool.isRequired,
  results: PropTypes.array,
  phrase: PropTypes.string,
};

Results.defaultProps = {
  results: null,
  phrase: '',
};

const GlobalSearchPage = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [results, setResults] = useState(null);
  const { loading, setLoaded, setLoading } = useLoadingState(false);
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const { apiCall } = useApiCall();

  const performSearch = async phrase => {
    if (phrase) {
      setLoading();
      const { data } = await apiCall(commonEndpoints.searchAll({ search_term: phrase }));
      setResults(data);
      setLoaded();
    } else {
      setResults(null);
    }
  };

  useEffect(() => {
    performSearch(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const updateSearchValue = ({ target }) => setSearchValue(target.value);

  const searchLabel = t(...search_messages.input_label);
  const styles = useStyles();
  return (
    <DialogViewWrapper contentSize='lg' title={t(...search_messages.page_title)}>
      <FormControl fullWidth variant='outlined'>
        <InputLabel color='secondary' htmlFor='search'>
          {searchLabel}
        </InputLabel>
        <OutlinedInput color='secondary' id='search' label={searchLabel} onChange={updateSearchValue} value={searchValue} />
      </FormControl>
      <div className={styles.resultsContainer}>
        <Results loading={loading} phrase={searchValue} results={results} />
      </div>
    </DialogViewWrapper>
  );
};

export default GlobalSearchPage;
