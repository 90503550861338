import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  wrapper: {
    maxWidth: `${theme.sizes.container.sm}px`,
    display: 'grid',
    gridGap: theme.spacing(4),
    justifyItems: 'center',
  },
}));
